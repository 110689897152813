<template>
  <div class="main_container">
    <div class="sidebar-container">
      <div>
        <h3 class="title">Préférences</h3>
        <nav>
          <li class="nav-item">
            <a
              class="nav-link active"
              :class="{ 'active-link': currentPath === '/favoris' }"
              aria-current="page"
              @click="gotoPage('favoris')"
            >
              Mes favoris
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link active"
              :class="{ 'active-link': currentPath === '/candidatures' }"
              aria-current="page"
              @click="gotoPage('candidatures')"
            >
              Mes candidatures
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link active"
              :class="{ 'active-link': currentPath === '/alert' }"
              aria-current="page"
              @click="gotoPage('alert')"
            >
              Mes alertes
            </a>
          </li>
        </nav>
      </div>

      <div>
        <h3 class="title">Paramètres</h3>
        <nav>
          <li class="nav-item">
            <a
              class="nav-link active"
              :class="{ 'active-link': currentPath === '/account' }"
              aria-current="page"
              @click="gotoPage('account')"
            >
              Mon compte
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link active"
              :class="{ 'active-link': currentPath === '/#' }"
              aria-current="page"
              @click="logout"
            >
              Se déconnecter
            </a>
          </li>
        </nav>
      </div>
    </div>
    <div class="d-lg-none">
      <div class="card w-100">
        <div
          class="d-flex justify-content-between align-items-center"
          @click="toggleField('preferences')"
        >
          <h3>Préférences</h3>
          <div v-if="!preferencesExpanded">
            <img src="@/assets/collapseArrow.png" alt="downside" />
          </div>
          <div v-else>
            <img
              style="transform: rotate(180deg)"
              src="@/assets/collapseArrow.png"
              alt="downside"
            />
          </div>
        </div>

        <div class="row" v-if="preferencesExpanded">
          <div>
            <nav>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  :class="{ 'active-link': currentPath === '/favoris' }"
                  aria-current="page"
                  @click="gotoPage('favoris')"
                >
                  Mes favoris
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  :class="{ 'active-link': currentPath === '/candidatures' }"
                  aria-current="page"
                  @click="gotoPage('candidatures')"
                >
                  Mes candidatures
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  :class="{ 'active-link': currentPath === '/alert' }"
                  aria-current="page"
                  @click="gotoPage('alert')"
                >
                  Mes alertes
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>

      <div class="card w-100 mt-3">
        <div
          class="d-flex justify-content-between align-items-center"
          @click="toggleField('paramètres')"
        >
          <h3>Paramètres</h3>
          <div v-if="!paramètresExpanded">
            <img src="@/assets/collapseArrow.png" alt="downside" />
          </div>
          <div v-else>
            <img
              style="transform: rotate(180deg)"
              src="@/assets/collapseArrow.png"
              alt="downside"
            />
          </div>
        </div>

        <div class="row" v-if="paramètresExpanded">
          <div>
            <nav>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  :class="{ 'active-link': currentPath === '/account' }"
                  aria-current="page"
                  @click="gotoPage('account')"
                >
                  Mon compte
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  :class="{ 'active-link': currentPath === '/#' }"
                  aria-current="page"
                  @click="logout"
                >
                  Se déconnecter
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { accountService } from "@/services";
import { toaster } from "@/utils/toast/toast";

export default {
  name: "ProfileSideBar",
  computed: {
    currentPath() {
      return window.location.pathname;
    },
  },
  data() {
    return {
      preferencesExpanded: false,
      paramètresExpanded: false,
    };
  },
  methods: {
    gotoPage(page) {
      this.$router.push(page);
    },
    toggleField(field) {
      if (field === "preferences") {
        this.preferencesExpanded = !this.preferencesExpanded;
      } else if (field === "paramètres") {
        this.paramètresExpanded = !this.paramètresExpanded;
      }
    },
    logout() {
      try {
        accountService.logout();
        toaster.showSuccessPopup("Vous êtes déconnecté");
        this.$router.push({ path: "/" });
      } catch (error) {
        toaster.showErrorPopup("Erreur lors de la déconnexion");
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: none;
}

li a {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 141% !important;
  margin: 15px 0 15px 0 !important;
}

.nav-item:hover {
  text-decoration: underline;
  text-decoration-color: #fbde2f;
}

.active-link {
  text-decoration: underline;
  text-decoration-color: #fbde2f;
}

.card {
  display: flex;
  padding: 10px 20px 20px 20px;
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;
  border: 1px solid var(--Gray-medium, #c4c4c4);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.nav-link {
  cursor: pointer;
}

.main_container {
  margin-top: 20px;
  margin-bottom: 20px;
}
.sidebar-container {
  display: none;
}
@media (min-width: 992px) {
  .sidebar-container {
    display: block;
    visibility: visible;
  }
}
</style>
