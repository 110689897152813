export const TELETRAVAIL_FIELDS = [
  {
    "id": 1,
    "teletravail": "Télétravail complet"
  },
  {
    "id": 2,
    "teletravail": "Télétravail partiel"
  },
  {
    "id": 3,
    "teletravail": "En présentiel"
  },
  {
    "id": 4,
    "teletravail": "Hybride"
  }
]