export const PROFESSION_FIELDS =
  [
    { nom: 'Administrateur Système' },
    { nom: 'Agent de Voyage' },
    { nom: 'Analyste de Crédit' },
    { nom: 'Analyste de Données' },
    { nom: 'Analyste en Assurance Qualité' },
    { nom: 'Analyste en Business Intelligence' },
    { nom: 'Analyste en Économie' },
    { nom: 'Analyste en Gestion de Projet' },
    { nom: 'Analyste en Logistique' },
    { nom: 'Analyste en Marketing Digital' },
    { nom: 'Analyste en Sécurité Informatique' },
    { nom: 'Analyste Financier' },
    { nom: 'Architecte Logiciel' },
    { nom: 'Chargé de Communication' },
    { nom: 'Chef de Produit' },
    { nom: 'Chef de Projet' },
    { nom: 'Chercheur en Astronomie' },
    { nom: 'Chercheur en Biologie' },
    { nom: 'Chercheur en Chimie' },
    { nom: 'Chercheur en Physique' },
    { nom: 'Chercheur en Sciences' },
    { nom: 'Concepteur de Jeux Vidéo' },
    { nom: 'Concepteur de Produits' },
    { nom: 'Conseiller en Santé' },
    { nom: 'Conseiller Financier' },
    { nom: 'Conseiller Juridique' },
    { nom: 'Consultant en Stratégie d\'Entreprise' },
    { nom: 'Designer d\'Intérieur' },
    { nom: 'Designer Graphique' },
    { nom: 'Développeur Full Stack' },
    { nom: 'Développeur Mobile' },
    { nom: 'Directeur des Ressources Humaines' },
    { nom: 'Expert en Cybersécurité' },
    { nom: 'Géologue' },
    { nom: 'Gestionnaire de Projet' },
    { nom: 'Ingénieur Civil' },
    { nom: 'Ingénieur Électrique' },
    { nom: 'Ingénieur en Environnement' },
    { nom: 'Ingénieur en Intelligence Artificielle' },
    { nom: 'Ingénieur en Logiciel' },
    { nom: 'Ingénieur en Robotique' },
    { nom: 'Responsable des Opérations' },
    { nom: 'Responsable des Ventes' },
    { nom: 'Responsable Marketing' },
    { nom: 'Scénariste' },
    { nom: 'Spécialiste en Développement Web' },
    { nom: 'Spécialiste en Éthique des Affaires' },
    { nom: 'Spécialiste en Relations Publiques' },
    { nom: 'Spécialiste en Ressources Humaines' }
  ] 