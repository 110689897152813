<template>
  <section class="sectionTellUsAll">
    <div class="titleTellUsAll">
      <h2>
        <span>Dis-nous tout !</span> <br />Notre #MatchCoach est à ton écoute
      </h2>
      <p>
        Tu vises un job mieux rémunéré, du télétravail ou juste pouvoir profiter
        d’une pause relaxation midi ? <br />
        Thanks-Boss accepte tous les critères sans restriction !
      </p>
    </div>
    <div class="wrapperTellUsAll">
      <div class="litleWrapper">
        <img src="@/assets/Etape1.png" alt="Card" />
        <img src="@/assets/Etape2.png" alt="Card" />
      </div>
      <div class="litleWrapper">
        <img src="@/assets/Etape3.png" alt="Card" />
        <img src="@/assets/Etape4.png" alt="Card" />
      </div>
      <div class="litleWrapper">
        <img src="@/assets/Etape5.png" alt="Card" />
        <img src="@/assets/Etape6.png" alt="Card" />
      </div>
    </div>
  </section>
</template>

<script></script>

<style>
.sectionTellUsAll {
  overflow: hidden;
  background-image: url("@/assets/Ellipse_0.png");
  background-repeat: no-repeat;
  background-position: bottom 2750px right 0px;
  background-size: 150px;
}

.sectionTellUsAll .titleTellUsAll {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0 0 0;
}

.sectionTellUsAll .titleTellUsAll h2 span {
  font-family: "Outfit700";
  font-size: 48px !important;
  line-height: 125% !important;
}

.sectionTellUsAll .titleTellUsAll h2 {
  margin-bottom: 20px;
  font-family: "Outfit300";
  width: 70%;
  text-align: center;
}

.sectionTellUsAll .titleTellUsAll p {
  width: 40%;
}

.sectionTellUsAll .wrapperTellUsAll {
  overflow: hidden;
  background-image: url("@/assets/driverLine.png");
  background-repeat: no-repeat;
  background-position: bottom 598px right 50px;
  background-size: 1140px;
  padding: 0 50px 0 50px;
  margin: 80px 0 80px 0;
}

.sectionTellUsAll .wrapperTellUsAll .litleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapperTellUsAll .litleWrapper img:nth-child(1) {
  margin-right: 70px;
  width: 40%;
}

.wrapperTellUsAll .litleWrapper img:nth-child(2) {
  width: 40%;
  margin-top: 90px;
}

@media screen and (max-width: 1050px) {
  .sectionTellUsAll {
    background-position: bottom 5600px right 0px;
    background-size: 150px;
  }

  .sectionTellUsAll .wrapperTellUsAll {
    background-image: none;
    padding: 0 20px 0 20px;
  }

  .wrapperTellUsAll .litleWrapper img {
    width: 80%;
  }

  .sectionTellUsAll .wrapperTellUsAll .litleWrapper {
    flex-direction: column;
  }

  .wrapperTellUsAll .litleWrapper img:nth-child(1) {
    margin: 0;
    width: 60%;
  }

  .wrapperTellUsAll .litleWrapper img:nth-child(2) {
    margin: 0;
    width: 60%;
  }
}

@media screen and (max-width: 800px) {
  .wrapperTellUsAll .litleWrapper img:nth-child(1) {
    width: 100%;
  }

  .wrapperTellUsAll .litleWrapper img:nth-child(2) {
    width: 100%;
  }
}
</style>
