<template>
  <form class="formation-template-container flex-col" ref="formationForm" @submit.prevent="handleSubmit">
    <section class="formation-template-first-section profile-grid">
      <div class="formation-template-left">
        <div class="field">
          <label>Titre</label>
          <input
            type="text"
            id="job_title"
            name="job_title"
            v-model="formation.titre"
            placeholder="Votre fonction"
            required
          />
        </div>
        <div class="field">
            <label>Lieu</label>
            <input
              type="text"
              id="position"
              name="position"
              v-model="formation.lieu"
              placeholder="Ville, commune"
              required
            />
          </div>
      </div>
      <div class="formation-template-right">
        <div class="formation-template-date-input">
          <div class="field">
            <label>De</label>
            <input
              type="date"
              id="debut-date"
              name="debut-date"
              v-model="formation.debut"
              placeholder="Du"
              required
            />
          </div>
          <div class="field">
            <label>Au</label>
            <input
              type="date"
              id="fin-date"
              name="fin-date"
              v-model="formation.fin"
              placeholder="Au"
              required
            />
          </div>
        </div>
      </div>
    </section>
    <section class="formation-template-second-section">
      <label>Détail</label>
      <textarea
        id="description"
        name="description"
        maxlength="800"
        v-model="formation.détail"
        placeholder="Description"
        required
      ></textarea>
    </section>
    <div class="formation-template-buttons">
      <img src="@/assets/trash.svg"  @click="handleDelete" />
      <button v-if="isNew" class="profile-save-button" type="submit">Sauvegarder</button>
    </div>
    <div class="profile-line"></div>
  </form>
</template>


<script>
import { toaster } from '@/utils/toast/toast';

export default {
  name: 'FormationTemplate',
  props: {
    formation: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSubmit() {
      if (new Date(this.formation.debut) > new Date(this.formation.fin)) {
        toaster.showErrorPopup('La date de début doit être antérieure à la date de fin');
        return;
      }
      this.$emit('formation-submit', this.formation);
      this.$refs.formationForm.reset();
    },
    handleDelete() {
      this.$emit('delete', this.formation.id);
    },
  },
}
</script>

<style scoped>
input, textarea {
  background: white;
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 12px 20px;
}

textarea {
  resize: none;
  padding: 12px 20px;
}

section {
  width: 100%;
}

label {
  font-weight: 600;
  line-height: 141%;
}

img {
  cursor: pointer;
}

.formation-template-container {
  display: flex;
  gap: 20px;

}

.profile-line {
  border-bottom: 1px solid var(--Gray-dark, #979797);
  margin-block: 32px;
}

.formation-template-left {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.formation-template-date-input {
  gap: 20px;
  justify-content: space-between;
}

.formation-template-second-section {
  label {
    margin: 25px 0px 7.8px 20px;
    font-weight: 600;
    line-height: 141%;
  }
}

.formation-template-right {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 20px;
}


.formation-template-date-input {
  .field {
    width: 46%; 
  }
  .field:last-child {
    margin-left: 8%;
  }
}


.formation-template-buttons {
  display: flex;
  justify-content: space-between;
}


.field {
  display: inline-flex;
  align-items:center;
  background: white;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding-inline: 20px;
}
.field label {
  flex: 1;
}
.field input {
  flex: auto;
  margin-left: 5px;
}
</style>