<template>
  <div class="sectionWhoWeAre">
    <div class="whoWeAre">
      <h3>Notre histoire.</h3>
      <p class="pontuation">
        Dans l'univers effréné de la tech, trouver le job idéal ou le candidat parfait peut souvent sembler une mission impossible. Chez Thanks-Boss, 
        nous avons transformé cette quête en une histoire de succès. </p>

        <p class="pontuation">Après de nombreuses discussions avec des candidats et des décideurs du secteur,
        une frustration commune est apparue : le défi de <strong>trouver le job ou le candidat idéal</strong> 
        dans des délais raisonnables. Les candidats passent d'entretien en entretien, 
        remplissant des formulaires sans fin, souvent sans recevoir de retour. 
        Lorsqu'ils trouvent enfin un poste, ils se rendent compte qu'il ne correspond pas à leurs attentes,
        qu'il s'agisse de l'adéquation avec le projet, du salaire, ou d'autres facteurs clés. Quel <strong>gaspillage de temps et d'énergie !</strong></p>

        <p class="pontuation">Du côté des recruteurs, pris dans le tourbillon de réunions 
        et de deadlines, publier une offre d'emploi se transforme rapidement en <strong>une avalanche de CVs</strong>, 
        où trouver la perle rare devient une tâche herculéenne, parfois même <strong>au-delà</strong> des <strong>frontières françaises.</strong></p> 

        <p><strong>C'est ici que Thanks-Boss entre en scène.</strong> Nos co-fondateurs, ayant eux-mêmes navigué entre les rôles de candidats et de décideurs, 
        ont mis leur expertise commune au service d'une idée <strong>innovante:</strong>
        développer une intelligence artificielle capable de créer le <strong>match parfait</strong>
        entre les aspirations profondes des <strong>candidats</strong> et les besoins spécifiques des <strong>entreprises.</strong></p>
    </div>
    <hr />
    <div v-for="person in alternatePersons" :key="person.id">
      <div v-if="person.position === 'left'" class="person">
        <div class="avatar">
          <img :src="person.image" :alt="person.nom" />
        </div>
        <div class="allDetails">
          <h3>{{ person.prenom }} {{ person.nom }}</h3>
          <div class="details">
            <p class="function">{{ person.fonction }}</p>
          
            <p>{{ person.description }}</p>
          </div>
        </div>
      </div>
      <div v-else class="person">
        <div class="allDetails right">
          <h3>{{ person.prenom }} {{ person.nom }}</h3>
          <div class="details">
            <p class="function">{{ person.fonction }}</p>
            
            <p>{{ person.description }}</p>
          </div>
        </div>
        <div class="avatar right">
          <img :src="person.image" :alt="person.nom" />
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import florentinDAM from "@/assets/FlorentinDAM.png";
import GuillaumeGAUDIN from "@/assets/GuillaumeGAUDIN.png";
import SergeDOORGACHURN from "@/assets/SergeDOORGACHURN.jpeg";

export default {
  data() {
    return {
      jobs: [],
      personsLeft: [
        {
          id: 1,
          image: florentinDAM,
          nom: "Florentin",
          prenom: "DAM",
          fonction: "Fondateur de Thanks-Boss",
        
          description:
            "Il a une passion contagieuse pour l'innovation. Diplômé en Mécatronique et spécialisé en Intelligence Artificielle, il a affûté ses compétences sur des projets d'envergure pour l'Agence Spatiale Européenne et Airbus, entre autres. À la tête de Thanks-Boss, il est le cerveau derrière notre ambitieuse IA, veillant à ce que chaque aspect de notre service dépasse les attentes de nos utilisateurs."
        },
        {
          id: 2,
          image: SergeDOORGACHURN,
          nom: "DOORGACHURN",
          prenom: "Serge",
          fonction: "Lead Développeur",
         
          description:
            "Notre maestro du code, a transformé sa passion pour les échecs en une carrière en développement full-stack. Après une reconversion professionnelle audacieuse, il est devenu un pilier de notre équipe de développement, poussant constamment nos solutions vers l'excellence.",
        },
      ],

      personsRight: [
        {
          id: 3,
          image: GuillaumeGAUDIN,
          nom: "Guillaume",
          prenom: "GAUDIN",
          fonction: "Co-fondateur de Thanks-Boss",
        
          description:
            "Il a une passion contagieuse pour l'innovation. Diplômé en Mécatronique et spécialisé en Intelligence Artificielle, il a affûté ses compétences sur des projets d'envergure pour l'Agence Spatiale Européenne et Airbus, entre autres. À la tête de Thanks-Boss, il est le cerveau derrière notre ambitieuse IA, veillant à ce que chaque aspect de notre service dépasse les attentes de nos utilisateurs.",
        },
      ],
    };
  },

  computed: {
    alternatePersons() {
      let combined = [];
      let maxLen = Math.max(this.personsLeft.length, this.personsRight.length);

      for (let i = 0; i < maxLen; i++) {
        if (i < this.personsLeft.length) {
          combined.push({ ...this.personsLeft[i], position: "left" });
        }
        if (i < this.personsRight.length) {
          combined.push({ ...this.personsRight[i], position: "right" });
        }
      }

      return combined;
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Outfit400";
}

strong{
  font-weight: 600;
}
.sectionWhoWeAre {
  display: flex;
  flex-direction: column;
}
.pontuation{
  margin-bottom: -25px!important;
}
.whoWeAre {
  display: flex;
  padding-bottom: 55px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
}

hr {
  margin: 0;
}
.person {
  display: flex;
  align-items: center;
  height: 477px;
  padding: 40px 0 40px 0;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
}

.person .avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 2 - 40px);
  margin: 0 40px 0 0;
  height: 100%;
  background-color: #c4c4c4;
}

.person .avatar.right {
  margin: 0 0 0 40px;
}

.person .avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: white;
}
.person .allDetails {
  height: 300px;
}
.person .allDetails h3 {
  font-family: "Outfit400";
  font-size: 20rem;
}
.person .allDetails .function {
  font-family: "Outfit400";
  font-size: 20rem;
}
.person .allDetails .details {
  height: 70%;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: start;
  flex-direction: column;
}

@media (max-width: 1080px) {
  .person {
    flex-direction: column;
    height: auto;
  }

  .person .avatar {
    margin: 0;
    width: calc(100% / 1);
  }

  .person .avatar.right {
    margin: 25px 0 0 0;
    width: calc(100% / 1);
    order: 1;
  }

  .person .allDetails {
    width: calc(100% / 1);
    height: auto;
    margin-top: 25px;
  }
  .person .allDetails.right {
    order: 2;
    margin-top: 25px;
  }

  .person .allDetails.right {
    margin-top: 0;
  }
}
</style>
