<template>
  <div class="jobsRecommend text-center mb-5">
    <h2>Jobs chez Thanks-Boss</h2>
  </div>
  <div class="middle">
    <JobsList :jobListTwo="getJobsOffers" :screenSize="'sm'" />
  </div>
  <button class="viewMore mb-5" @click="loadMoreJobsOffers">
    <div>
      <div class="d-flex justify-content-center align-items-center">
        <h5>Voir plus</h5>
        <div class="ms-2">
          <img src="@/assets/collapseArrow.png" alt="downArrow" />
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import getAxiosInstance from "@/services/axios";
import JobsList from "@/components/home/job-list/JobsList.vue";

export default {
  components: {
    JobsList,
  },
  data() {
    return {
      currentPage: 1,
      currentText: "",
      getJobsOffers: [],
    };
  },
  methods: {
    async fetchJobsOffers() {
      try {
        const response = await getAxiosInstance().get(
          `/job-offers/top-ten/?page=${this.currentPage}`
        );
        this.getJobsOffers = [...this.getJobsOffers, ...response.data];
      } catch (error) {
        console.error("Error retrieving user experience:", error);
      }
    },
    async loadMoreJobsOffers() {
      this.currentPage += 1;
      await this.fetchJobsOffers();
    },
  },
  created() {
    this.fetchJobsOffers();
  },
};
</script>

<style scoped>
* {
  font-family: "Outfit300";
}
.jobsRecommend {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.jobsRecommend h2 {
  text-align: center;
  margin: 0 25px 0 25px;
}
.job-display {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  margin-right: 40px;
}

.job-card {
  width: 423px;
  height: 435px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--Gray-medium, #c4c4c4);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.job-header {
  display: flex;
  flex-direction: column;
}

.job-detail {
  display: flex;
  align-items: center;
}

.job-contract img {
  width: 21px;
  height: 21px;
}

button {
  background-color: white;
  margin: 0;
  padding: 0;
  border: 0;
}

.middle {
  display: flex;
  padding: 50;
  justify-content: center;
}

.job-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}

.job-tag {
  display: flex;
  padding: 4px 10px 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid var(--Primary-jaune, #fbde2f);
  background: #fff;
}

.job-footer {
  display: flex;
  justify-content: space-between;
}

.viewMore {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 4px 0 4px 0;
  border: #c4c4c4 solid 1px;
  background-color: #fff;
}

.viewMore:hover {
  background-color: #f2f2f2;
  border: #c4c4c4 solid 3px;
}
</style>
