<template>
  <div class="profile-child-container">
    <div class="formation-container">
      <div class="formation-container-header flex-row d-flex">
        <h1>Formations</h1>
        <img src="@/assets/uparrow.png" @click="toggleIsActive" :class="{ 'rotate-180': isActive }" />
      </div>
      <div v-show="isActive">
        <button @click="addFormation" :disabled="isAdded">
          Ajouter +
        </button>
        <!-- call FormationTemplate for every Formation -->
        <div v-for="(formation, index) in formationForm" :key="index">
          <FormationTemplate
            :formation="formation"
            :isNew="true"
            @formation-submit="handleSubmit"
            @delete="handleDelete"
          />
        </div>
        <div v-for="(formation, index) in formationList" :key="index">
          <FormationTemplate
            :formation="formation"
            @formation-submit="handleSubmit"
            @delete="handleDelete"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormationTemplate from '../template/FormationTemplate.vue';
export default {
  name: 'Formation',
  components: {
    FormationTemplate
  },
  props: {
    formationList: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      isActive: false,
      isAdded: false,
      formationForm: []
    }
  },
  methods: {
    togglePopup() {
      this.$refs.popupLinkedin.isPopupVisible = !this.$refs.popupLinkedin.isPopupVisible;
    },
    toggleIsActive() {
      this.isActive = !this.isActive;
    },
    handleSubmit(formationList) {
      this.$emit('formation-form-submit', formationList);
      this.isAdded = false;
      this.formationForm.shift();
    },
    handleDelete(id) {
      if (id === '') {
        this.formationForm.shift();
        return this.isAdded = false;
      }
      this.$emit('formation-form-delete', id);
    },
    addFormation() {
      if (this.formationList.length >= 20 || this.isAdded) return;
      this.isAdded = true;
      this.formationForm.unshift({
        id: '',
        titre: '',
        lieu: '',
        debut: '',
        fin: '',
        détail: '',
      });
    }
  },
}

</script>

<style scoped>


h1 {
  font-size: 30px !important;
  padding: 0 !important;
  font-weight: 500;
}

select {
  height: 45.881px;
  width: 85%;
  padding: 12px 20px;
  margin-block-start: 11px;
  border-radius: 5px;
  border: none;
}

img {
  width: 45px;
  height: 44px;
  cursor: pointer;
}

button {
  padding: 8px 25px;
  border-radius: 50px;
  border: 1.5px solid #000;
  width: 120px;
  margin: 11px 0px 32px; 
}

span, p {
  color: #000;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 141% !important;
  margin-left: 20px;
}

span {
  display: flex;
  align-items: center;
}

.profile-child-container {
  padding: 14px 34px 14px 34px;
}

.formation-container {
  width: 100%;
}

.formation-container-header {
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
</style>