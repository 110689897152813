<template>
  <Slider
    v-model="salaire"
    :enableCross="false"
    :min="0"
    :max="100"
    :marks="marks"
    :hide-label="true"
    :tooltip-formatter="formatter1"
    :tooltip-placement="['bottom', 'top']"
    :tooltip="'always'"
    class="slider"
  />
</template>

<script>
import Slider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import "@/components/inputs/slider/slider-style.css";

export default {
  components: {
    Slider,
  },
  emits: ["salary"],
  props: {
    salaire_min: Number,
    salaire_max: Number,
  },
  data() {
    return {
      salaire_min: "",
      salaire_max: "",
      salaire: [0, 100],
      formatter1: "{value} k€",
      // marks: [20, 40, 60, 80, 100],
      marks: (val) =>
        val % 20 === 0
          ? {
              label: `${val}k€`,
              labelStyle: {
                // opacity: val * 0.01 * 0.7 + 0.3,
              },
              labelActiveStyle: {
                color: "#999",
              },
            }
          : false,
    };
  },
  methods: {},
  watch: {
    salaire(newValues) {
      this.salaire_min = newValues[0];
      this.salaire_max = newValues[1];
      this.$emit("salary", {
        salaire_min: this.salaire_min,
        salaire_max: this.salaire_max,
      });
    },
    salaire_min(newSalaryMin) {
      this.salaire_min = newSalaryMin;
    },
    salaire_max(newSalaryMax) {
      this.salaire_max = newSalaryMax;
    },
  },
};
</script>

<style scoped>
.slider {
  margin-top: 45px;
  margin-bottom: 40px;
}
</style>
