<template>
  <div class="form-control">
    <P>{{ message }}</P>
  </div>
</template>

<script>

export default {
  name: "UserMessage",
  props: {
    message: { type: Object, required: true },
  },
};
</script>

<style scoped>
.form-control {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 12px 15px;
  border-radius: 10px 0px 10px 10px;
  border: 0 !important;
  background: var(--gray-light, #f5f5f5);
  min-height: 45px;
  margin-left: 5px;
  overflow-y: hidden;
  resize: none;
}
</style>