<template>
  <div class="card-container mycontainer" style="height: 100%;">
    <v-stepper 
      v-model="page"
      v-if="!isCreated"
      :items="stepperItems" 
      @keydown.enter="validate();"
      class="stepper-container-email-register" 
      next-text='Suivant' 
      prev-text="Retour" 
      ref="stepper"
      style="width: 500px;"
    >
      
      <template v-slot:item.1>
        <v-card title="Avec quel email veux-tu te faire connaître ?">
          <v-form ref="emailForm" @submit.prevent>
            <v-text-field label="Ton email" type="email" :rules="emailRules" v-model="formData.email" placeholder="exemple@thanks-boss.com" />
          </v-form>
        </v-card>
      </template>

      <template v-slot:item.2>
        <v-card title="Sécurisons ton compte !">
          <v-form ref="passwordForm" @submit.prevent>
            <v-text-field label="Mot de passe"  v-model="formData.password" :rules="passwordRules" placeholder="P@ssword!!"   :append-inner-icon="showPaasord ? 'mdi-eye' : 'mdi-eye-off'" @click:append-inner="showPaasord = !showPaasord" :type="showPaasord ? 'text' : 'password'"/>
            <v-text-field label="Confirmer"  v-model="formData.password2" :rules="passwordRules2" placeholder="P@ssword!!"  :append-inner-icon="showPaasordVerif ? 'mdi-eye' : 'mdi-eye-off'" @click:append-inner="showPaasordVerif = !showPaasordVerif" :type="showPaasordVerif ? 'text' : 'password'"/>
          </v-form>
        </v-card>
      </template>

      <template v-slot:item.3>
        <v-card title="Dis nous en plus sur toi !" flat>
          <v-form ref="nameForm" @submit.prevent>
            <v-text-field label="Ton prénom" v-model="formData.first_name" :rules="nameRules" placeholder="Prénom"  />
            <v-text-field label="Ton nom" v-model="formData.last_name" :rules="nameRules" placeholder="Nom"  />
          </v-form>
        </v-card>
      </template>

      <template v-slot:item.4>
        <v-card title="Aide les recruteurs à trouver ton profil !" flat>
          <v-form ref="whereForm" @submit.prevent style="height: 230px">
            <Ville @cityEntry="getFormData" />
            <v-select label="Présentiel ou télétravail ?" density="comfortable" item-title="teletravail" :items="TELETRAVAIL_FIELDS" v-model="formData.teletravail" :rules="notEmptyRules" />
          </v-form>
        </v-card>
      </template>
      
      <template v-slot:item.5>
        <v-card title="Quel est ton métier de rêve ?" flat>
          <v-form ref="titleRef" @submit.prevent>
            <v-text-field label="Titre" v-model="formData.titre" :rules="notEmptyRules" placeholder="Développeur web, cuisinier, ..." />
          </v-form>
        </v-card>
      </template>

      <template v-slot:item.6>
        <v-card title="Pour finir... Quel type de contrat t'intéresse ?" flat>
          <v-form ref="contractRef" @submit.prevent>
            <v-checkbox 
              v-for="(item, index) in CONTRACT_FIELDS" 
              v-model="formData.contrat"
              :key="index" 
              :label="item.contrat"
              :value="item.contrat"
              :rules="notEmptyRules"
            >
            </v-checkbox>
          </v-form>
        </v-card>
        <p style="font-size: 12px !important; text-align: left">
          En créant un compte ou en vous connectant, vous comprenez et
          acceptez les
          <a class="anim_link underline" href="https://www.thanks-boss.com/cgu" target="_blank">
            CGU
          </a>
          de
          Thanks-Boss. Vous confirmez également avoir pris connaissance de
          notre politique relative aux cookies et de notre
          <a class="anim_link underline" href="https://www.thanks-boss.com/politique-confidentialite" target="_blank">
            Politique de confidentialité
          </a>
        </p>
      </template>

      <template v-slot:actions>
        <div style="display: flex; justify-content: space-around; padding-bottom: 20px">
          <v-btn 
            :disabled="page === 1" 
            @click="stepper.prev()" 
            size="large" 
            variant="outlined">
            Retour
          </v-btn>
          <v-btn 
            :hidden="page === stepperItems.length" 
            :disabled="page === stepperItems.length" 
            @click="validate();" 
            size="large"
            variant="flat" 
            theme="dark">
            Suivant
          </v-btn>
          <v-btn 
            :hidden="page !== stepperItems.length" 
            :loading="isLoading"
            @click="submitForm()" 
            size="large" 
            variant="flat"
            color="primary">
            Créer mon compte
          </v-btn>
        </div>
      </template>
    </v-stepper>
    <v-card v-else>
      <v-card-title>Votre compte a bien été créé !</v-card-title>
      <!--
      <v-card-text>
        <p>
          Un email de confirmation vous a été envoyé. Veuillez cliquer sur le lien pour activer votre compte.
        </p>
        <p>
          Si vous n'avez pas reçu l'email, veuillez vérifier votre dossier de courrier indésirable.
        </p>
      </v-card-text>
    -->
      <v-card-actions style="display: flex; justify-content: center;">
        <v-btn @click="$router.push({ path: '/login', query: { reg: true } })" color="primary">Se connecter</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script setup>
import { ref } from "vue";
const stepper = ref(null);

</script>

<script>
import { accountService } from "@/services";
import { toaster } from "@/utils/toast/toast";
import { mapActions } from "vuex";

import { CONTRACT_FIELDS } from "@/utils/base/contract.js";
import { TELETRAVAIL_FIELDS } from "@/utils/base/teletravail.js";
import Ville from "@/components/inputs/city-droplist/Ville.vue";

export default {
  name: "RegisterEmail",
  components: {
    Ville,
  },
  data() {
    return {
      showPaasord: false,
      showPaasordVerif: false,

      formData: {type_user: "applicant"},
      emailRegex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      isLoading: false,
      isCreated: false,
      page: null,
      stepperItems: ['Email', 'Password', 'Name', 'Location', 'Title', 'Contract'],
      emailRules: [
        v => !!v || 'Le champ est requis',
        v => !/\s/.test(v) || 'L\'adresse e-mail ne doit pas contenir d\'espace',
        v => v.length >= 5 || 'L\'adresse e-mail doit comporter au moins 5 caractères',
        v => v.length <= 50 || 'L\'adresse e-mail ne doit pas dépasser 50 caractères',
        v => /^\S+@\S+$/.test(v) || 'L\'adresse e-mail doit être valide',
      ],
      passwordRules: [
        v => !!v || 'Le champ est requis',
        v => !/\s/.test(v) || 'Le mot de passe ne doit pas contenir d\'espace',
        v => v.length >= 8 || 'Le mot de passe doit comporter au moins 8 caractères',
        v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).*$/.test(v) || 'Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial',
      ],
      passwordRules2: [
        v => !!v || 'Le champ est requis',
        v => this.formData.password === this.formData.password2 || 'Les mots de passe ne correspondent pas',
      ],
      nameRules: [
        v => !!v || 'Le champ est requis',
        v => v.length >= 2 || 'Le nom doit comporter au moins 2 caractères',
        v => v.length <= 50 || 'Le nom ne doit pas dépasser 50 caractères',
      ],
      notEmptyRules: [
        v => !!v || 'Le champ est requis',
      ],
    };
  },
  methods: {
    ...mapActions(["handleUserChange", "handleLoggedIn"]),
    getFormData(data) {
      this.formData = { ...this.formData, ...data };
    },

    // form validators
    async validate() {
      const getStepper = this.$refs.stepper;
      let valid = false;

      switch (this.page) {
        case 1:
          valid = (await this.$refs.emailForm.validate()).valid;
          break;
        case 2:
          valid = (await this.$refs.passwordForm.validate()).valid;
          break;
        case 3:
          valid = (await this.$refs.nameForm.validate()).valid;
          break;
        case 4:
          valid = (await this.$refs.whereForm.validate()).valid;
          break;
        case 5:
          valid = (await this.$refs.titleRef.validate()).valid;
          break;
        case 6:
          valid = (await this.$refs.contractRef.validate()).valid;
          break;
        default:
          break;
      }
      
      // check if form is valid and not the last page
      if (valid && this.page < getStepper.items.length) {
        console.log(this.formData);
        getStepper.next();
      } else if (valid && this.page === getStepper.items.length) {
        return true;
      } else {
        return false;
      }
    },
    // submit form to create the account
    async submitForm() {
      try {
        // check if checkbox form is valid
        const valid = await this.validate();
        if (!valid) return;
        this.isLoading = true;

        this.formData.email = this.formData.email.toLowerCase();
        await accountService.register(this.formData);
        this.isCreated = true;
      
      } catch (error) {
        console.log(error);
        if (error.response.data.email == "Un objet account avec ce champ email existe déjà.") {
          toaster.showErrorPopup("Un compte avec cette adresse mail existe déjà.");
        } else {
          toaster.showErrorPopup("Erreur à la création de votre compte.");
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.labels-register-email {
  font-weight: 700;
  height: 49px;
  line-height: 49px;
  text-align: left;
  margin-right: 5px;
  font-size: 16px;
  margin-left: 5px;
  min-width: fit-content;
}

.input-field-register-email {
  padding-left: 15px;
  background-color: #f5f5f5;
}

.input-field-register-email input {
  width: 100%;
}

.input-field-register-email:not(:last-child) {
  margin-bottom: 20px;
}

.v-stepper-header {
  display: none !important;
}

.stepper-container-email-register .v-card-title {
  white-space: unset !important;
}

.stepper-container-email-register span {
  font-size: inherit !important;
  font-weight: inherit!important;
}
</style>

<style scoped>
.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 106px;
  padding-bottom: 106px;
}

.mycontainer {
  max-width: inherit;
  background-color: #fff;
}
</style>
