<template>
  <Banner text="Mon Compte" :src="require('@/assets/my_account_banner.svg')" />
  <div class="account-container">
    <Sidebar class="accountSidebar" />
    <div class="separator"></div>
    <Profile />
  </div>
</template>

<script>
import Banner from "@/components/layout/banner/Banner.vue";
import Sidebar from "@/components/layout/profile-sidebar/Sidebar.vue";
import Profile from "@/components/account/profile/Profile.vue";

export default {
  name: "Account",
  components: {
    Banner,
    Sidebar,
    Profile,
  },
  data() {},
  methods: {},
};
</script>

<style scoped>
.account-container {
  display: inline-flex;
  width: 100%;
  padding-top: 40px;
  padding-left: 65px;
}

.accountSidebar {
  width: 25%;
}

.separator {
  height: 30px;
}

@media screen and (max-width: 991px) {
  .account-container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .accountSidebar {
    width: 100%;
  }
}
</style>
