import Axios from './caller.service'
import store from '@/store'
import getAxiosInstance from './axios'
 
let login = (credentials) => {
  return Axios.post('/user/token/', credentials)
}
const register = (credentials) => {
  return Axios.post('/user/register/', credentials)
}
 
let logout = () => {
  localStorage.removeItem('token')
  store.commit('setLoggedIn', false);
  store.dispatch('logout');
}
 
let saveToken = (token) => {
  localStorage.setItem('token', token)
}
let getToken = () => {
  return localStorage.getItem('token')
}
let isLogged = () => {
  let token = localStorage.getItem('token')
  return !! token
}

const getProfile = () => {
  return getAxiosInstance().get("user/");
};
 
export const accountService = {
  login, register, logout, saveToken, isLogged, getToken, getProfile
}