<template>
  <div class="modal fade" id="confirmationModal" tabindex="-1" aria-hidden="true" data-bs-keyboard="false"
    ref="confirmationModal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="background-color: transparent;"></button>
        </div>
        <div class="modal-body">
          <button @click="emitConfirm" data-bs-dismiss="modal" aria-label="Close" style="margin-right: 10px">Oui</button>
          <button data-bs-dismiss="modal" aria-label="Close">Non</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ConfirmationModal",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    emitConfirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped>
.modal-body button {
  display: flex;
  padding: 8px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #000;
  color: white;
}

.modal-body {
  display: flex;
  justify-content: center;
}

</style>
