<template>
  <div v-if="job && user">
    <div>
      <div class="row heading" v-if="job">
        <div class="col-sm-12 col-lg-6">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="job-company">
                {{ job.company }}
              </h3>
            </div>
            <div class="col-sm-12">
              <h2 class="job-title mt-3">
                {{ job.title }}
              </h2>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 d-flex justify-content-end heading-img">
          <img v-if="job.logo_url" :src="job.logo_url" alt="postuler" />
          <img v-else src="@/assets/logo.png" class="logo pointer" style="margin-bottom: 50px;"
            @click="gotoPage(`/company/${job.author || job.author_id}`)" />

        </div>
      </div>
      <!-- Form my info -->
      <div class="main">
        <div class="card personalInfo mt-4">
          <h4 class="mb-3">Mes informations</h4>
          <div class="avatar">
            <!-- This image have to be your -->
            <img :src="photoUrl" alt="avatar" class="profile-picture" />
          </div>
          <div class="row">
            <div class="col-sm-12 col-lg-6">
              <input placeholder="Camille" class="form-control" readonly :value="user.first_name" />
            </div>
            <div class="col-sm-12 col-lg-6">
              <input placeholder="camille.legendre@thanks-boss.com" class="form-control" :value="user.email" readonly />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-lg-6">
              <input placeholder="Legendre" class="form-control" readonly :value="user.last_name" />
            </div>
            <div class="col-sm-12 col-lg-6">
              <input placeholder="Téléphone" class="form-control" readonly :value="user.numberPhone" />
            </div>
          </div>
        </div>
        <!-- Why me ? -->
        <div class="card motivation mt-4">
          <h4>Pourquoi moi ?</h4>
          <div class="mt-3">
            <h6 class="ms-2">En quelques mots</h6>
            <textarea v-model.trim="formValue.lettre" placeholder="Expliquez en 800 caractères"
              class="form-control resize-none" rows="4" style="resize: none;" maxlength="800"></textarea>
          </div>
          <div class="mt-3">
            <h6 class="ms-2">Lettre de motivation (facultative)</h6>
            <div class="file-upload-wrapper mt-2">
              <input v-show="motivationFileName" type="text" class="form-control" :value="motivationFileName"
                readonly />
              <ButtonComponent @click="importMotivation" text="Importer" class="mt-2" />
              <input type="file" @change="onFileSelected" ref="motivationFileInput" class="d-none" />
            </div>
          </div>
        </div>
        <!-- Info legal -->
        <div class="card extra mt-4">
          <p class="mt-3">
            L’employeur recevra également une copie de ton profil.
          </p>
          <p class="mt-3">
            En soumettant ma candidature, j’accepte que mes données
            soient traitées pour les fins du recrutement.
          </p>
          <div style="padding-top: 25px">
            <button-component text="Envoyer la candidature" @click="submitForm" />
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import getAxiosInstance from "@/services/axios";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import inconnue from "@/assets/inconnu.jpg";
import { toaster } from "@/utils/toast/toast";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Postuler",
  components: {
    ButtonComponent,
  },
  data() {
    return {
      formValue: {
        lettre: "",
        lettre_motivation: null,
      },
      imageInconnue: inconnue,
      motivationFileName: "",
      job: null,
      user: null,
    };
  },
  computed: {
    photoUrl() {
      if (this.user.photo === null) {
        return this.imageInconnue;
      } else {
        return `https://web.thanks-boss.com/api${this.user.photo}`;
      }
    },
    ...mapGetters(["getUser"]),
  },
  async mounted() {
    await this.getJob();
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      this.user = this.getUser;
    },
    async getJob() {
      await getAxiosInstance().get(`/jobs/${this.$route.params.id}/`).then((response) => {
        this.job = response.data;
      });
    },
    ...mapActions(["handleCandidatureChange"]),
    async submitForm() {
      try {
        const response = await getAxiosInstance().post(`/postuler/${this.job.id}/`, this.formValue,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        );
        this.handleCandidatureChange(response.data);
        toaster.showSuccessPopup("Votre candidature a bien été envoyée");
        // this.$router.push("/candidatures");
      } catch (error) {
        if (error.response.data.message === "Vous avez déjà postulé à cet emploi.") {
          return toaster.showErrorPopup("Vous avez déjà postulé à cet emploi.");
        }
        toaster.showErrorPopup("Une erreur est survenue");
        console.error("Erreur lors de la soumission du formulaire:", error);
      }
    },
    importMotivation() {
      this.$refs.motivationFileInput.click();
    },
    onFileSelected(event) {
      const selectedFile = event.target.files[0];
      this.motivationFileName = selectedFile.name;
      if (selectedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        this.formValue.lettre_motivation = selectedFile;
      }
    },
  },
}
</script>

<style scoped>
.heading img {
  height: 120px;
}

.heading {
  display: flex;
  align-items: center;
  padding: 25px;
  height: 400px;
}

.card {
  padding: 35px 27px;
  margin: 20px;
  border-radius: 10px;
  border: 1px solid var(--Gray-medium, #c4c4c4);
  background: var(--Gray-light, #f5f5f5);
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.extra {
  margin-bottom: 20px;
}

.avatar {
  border-radius: 50%;
}

.profile-picture {
  border-radius: 155px;
  width: 153.025px;
  height: 155px;
  object-fit: cover;
  background-repeat: no-repeat;
}

.form-control {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--gray-medium, #c4c4c4);
  background: #fff;
  margin-top: 8px;
}

.file-upload-wrapper {
  position: relative;
}

.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.is-invalid+.invalid-feedback {
  display: block;
}

.d-none {
  display: none;
}

h3 {
  font-weight: 300 !important;
}

h4 {
  font-size: 30px !important;
  font-weight: 500 !important;
}

@media (max-width: 768px) {
  .modal-container {
    margin: 0 !important;
  }

  h4 {
    font-size: 27px !important;
  }
}

@media (max-width: 992px) {
  .heading {
    flex-direction: column-reverse;
  }

  .heading-img {
    justify-content: center !important;
    margin-bottom: 20px;
  }
}
</style>
