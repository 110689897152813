<template>
  <!-- SECTION OUR DNA -->
  <sectionOurDna />
  <div class="adn-padding">
    <!-- SECTION WHO WE ARE -->
    <sectionWhoWeAre />
    <!-- SECTION TEAM -->
    <sectionOurCommitment />
    <!-- SECTION JOBS RECOMMENT -->
    <!-- <sectionJobsRecomment /> -->
    <!-- SECTION NOTE -->
    <sectionNote />
  </div>
  <sectionSponsor />
</template>

<script>
import sectionOurDna from "@/components/ourAdn/sectionOurDna";
import sectionWhoWeAre from "@/components/ourAdn/sectionWhoWeAre";
import sectionTeam from "@/components/ourAdn/sectionTeam";
import sectionOurCommitment from "@/components/ourAdn/sectionOurCommitment";
import sectionJobsRecomment from "@/components/ourAdn/sectionJobsRecomment";
import sectionNote from "@/components/ourAdn/sectionNote";
import sectionSponsor from "@/components/ourAdn/sectionSponsor";

export default {
  components: {
    sectionOurDna,
    sectionWhoWeAre,
    sectionTeam,
    sectionOurCommitment,
    sectionJobsRecomment,
    sectionNote,
    sectionSponsor,
  },
};
</script>

<style scoped>
.adn-padding {
  padding: 50px 222px 0 222px;
}

@media (max-width: 1080px) {
  .adn-padding {
    padding: 50px 100px 0 100px;
  }
}

@media (max-width: 990px) {
  .adn-padding {
    padding: 30px 30px 0 30px;
  }
}

@media (max-width: 720px) {
  .adn-padding {
    padding: 50px 25px 0 25px;
  }
}
</style>