import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/home/Home.vue";
import Autonome from "@/views/autonome/Autonome.vue";
import Accompagnee from "@/views/accompagnee/Accompagnee.vue";
import Alert from "@/views/alert/Alert.vue";
import Favoris from "@/views/favoris/Favoris.vue";
import Candidatures from "@/views/candidature/Candidatures.vue";
import PageNotFound from "@/views/not-found/PageNotFound.vue";
import Register from "@/views/register/Register.vue";
import Login from "@/views/login/Login.vue";
import OurAdn from "@/views/adn/ourAdn.vue";
import Account from "@/views/account/Account.vue";
import Company from "@/views/company/Company.vue";
import Concept from "@/views/concept/Concept.vue";
import JobDetail from "@/views/job-details/JobDetail.vue";
import Postuler from "@/views/postuler/Postuler.vue";
import PasswordResetRequest from "@/views/password-reset-request/PasswordResetRequest.vue";
import PasswordResetConfirm from "@/views/password-reset-confirm/PasswordResetConfirm.vue";
import RegisterEmail from "@/views/register-email/RegisterEmail.vue";
import Contact from "@/views/contact/Contact_v2.vue";
import News from "@/views/news/News.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/news",
    name: "news",
    component: News,
  },
  {
    path: "/autonome",
    name: "autonome",
    component: Autonome,
  },
  {
    path: "/accompagnee",
    name: "accompagnee",
    component: Accompagnee,
  },
  {
    path: "/alert",
    name: "alert",
    component: Alert,
  },
  {
    path: "/favoris",
    name: "favoris",
    component: Favoris,
  },
  {
    path: "/candidatures",
    name: "candidatures",
    component: Candidatures,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/register/email",
    name: "registerEmail",
    component: RegisterEmail,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/notreAdn",
    name: "notreAdn",
    component: OurAdn,
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: PasswordResetRequest,
  },
  {
    path: "/password-reset-confirm",
    name: "password-reset-confirm",
    component: PasswordResetConfirm,
  },
  {
    path: "/account",
    name: "account",
    component: Account,
  },
  {
    path: "/:catchAll(.*)",
    name: "pagenotfound",
    component: PageNotFound,
  },
  {
    path: "/account",
    name: "account",
    component: Account,
  },
  {
    path: "/company/:companyid",
    name: "company",
    component: Company,
    props: true,
  },
  {
    path: "/details/:id",
    name: "jobdetails",
    component: JobDetail,
  },
  {
    path: "/postuler/:id",
    name: "postuler",
    component: Postuler,
  },
  {
    path: "/concept",
    name: "concept",
    component: Concept,
  },
  {
    path: "/contact",
    name: "contact_v2",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: "/applicant",
});

export default router;
