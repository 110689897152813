<template>
  <div class="d-flex align-items-center toggle-button" :style="{ '--toggle-border-color': color }">
    <label :for="id" class="form-check-label">{{ label }}</label>
    <div class="form-check form-switch d-flex align-items-center ms-2">
      <input class="form-check-input" type="checkbox" :id="id" v-model="checked" @change="onChange"
        :style="toggleStyles" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleButton",
  props: {
    label: String,
    id: String,
    value: Boolean,
    color: String,
  },
  data() {
    return {
      checked: this.value,
    };
  },
  computed: {
    toggleStyles() {
      return {
        backgroundColor: this.checked ? this.color : "white",
        borderColor: this.color,
      };
    },
  },
  methods: {
    onChange() {
      this.$emit("update:value", this.checked);
    },
  },
  watch: {
    value(newValue) {
      this.checked = newValue;
    },
  },
};
</script>

<style scoped>
.form-switch .form-check-input {
  width: 52px !important;
  height: 25px !important;
  background-image: none;
  border-radius: 12.5px;
  transition: background-color 0.2s, border-color 0.2s;
  display: inline-flex;
  align-items: center;
}

.form-switch .form-check-input::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-color: white;
  border: 1px solid var(--toggle-border-color, black);
  border-radius: 50%;
  transform: translateX(2px);
  transition: transform 0.2s, background-color 0.2s, border-color 0.2s;
}

.form-switch .form-check-input:checked::before {
  transform: translateX(29px);
  background-color: white;
  border-color: white;
}
</style>
