import axios from "axios";
import { accountService } from './account.service';

const getAxiosInstance = (contentType = "application/json") => {
  const token = accountService.getToken(); 

  return axios.create({
    baseURL: "https://web.thanks-boss.com/api/",
    headers: {
      "Content-Type": contentType,
      Authorization: token ? `Bearer ${token}` : undefined, 
    },
  });
};

export default getAxiosInstance;