<template>
  <div class="feedback-template-container">
    <h4>{{ feedback.titre }}</h4>
    <span style="display: flex; align-items: center;"><span class="star filled">&#9733;</span>{{ feedback.note }}</span>
    <div class="commentaire-container">
      <p>{{ feedback.commentaire }}</p>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/buttons/button/Button.vue";

export default {
  name: "AllFeedbacks",
  components: {
    ButtonComponent,
  },
  props: {
    feedback: {
      type: Object,
    },
  },
};
</script>

<style scoped>
h4 {
  font-size: 24px !important;
}

p {
  font-size: 18px !important;
  font-family: "Outfit300";
}

.feedback-template-container {
  border-bottom: 1px solid #c4c4c4;
  margin-top: 20px;
}

.commentaire-container {
  margin-bottom: 20px;
}

.star {
  font-size: 30px !important;
  margin-right: 10px;
  color: #FBDE2F;
}

</style>
