<template>
  <div>
    <div class="modal fade" id="infoModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="modal-container">
              <div class="popup-header">
                <h3>Alternant / Junior / Confirmé / Sénior</h3>
              </div>
              <div class="row mt-2">
                <div class="col-1">
                  <img src="@/assets/Info.png" alt="info" />
                </div>
                <div class="col-10">
                  <p>
                    On parle des niveaux de compétences professionnelles
                    couramment définis sur la base du nombre d’années
                    d’expérience.
                  </p>
                  <p>Thanks-Boss en identifie quatre :</p>
                  <ul class="mt-2">
                    <li>Alternant: 0-1 an</li>
                    <li>Junior: 2-4 ans</li>
                    <li>Confirmé: 5-9 ans</li>
                    <li>Sénior: +10 ans</li>
                  </ul>
                  <p class="mt-2">
                    <b style="font-weight: 500">A noter:</b> au-delà de la durée
                    d’expérience, d’autres critères entrent en jeu.
                  </p>
                  <p>
                    Une grille officielle n’existe pas et chaque employeur a ses
                    propres critères d’évaluation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "infomodal",
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.modal-dialog {
  width: 50%;
  height: 80%;
  display: flex;
  align-items: center;
}

.modal-content {
  display: flex;
  padding: 20px 30px 30px 30px;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid var(--Gray-Feedback-Information, #5173d7);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.modal-header {
  border-bottom: 0 !important;
  padding: 10px 10px !important;
}

.modal-body {
  padding: 0 !important;
}

h3 {
  font-size: 24px !important;
  color: #5173d7 !important;
}
</style>
