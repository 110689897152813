<template>
  <div class="sectionOurCommitment">
    <h2>Nos valeurs</h2>
    <div class="wrapperCommitment">
      <div class="cardCommitment">
        <div class="divImgCommitment">
          <img src="@/assets/recherche1.png" alt="item" />
        </div>
        <h3>Qualité</h3>
        <p>
          Chaque recommandation que nous faisons est le fruit d'une analyse rigoureuse,
          visant à garantir la satisfaction totale de nos utilisateurs. 
        </p>
      </div>
      <div class="cardCommitment">
        <div class="divImgCommitment">
          <img src="@/assets/Group.png" alt="item" />
        </div>
        <h3>Rapidité</h3>
        <p>
          Nous savons que votre temps est précieux.
          C'est pourquoi nos solutions vous offrent des résultats rapides,
          sans jamais compromettre la qualité. 
        </p>
      </div>
      <div class="cardCommitment">
        <div class="divImgCommitment">
          <img src="@/assets/Préférences.png" alt="item" />
        </div>
        <h3>Accessibilité</h3>
        <p>
          Nous croyons en l'équité d'accès à nos services, 
          en proposant des solutions à des prix compétitifs,
          pour que chacun puisse bénéficier de notre technologie avancée
        </p>
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
* {
  font-family: "Outfit500";
}
.sectionOurCommitment {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 71px 0 80px 0;
}

.sectionOurCommitment h2 {
  padding-bottom: 55px;
  font-family: "Outfit500";
}
.sectionOurCommitment .wrapperCommitment {
  display: flex;
  justify-content: space-around;
  max-width: 1230px;
  padding: 4rem;
}

.sectionOurCommitment .wrapperCommitment .cardCommitment {
  width: 334px;
  height: 408px;
}
.sectionOurCommitment .wrapperCommitment .cardCommitment h3 {
  font-family: "Outfit500";
  padding: 25px 10px 25px 10px;
  
}
.sectionOurCommitment .wrapperCommitment .cardCommitment p {
  font-family: "Outfit300";
  line-height: 25px !important;
  padding-right: 50px;
}

.sectionOurCommitment .wrapperCommitment .cardCommitment .divImgCommitment {
  display: flex;
  flex-direction: start;
}

.sectionOurCommitment .wrapperCommitment .cardCommitment .divImgCommitment img {
  height: 118px;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 1080px) {
  .sectionOurCommitment .wrapperCommitment {
    flex-direction: column;
    padding: 0;
  }
}
</style>
