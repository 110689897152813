<template>
  <div class="sectionNote">
    <h2>A noter</h2>
    <div class="allNote">
      <div class="noteLeft">
        <div class="itemsNote">
          <img src="@/assets/iconeCheck.svg" alt="Icone Check" />
          <p>Full télétravail</p>
        </div>
        <div class="itemsNote">
          <img src="@/assets/iconeCheck.svg" alt="Icone Check" />
          <p>Flexibilité sur les heures</p>
        </div>
        <div class="itemsNote">
          <img src="@/assets/iconeCheck.svg" alt="Icone Check" />
          <p>Une bonne ambiance</p>
        </div>
      </div>
      <div class="noteRight">
        <img src="@/assets/iconeCheck.svg" alt="Icone Check" />
        <p>
          La possibilité de monter en compétences sur de la tech, du marketing,
          du front ou de la communication
        </p>
      </div>
    </div>
  </div>
</template>

<script></script>

<style scoped>
* {
  font-family: "Outfit300";
}
.sectionNote {
  display: flex;
  flex-direction: column;
  padding: 55px 171px 55px 171px;
  background-color: #f5f5f5;
  margin-bottom: 55px;
}
.sectionNote h2 {
  margin-bottom: 40px;
}
.sectionNote .allNote {
  display: flex;
  justify-content: center;
}

.sectionNote .allNote .noteLeft {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}

.sectionNote .allNote .noteLeft .itemsNote {
  display: flex;
  justify-content: start;
}
.sectionNote .allNote .noteLeft .itemsNote p {
  font-size: 24px !important;
}

.sectionNote .allNote .noteLeft .itemsNote img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.sectionNote .allNote .noteRight {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.sectionNote .allNote .noteRight img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}
.sectionNote .allNote .noteRight p {
  line-height: 33px !important;
  font-size: 24px !important;
}

@media (max-width: 990px) {
  .sectionNote {
    padding: 25px;
  }
  .sectionNote .allNote {
    flex-direction: column;
    width: 100%;
  }

  .sectionNote .allNote .noteLeft {
    margin-bottom: 20px;
    width: 100%;
  }
  .sectionNote .allNote .noteRight {
    margin-bottom: 20px;
    width: 100%;
  }
}
</style>
