<template>
  <Navbar />
  <div class="router-view">
    <div class="progress-circle">
      <v-progress-circular v-if="!isReady" indeterminate></v-progress-circular>
    </div>
    <router-view v-if="isReady" />
  </div>
  <Footer />
</template>

<script>
import { getProfile } from '@/services/applyJob.service';
import Navbar from "@/components/layout/navbar/Navbar.vue";
import Footer from "@/components/layout/footer/Footer.vue";
import { mapActions } from "vuex";
import { accountService } from '@/services/account.service';
import { truConversion } from '@/utils/cookie/handleCookieChange';
import { toaster } from './utils/toast/toast';
import getAxiosInstance from './services/axios';

export default {
  name: "app",
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      isReady: false,
    };
  },
  methods: {
    ...mapActions(["handleUserChange", "handleLoggedIn"]),
    async checkUser() {
      // if user is not logged in, clear the token and remove the user from the store
      if(!this.checkLoggedIn()) {
        localStorage.removeItem("token");
        this.handleUserChange({ type: null, payload: {} });
        console.log("no user logged in !");
        return this.isReady = true;
      }
      // if user is logged in, get the user profile
      try {
        const response = await getProfile();
        this.handleUserChange({ type: null, payload: response.data });
      } catch (error) {
        toaster.showErrorPopup("Une erreur s'est produite lors de la récupération de votre profil. Veuillez vous reconnecter.");
        console.log("error getting user profile");
      } finally {
        this.isReady = true;
      }
      // refresh token
      // try {
      //   const tokenResponse = await getAxiosInstance().post('/user/api-token-refresh/', { refresh: accountService.getToken() });
      //   console.log(tokenResponse);
      // } catch (error) {
      //   console.log("token refresh failed !");
      // }
    },
    checkLoggedIn() {
      const token = accountService.getToken();
      if (token) {
        this.handleLoggedIn(true);
        return true;
      } else {
        this.handleLoggedIn(false);
        return false;
      }
    },
  },

  beforeMount() {
    // this.checkLoggedIn();
    this.checkUser();
    truConversion();

  },
};
</script>

<style>
* {
  font-family: "Outfit", sans-serif;
}
@font-face {
  font-family: "Outfit";
  src: url("@/assets/fonts/static/Outfit-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit100";
  src: url("@/assets/fonts/static/Outfit-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit200";
  src: url("@/assets/fonts/static/Outfit-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit300";
  src: url("@/assets/fonts/static/Outfit-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit400";
  src: url("@/assets/fonts/static/Outfit-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit500";
  src: url("@/assets/fonts/static/Outfit-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit600";
  src: url("@/assets/fonts/static/Outfit-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit700";
  src: url("@/assets/fonts/static/Outfit-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit800";
  src: url("@/assets/fonts/static/Outfit-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit900";
  src: url("@/assets/fonts/static/Outfit-Black.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Outfit", sans-serif;
}

h1 {
  font-size: 62px !important;
  font-weight: 700 !important;
  line-height: 78px !important;
  /* 125.806% */
}

.cm__title {
  font-size: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

@media (max-width: 768px) {
  h1 {
    font-size: 44px !important;
    line-height: 58px !important;
    /* 131.818% */
  }
}

h2 {
  font-size: 48px !important;
  font-weight: 700 !important;
  line-height: 60px !important;
}

@media (max-width: 768px) {
  h2 {
    font-size: 34px !important;
    font-weight: 700 !important;
    line-height: normal;
  }
}

h3 {
  font-size: 30px !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  h3 {
    font-size: 27px !important;
    font-weight: 500 !important;
    line-height: normal;
  }
}

h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 141% !important;
  margin-bottom: 0 !important;
}

h5 {
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 141%;
  margin-bottom: 0 !important;
}

h6 {
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 21px !important;
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  h6 {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

p {
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 21px !important;
  margin: 0 !important;
}


*,
::before,
::after {
  box-sizing: border-box;
}

.clickable {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 4px;
  height: 1px;
}

::-webkit-scrollbar-track {
  background: #f1f1f100;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #FFD92C;
}

::-webkit-scrollbar-thumb:hover {
  background: #FFD92C;
}

.primary-jobtag-item {
  display: flex;
  padding: 4px 15px 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--primary-jaune, #fbde2f);
}

.secondary-jobtag-item {
  display: flex;
  padding: 4px 10px 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--gray-clear, #e5e5e5);
}

.router-view {
  min-height: calc(100% - (87.19px + 342px));
}

.progess-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-circle {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-progress-circular {
  color: #0957bd;
}

#app,
html,
body {
  height: 100%;
}
</style>
