export const ACTIVITY_FIELDS = [
  {
    "id": 1,
    "nom": "Agroalimentaire"
  },
  {
    "id": 2,
    "nom": "Banque / Assurance"
  },
  {
    "id": 3,
    "nom": "Bois / Papier / Carton / Imprimerie"
  },
  {
    "id": 4,
    "nom": "BTP / Matériaux de construction"
  },
  {
    "id": 5,
    "nom": "Chimie / Parachimie"
  },
  {
    "id": 6,
    "nom": "Commerce / Négoce / Distribution"
  },
  {
    "id": 7,
    "nom": "Édition / Communication / Multimédia"
  },
  {
    "id": 8,
    "nom": "Électronique / Électricité"
  },
  {
    "id": 9,
    "nom": "Études et conseils"
  },
  {
    "id": 10,
    "nom": "Industrie pharmaceutique"
  },
  {
    "id": 11,
    "nom": "Informatique / Télécoms"
  },
  {
    "id": 12,
    "nom": "Machines et équipements / Automobile"
  },
  {
    "id": 13,
    "nom": "Métallurgie / Travail du métal"
  },
  {
    "id": 14,
    "nom": "Plastique / Caoutchouc"
  },
  {
    "id": 15,
    "nom": "Services aux entreprises"
  },
  {
    "id": 16,
    "nom": "Textile / Habillement / Chaussure"
  },
  {
    "id": 17,
    "nom": "Transports / Logistique"
  }
];