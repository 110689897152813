<template>
  <div class="wrapperSmartSolution">
    <div class="divLeft">
      <h2>
        <span>Tu es unique.</span> Tu as tes propres aspirations, goûts et
        talents !
      </h2>
      <p>
        <span
          >Sache qu’au-delà des diplômes et des expériences pro, notre solution
          intelligente s’intéresse à toi, à ta personnalité et tes aspirations
          !</span
        ><br /><br />
        En cheminant avec toi, elle apprend à te connaitre et à s’approcher
        toujours plus de ta vision du job idéal.
      </p>
    </div>
    <div class="divRight">
      <img
        src="@/assets/criteres_job.png"
        alt="Image de fille sur son telephone avec un chat"
      />
    </div>
  </div>
</template>

<script></script>

<style>
.wrapperSmartSolution {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 00 100px;
}

.wrapperSmartSolution .divLeft {
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  width: 50%;
}

.wrapperSmartSolution .divLeft h2 {
  font-family: "Outfit300";
  font-size: 48px !important;
  margin-bottom: 55px;
  text-align: end;
  width: 70%;
}

.wrapperSmartSolution .divLeft h2 span {
  font-family: "Outfit700";
  font-size: inherit !important;
  line-height: 0 !important;
  width: 90%;
}

.wrapperSmartSolution .divLeft p {
  font-size: 18px !important;
  line-height: 141% !important;
  text-align: end;
  width: 90%;
}

.wrapperSmartSolution .divLeft p span {
  font-family: "Outfit600";
  font-size: inherit !important;
}

.wrapperSmartSolution .divRight {
  width: 50%;
}
.wrapperSmartSolution .divRight img {
  height: 80%;
  width: 80%;
  margin-left: 25px;
}

@media screen and (max-width: 805px) {
  .wrapperSmartSolution {
    flex-direction: column;
  }
  .wrapperSmartSolution .divLeft {
    width: 80%;
    order: 2;
  }

  .wrapperSmartSolution .divLeft h2 {
    font-size: 34px !important;
    text-align: start;
    width: 100%;
  }

  .wrapperSmartSolution .divLeft p {
    text-align: start;
    width: 100%;
  }

  .wrapperSmartSolution .divRight {
    width: 100%;
    order: 1;
  }
}
</style>
