<template>
  <div class="autour">
    <ChatHeader :createConversation="createConversation" :convName="conversation" :isDisabled="isCreating"
      @renameConversation="renameConversation" style="margin-bottom: 30px" />
    <BotMessage v-if="isNew" :message="botMessage" />
    <div v-if="conversation" v-for="(message, index) in sortedMessages" :key="index">
      <BotMessage v-if="message.message_Mc" :message="message.message_Mc" />
      <UserMessage v-if="message.message_applicant" :message="message.message_applicant" />
    </div>
    <JobCarouselVue v-if="jobs && jobs.length" :jobs="jobs" @jobFavored="jobIsFavored" />
    <p v-if="jobIsLoading">Chargement des jobs...</p>
    <BotMessage v-if="jobIsNull" :message="botNoJobs" />
    <BotMessage v-if="isFavored" :message="botFavoriteMessage" />
    <UserInput :selectedChat="conversation" @newMessage="newMessage" />
    <div class="d-flex justify-content-end mt-4 clickable" @click="expandBoite">
      <img v-if="!$store.state.autonome.isExpandedChat" src="@/assets/maximize.png" alt="maximize"
        class="min-max-icon" />
      <img v-else src="@/assets/minimize.png" alt="minimize" class="min-max-icon" />
    </div>
  </div>
</template>

<script>
import BotMessage from '@/components/accompagnee/message-row/bot-message/BotMessage.vue';
import UserInput from '@/components/accompagnee/message-row/user-message/UserInput.vue';
import UserMessage from '@/components/accompagnee/message-row/user-message/UserMessage.vue';
import getAxiosInstance from '@/services/axios';
import JobCarouselVue from '@/components/accompagnee/job-carousel/JobCarousel.vue';
import ChatHeader from "@/components/accompagnee/chat-header/ChatHeader.vue";
import { mapActions } from "vuex";
import { takeCritere, titleModification } from '@/services/accompagnee.service';

export default {
  name: "ChatContainer",
  props: {
    conversation: {
      type: Object,
      default: () => { },
    },
    createConversation: {
      type: Function,
      required: true,
    },
    isCreating: Boolean,
  },
  components: {
    BotMessage,
    UserMessage,
    UserInput,
    JobCarouselVue,
    ChatHeader,
  },
  data() {
    return {
      jobs: [],
      jobIsLoading: false,
      jobIsNull: false,
      botNoJobs: "Aucun jobs trouvées...",
      botMessage: "Quels sont les missions, les domaines d'activité, mais aussi les avantages, valeurs… qui te tiennent à cœur ?",
      botFavoriteMessage: `Tu viens de placer quelques jobs en favoris (d'autres pas). Cela m'apprend des choses et m'aide à m'approcher toujours plus de tes aspirations !`,
      isNew: false,
      isFavored: false,
    };
  },
  computed: {
    sortedMessages() {
      if (this.conversation === undefined || this.conversation === null) return [];
      if (this.conversation.messages === undefined || this.conversation.messages === null || this.conversation.messages.length === 0) return this.isNew = true;
      return this.conversation.messages.sort((a, b) => new Date(a.date_creation) - new Date(b.date_creation));
    },
  },
  watch: {
    conversation: {
      handler: 'getJobsFromLastMessage',
      deep: false
    },
  },
  methods: {
    ...mapActions(['handleTitleConversationChange']),
    async getJobsFromLastMessage() {
      this.jobs = [];
      this.jobIsFavored(false);
      this.$emit("chatChangeFalse");
      const conversation = this.conversation.messages.sort((a, b) => new Date(a.date_creation) - new Date(b.date_creation));
      const [lastMessage] = conversation.slice(-1);
      if (lastMessage !== undefined && lastMessage.message_applicant && lastMessage.message_applicant !== "") {
        try {
          const response = await this.newMessage(lastMessage.message_applicant);
          this.jobs = response.data;
          //this.jobs = response.data['results'];
          console.log("results:", this.jobs);
        } catch (error) {
          console.log(error);
        }
      }
      this.$emit("chatChangeTrue");
    },
    async newMessage(message) {
      try {
        this.$emit("chatChangeFalse");
        this.jobIsLoading = true;
        this.isNew = false;
        this.jobIsFavored(false);
        const criteres = await takeCritere(message);
        criteres.data.skill = criteres.data.skill.filter(skill => skill !== '');
        const res = await this.getJobRecommendation(criteres.data);
        //const res = await this.getJobRecommendation(message);
        this.jobIsLoading = false;
        //this.jobs = res.data['results'];
        this.jobs = res.data[0];
        if (this.jobs.length === 0) {
          return this.jobIsNull = true
        } else {
          this.jobIsNull = false;
        }
        this.$emit("chatChangeTrue");
        return res;
      } catch (error) {
        this.jobIsLoading = false;
        this.jobIsNull = true;
        this.jobs = [];
        console.log(error);
      }
    },
    async getJobRecommendation(data) {
      const filteredEntries = Object.entries(data)
        .filter(([key, value]) => {
          if (Array.isArray(value) && value.length === 1 && value[0] === "") {
            return false;
          }
          return true;
        });
      const queryString = filteredEntries
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      const formattedQueryString = queryString
        .replace(/avantages/g, "nom_avantage")
        .replace(/titre/g, "title");
      return getAxiosInstance("multipart/form-date").post(`/best_job_django/?${formattedQueryString}`);
      //return getAxiosInstance("multipart/form-data").get(`/jobs/?title=${data}`);
    },
    async renameConversation(newName) {
      try {
        const response = await titleModification(this.conversation.conversation_id, newName);
        console.log(response.data);
        this.handleTitleConversationChange(response.data);
        console.log(this.conversation.titre);
      } catch (error) {
        console.log(error);
      }
    },
    jobIsFavored(fav) {
      this.isFavored = fav;
    },
    expandBoite() {
      this.$store.commit("autonome/updateExpandChat", !this.$store.state.autonome.isExpandedChat);
    },
  },
};
</script>

<style scoped>
.autour {
  position: relative;
  padding: 0px 42px 40px 42px;
  margin-bottom: 50px;
  align-items: center;
  gap: 40px;
  border-radius: 20px;
  border: 2px solid var(--primary-jaune, #fbde2f);
  background-color: #fff;
}

@media (max-width: 992px) {
  .autour {
    margin-top: 40px;
    padding: 10px;
  }
  
}

.scrollable-content {
  overflow-y: auto;
  width: 100%;
}

.expanded-scrollable-content {
  width: 100%;
}

.min-max-icon {
  width: 28px;
  height: 28px;
}
</style>
