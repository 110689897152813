<template>
  <div class="profile-child-container">
    <div class="experience-container">
      <div class="experience-container-header flex-row d-flex">
        <h1>Expérience professionnelles</h1>
        <img src="@/assets/uparrow.png" @click="toggleIsActive" :class="{ 'rotate-180': isActive }" />
      </div>
      <div v-show="isActive">
        <button @click="addNewExperience" :disabled="isAdded">
          Ajouter +
        </button>
        <!-- call ExperienceTemplate for every Experience -->
        <div v-for="(experience, index) in experienceForm" :key="index">
          <ExperienceTemplate :experience="experience" :isNew="true" @exp-submit="handleSubmit" @delete="handleDelete" />
        </div>
        <div v-for="(experience, index) in experienceList" :key="index">
          <ExperienceTemplate :experience="experience" @exp-submit="handleSubmit" @delete="handleDelete" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExperienceTemplate from '../template/ExperienceTemplate.vue';
export default {
  name: 'Experience',
  components: {
    ExperienceTemplate
  },
  props: {
    experienceList: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      isActive: false,
      isAdded: false,
      experienceForm: []
    }
  },
  methods: {
    toggleIsActive() {
      this.isActive = !this.isActive;
    },
    handleSubmit(experienceList) {
      this.$emit('experienceList-form-submit', experienceList);
      this.isAdded = false;
      this.experienceForm.shift();
    },
    handleDelete(id) {
      if (id === '') {
        this.experienceForm.shift();
        return this.isAdded = false;
      }
      this.$emit('experience-form-delete', id);
    },
    // create instance of experience template when add button is clicked
    addNewExperience() {
      if (this.experienceList.length >= 20 || this.isAdded) return;
      this.isAdded = true;
      this.experienceForm.unshift({
        id: '',
        company: '',
        place: '',
        position: '',
        debut: '',
        fin: '',
        exp_detail: '',
      });
    },
  },
}
</script>

<style scoped>
h1 {
  font-size: 30px !important;
  padding: 0 !important;
  font-weight: 500;
}

select {
  height: 45.881px;
  width: 85%;
  padding: 12px 20px;
  margin-block-start: 11px;
  border-radius: 5px;
  border: none;
}

img {
  width: 45px;
  height: 44px;
  cursor: pointer;
}

button {
  padding: 8px 25px;
  border-radius: 50px;
  border: 1.5px solid #000;
  width: 120px;
  margin: 11px 0px 32px;
}

span,
p {
  color: #000;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 141% !important;
  margin-left: 20px;
}

span {
  display: flex;
  align-items: center;
}

.profile-child-container {
  padding: 14px 34px 14px 34px;
}

.experience-container {
  width: 100%;
}

.experience-container-header {
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
</style>