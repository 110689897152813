<template>
  <form class="language-template-container flex-col" ref="language" @submit.prevent="handleSubmit">
    <div class="profile-grid">
      <div class="field">
        <label for="language">Langue</label>
        <input type="text" id="language" name="language" v-model="language.langue" placeholder="Language" required />
      </div>
      <div class="field">
        <label for="level">Niveau</label>
        <select id="level" name="level" v-model="language.niveau" required>
          <option value="" disabled selected>Choisir un niveau</option>
          <option v-for="(niveau, index) in niveau" :key="index" :value="niveau.id">
            {{ niveau.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="language-template-buttons">
      <img src="@/assets/trash.svg" @click="handleDelete"/>
      <button v-if="isNew" class="profile-save-button" type="submit">Sauvegarder</button>
    </div>
    <div class="profile-line"></div>
  </form>
</template>


<script>
export default {
  name: 'LanguageTemplate',
  props: {
    language: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      niveau: [
        { id: 1, name: 'Débutant' },
        { id: 2, name: 'Intermédiaire' },
        { id: 3, name: 'Avancé' },
        { id: 4, name: 'Bilingue' },
      ],
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('language-submit', this.language);
      this.$refs.language.reset();
    },
    handleDelete() {
      this.$emit('delete', this.language.id);
    },
  }
}
</script>

<style scoped>
input,
textarea,
select {
  background: white;
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 12px 20px;
}

textarea {
  resize: none;
  padding: 12px 20px;
}

section {
  width: 100%;
}

label {
  font-weight: 600;
  line-height: 141%;
}

img {
  cursor: pointer;
}

.language-template-container {
  display: flex;
  gap: 20px;
}

.language-template {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}


.language-template-buttons {
  display: flex;
  justify-content: space-between;
}

.field {
  display: inline-flex;
  align-items: center;
  background: white;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding-inline: 20px;
}

.field label {
  flex: 1;
}

.field input {
  flex: auto;
  margin-left: 5px;
}

.profile-line {
  border-bottom: 1px solid var(--Gray-dark, #979797);
  margin-block: 32px;
}
</style>