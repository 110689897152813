<template>
  <div class="question">
    <div class="oeil-box">
      <img src="@/assets/oeil_TB.svg" alt="oeil" />
    </div>
    <p class="jaune" v-if="message">
      {{ message }}
    </p>
    <p class="jaune" v-else>
      Quels sont les missions, les domaines d’activité, mais aussi les avantages, valeurs… qui te tiennent à cœur ?
    </p>
  </div>
</template>

<script>
export default {
  name: "BotMessage",
  props: {
    message: { type: String, required: true },
  },
};
</script>

<style scoped>
.question {
  display: flex;
  gap: 18px;
}
.oeil-box {
  width: 31px;
  height: 31px;
  flex-shrink: 0;
  background-color: #fbde2f;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.jaune {
  display: flex;
  padding: 12px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 0px 10px 10px 10px;
  background: #fbde2f;
}
</style>
