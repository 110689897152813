<template>
  <div>
    <Banniere text="Mes favoris" :src="require('@/assets/Préférences.png')" />
    <Sidebar v-if="isMobile" />
    <div class="my-container">
      <div class="row">
        <div class="sidebar col-lg-3">
          <Sidebar v-if="!isMobile" />
        </div>
        <div class="col-sm-12 col-lg-9">
          <div v-if="jobFavorite.length === 0" class="d-flex justify-content-center align-items-center">
            <h3 class="text-center">Vous n'avez pas encore de jobs favoris</h3>
          </div>
          <div v-for="job in jobFavorite" :key="job.id" class="favoris-card mb-3">
            <div class="row mt">
              <div class="col-sm-12 col-md-4 d-flex justify-content-center">
                <div class="logo">
                  <img v-if="job.job_offer.logo_url" :src="job.job_offer.logo_url" alt="company logo" @click="gotoDetail(job)" />
                  <img v-else-if="job.job_offer.source == 'FT' && job.job_offer.logo_url == null" src='@/assets/francetravail.svg' alt="company logo" @click="gotoDetail(job)" />
                  <img v-else src="@/assets/logo.png" style="margin-bottom: 50px;" @click="gotoDetail(job)" />
                </div>
              </div>
              <div class="col-sm-12 col-md-8">
                <div class="job-info">
                  <div class="d-flex justify-content-between align-items-center">
                    <h3 class="job-title mt" @click="gotoDetail(job)">
                      {{ job.job_offer.title }}
                      <img v-if="job.job_offer.source == 'FT'" src='@/assets/francetravail.svg' alt="Source Image" class="source-icon" />
                    </h3>
                    <div class="mt">
                      <img style="cursor: pointer" src="@/assets/Grand.svg" alt="grand" data-bs-toggle="modal"
                        data-bs-target="#confirmationModal" @click="getSelectedJob(job)" />
                    </div>
                  </div>
                  <h4 class="job-company mt">
                    {{ job.job_offer.company }}
                    <img v-if="getSourceImage(job.job_offer.source)" :src="getSourceImage(job.job_offer.source)"
                      alt="Source Image" class="taille" />
                  </h4>
                  <div class="d-flex align-items-center mt">
                    <img src="@/assets/location.png" alt="Location" style="height: 21px" />
                    <p class="ms-1">
                      {{ formatLocation(job.job_offer.local) }}
                    </p>
                  </div>
                  <div class="row mt">
                    <div class="d-flex align-items-center col-sm-12 col-md-6">
                      <img src="@/assets/Conditions.png" alt="Conditions" style="height: 21px" />
                      <p class="ms-1">
                        {{ job.job_offer.contract }}
                        <span v-if="job.job_offer.salaire_min != 0 || job.job_offer.salaire_max != 0">
                          /
                          {{ job.job_offer.salaire_min }}k -
                          {{ job.job_offer.salaire_max }}k €
                        </span>
                      </p>
                    </div>
                    <div class="d-flex align-items-center col-sm-12 col-md-6 mt">
                      <img src="@/assets/Briefcase.png" alt="Experience" style="height: 21px" />
                      <p class="ms-1">
                        {{ job.job_offer.expérience }}
                      </p>
                      <button class="d-flex align-items-center ms-2" data-bs-toggle="modal" data-bs-target="#infoModal">
                        <img src="@/assets/Info.png" alt="conditions" style="margin-left: 12px" />
                      </button>
                    </div>
                  </div>
                  <div class="job-footer mtb">
                    <p class="job-date">
                      {{ formatDate(job.job_offer.created_at) }}
                    </p>
                    <div class="d-flex gap-2 job-buttons">
                      <ButtonComponent text="Détails" @click="gotoDetail(job)" />
                      <ButtonComponent text="Postuler" @click="gotoPostuler(job)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="viewMore mt-3" @click="sliceFavorite">
            <div class="d-flex justify-content-center align-items-center">
              <h5>Voir plus</h5>
              <div class="ms-2">
                <img src="@/assets/collapseArrow.png" alt="downArrow" />
              </div>
            </div>
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <InfoModal />
  <ConfirmationModal :title="title" @confirm="removeFavorite" />
</template>

<script>
import getAxiosInstance from "@/services/axios";
import linkedinImage from "@/assets/linkedin.svg";
import thankBossImage from "@/assets/oeil_TB.svg";
import Sidebar from "@/components/layout/profile-sidebar/Sidebar.vue";
import Banniere from "@/components/layout/banner/Banner.vue";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import InfoModal from "@/components/modals/info/InfoModal.vue";
import ConfirmationModal from "@/components/modals/confirmation/Confirmation.vue";
import { toaster } from "@/utils/toast/toast";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Favoris",
  components: {
    Banniere,
    Sidebar,
    InfoModal,
    ButtonComponent,
    ConfirmationModal,
  },
  data() {
    return {
      allJobs: [],
      jobFavorite: [],
      currentIndex: 2,
      selectedJob: null,
      isMobile: false,
      title: "Êtes vous sûr d'enlever ce job de vos favoris ?",
    };
  },
  mounted() {
    this.fetchJobsFavorite();
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    gotoPostuler(job) {
      if (job.job_offer.source.toLowerCase() === "linkedin") {
        window.open(job.job_offer.url, "_blank");
      } else {
        this.$router.push("/postuler/" + this.job.id);
      }
    },
    gotoDetail(job) {
      this.$router.push("/details/" + job.job_offer.id);
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth < 992;
    },
    ...mapActions(["handleDeleteFavoris"]),
    async removeFavorite() {
      getAxiosInstance()
        .post(`/favorite_job/add/${this.selectedJob.id}`)
        .then(() => {
          this.handleDeleteFavoris(this.selectedJob.id);
          this.fetchJobsFavorite();
          toaster.showSuccessPopup("Job retiré des favoris");
        })
        .catch((error) => {
          toaster.showErrorPopup(
            "Erreur lors de la suppression du job des favoris"
          );
          console.log(error);
        });
    },
    getSelectedJob(job) {
      this.selectedJob = job.job_offer;
    },
    fetchJobsFavorite() {
      this.jobFavorite = this.getUser.favorie_job.sort(
        (a, b) =>
          new Date(b.job_offer.created_at) - new Date(a.job_offer.created_at)
      );
    },
    formatDate(dateString) {
      const options = { day: "2-digit", month: "long", year: "numeric" };
      return new Date(dateString).toLocaleDateString("fr-FR", options);
    },
    getSourceImage(source) {
      if (source === "Linkedin") {
        return linkedinImage;
      } else if (source === "Thank_Boss") {
        return thankBossImage;
      }
      return null;
    },
    formatLocation(location) {
      if (!location) {
        return "remote";
      }
      const commaIndex = location.indexOf(",");
      return commaIndex === -1 ? location : location.substring(0, commaIndex);
    },
    sliceFavorite() {
      this.currentIndex += 6;
      this.jobFavorite = this.allJobs.slice(0, this.currentIndex);
    },
  },
};
</script>

<style scoped>
.job-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-container {
  padding: 40px 65px;
  min-height: 500px;
}

.favoris-card {
  display: flex;
  padding: 25px 25px 25px 35px;
  flex-direction: column;
  gap: 35px;
  border-radius: 10px;
  border: 1px solid var(--Gray-medium, #c4c4c4);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.logo {
  display: flex;
  padding: 16.541px;
  justify-content: center;
  align-items: center;
  border-radius: 8.271px;
  height: 220px;
}

.logo img {
  max-height: 100%;
  width: 100%;
}

.logo,
.job-title {
  cursor: pointer;
  font-size: 25px !important;
}

.job-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

button {
  border: 0;
  padding: 0;
  background-color: white;
}

.taille {
  height: 25px;
  width: 25px;
  margin-bottom: 3px;
}

.text-center {
  margin-top: 60px;
}

.viewMore {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 4px 0 4px 0;
  border: #c4c4c4 solid 1px;
  background-color: #fff;
}

.viewMore:hover {
  background-color: #f2f2f2;
  border: #c4c4c4 solid 3px;
}

.source-icon {
  width: 50px;
  height: 50px;
}

@media (max-width: 992px) {
  .favoris-card {
    margin-top: 30px;
  }

  .logo {
    height: 184px;
  }
}

@media (max-width: 768px) {
  .my-container {
    padding: 40px 25px;
    min-height: 500px;
  }

  li a {
    font-size: 21px !important;
    font-weight: 300 !important;
  }

  .mt {
    margin-top: 20px;
  }

  .mtb {
    margin-top: 50px;
  }
}

@media (max-width: 470px) {
  .job-footer {
    flex-direction: column;
  }
  .job-buttons {
    align-self: flex-end;
    margin-top: 20px;
  }
  .job-date {
    align-self: flex-start;
  }
}

@media (max-width: 400px) {
  .job-footer {
    flex-direction: column;
  }
  .job-buttons {
    align-self: center;
    margin-top: 20px;
  }
  .job-date {
    align-self: flex-start;
  }
}
</style>
