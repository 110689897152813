<template>
<div class="down large-width">
  <div class="autocomplete-container entry_field">
    <v-text-field placeholder="Ville" v-model="city" @input="getGeoValue('text')" autocomplete="off" />

    <!-- Autocomplete suggestions for city names -->
    <ul v-if="autocompleteCityResults.length" class="autocomplete-list" style="z-index: 1">
      <li v-for="(result, index) in autocompleteCityResults" :key="index"
        @click="selectAutocompleteResult(result, 'text')">
        {{ result.nom }} - {{ result.codesPostaux.join(", ") }}
      </li>
    </ul>
  </div>
</div>

<div class="down" style="width: 100%">
  <div>
    <v-text-field label="Code postal" v-model="code_postal" @input="getGeoValue('number')" @blur="hide_dropdown"
      disabled />

    <!-- Autocomplete suggestions for postal codes -->
    <ul v-if="autocompletePostalCodeResults.length" class="autocomplete-list" style="z-index: 1">
      <li v-for="(result, index) in autocompletePostalCodeResults" :key="index"
        @click="selectAutocompleteResult(result, 'number')">
        {{ result.code }} - {{ result.nom }}
      </li>
    </ul>
  </div>
</div>

<!-- Dropdown for city suggestions based on selected postal code -->
<div v-if="showCityDropdown" class="autocomplete-container">
  <ul class="autocomplete-list" style="z-index: 2">
    <li v-for="(city, index) in citySuggestions" :key="index" @click="selectCityFromDropdown(city)">
      {{ city.nom }} - {{ city.codesPostaux.join(", ") }}
    </li>
  </ul>
</div>
</template>

<script>
export default {
  emits: ["cityEntry"],
  props: {
    sendingCity: String,
    sendingCode: Number,
  },
  data() {
    return {
      city: "",
      code_postal: "",
      autocompleteCityResults: [],
      autocompletePostalCodeResults: [],
      showCityDropdown: false,
      citySuggestions: [],
    };
  },
  watch: {
    sendingCity(newCity) {
      this.city = newCity;
    },
    sendingCode(newCode) {
      this.code_postal = newCode;
    },
  },
  mounted() {
    // this.getCityList();
  },
  created() {
    this.city = this.sendingCity;
    this.code_postal = this.sendingCode;
  },
  methods: {
    // getCityList() {
    //   console.log("appel villes");
    // },
    async getGeoValue(type) {
      const search = type === "text" ? this.city : this.code_postal;

      // Réinitialiser la liste des suggestions à chaque nouvelle saisie
      if (type === "number") {
        this.autocompletePostalCodeResults = [];
      }

      // Call the API to get autocomplete suggestions based on the type and search value
      try {
        const response = await fetch(
          `https://geo.api.gouv.fr/communes?${type}=${encodeURIComponent(
            search
          )}&fields=nom,codesPostaux&format=json`
        );
        const data = await response.json();

        if (type === "text") {
          const filteredResults = data.filter((result) =>
            result.nom.toLowerCase().startsWith(this.city.toLowerCase())
          );
          this.autocompleteCityResults = filteredResults;
        } else if (type === "number") {
          if (this.code_postal.length >= 3) {
            const filteredResults = data
              .filter((result) =>
                result.codesPostaux.some((cp) =>
                  cp.startsWith(this.code_postal.substring(0, 3))
                )
              )
              .sort((a, b) => {
                // Trier les résultats en fonction de la correspondance avec les trois premiers chiffres saisis
                const indexOfA = a.codesPostaux.findIndex((cp) =>
                  cp.startsWith(this.code_postal.substring(0, 3))
                );
                const indexOfB = b.codesPostaux.findIndex((cp) =>
                  cp.startsWith(this.code_postal.substring(0, 3))
                );

                return indexOfA - indexOfB;
              });

            this.showCityDropdown = true;
            this.citySuggestions = filteredResults;
          } else {
            this.showCityDropdown = false;
            this.citySuggestions = [];
          }

          this.autocompletePostalCodeResults = data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    selectAutocompleteResult(result, type) {
      if (type === "text") {
        this.city = result.nom;
        this.code_postal = result.codesPostaux[0];
        this.autocompleteCityResults = [];
        this.sendCity();
      } else if (type === "number") {
        this.code_postal = result.code;
        this.city = result.nom;
        this.autocompletePostalCodeResults = [];

        // Réinitialiser le menu déroulant des suggestions de villes
        this.showCityDropdown = false;
        this.citySuggestions = [];
      }
    },
    selectCityFromDropdown(city) {
      this.city = city.nom;
      this.code_postal = city.codesPostaux[0];
      this.showCityDropdown = false;
      this.citySuggestions = [];
    },
    hide_dropdown() {
      this.showCityDropdown = false;
      console.log("out");
    },
    sendCity() {
      //sending city value to the parent component
      this.$emit("cityEntry", {
        ville: this.city,
        postal_code: this.code_postal,
      });
    },
  },
};
</script>

<style scoped>
.autocomplete-container {
  position: relative;
  display: inline-block;
}

.autocomplete-list {
  list-style: none;
  padding: 0;
  position: absolute;
  top: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
}

/* li {
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid #ccc;
  } */

li:last-child {
  border-bottom: none;
}

.form-base {
  background-color: var(--dl-color-gray-light);
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(196, 196, 196, 1);
  margin-bottom: 100px;
}

.entry_field {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.form_bloc input,
.form_bloc select,
.form_bloc textarea {
  border-radius: 5px;
  border: none;
  padding: 10px 0 10px;
}

#cityAuto {
  padding: 10px 30px 10px 30px;
}

label {
  margin-bottom: -5px;
  padding-left: 20px;
}

.small-width {
  min-width: 30%;
}

.large-width {
  min-width: 70%;
}

.no-left-padding {
  padding-left: 15px;
}

#codeAuto {
  text-align: center;
  padding-left: 0px;
  margin-left: -10px;
}

ul {
  cursor: pointer;
}
</style>
