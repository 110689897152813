import { createStore } from "vuex";
import CritereModule from "./critere.module";
import AutonomeModule from "./autonome.module";

export default createStore({
  modules: {
    critere: CritereModule,
    autonome: AutonomeModule,
  },
  state: {
    token: null,
    isLoggedIn: false, 
    user: {},
  },
  mutations: {
    // log and token
    setToken(state, token) {
      state.token = token;
    },
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    }, 
    updateUser(state, { type, payload }) {
      if (type) {
        state.user[type] = [...state.user[type], payload];
      } else {
        state.user = payload;
      }
    },
    resetUser(state) {
      state.user = {};
    },
    deleteUserPropreties(state, {type, payload}) {
      state.user[type] = state.user[type].filter(list => list.id !== payload);
    },
    // alert
    updateAlert(state, payload) {
      state.user.alerte = [...state.user.alerte, payload];
    },
    deleteAlert(state, payload) {
      state.user.alerte = state.user.alerte.filter(alerte => alerte.id !== payload.id);
    },
    // candidature
    updateCandidature(state, payload) {
      state.user.postulation = [...state.user.postulation, payload];
    },
    // conversation
    addConversation(state, payload) {
      state.user.conversation = [...state.user.conversation, payload];
    },
    updateConversation(state, payload) {
      const conversationIndex = state.user.conversation.findIndex(conversation => conversation.conversation_id === payload.conversation);
      if (conversationIndex !== -1) {
        state.user.conversation[conversationIndex].messages = [
          ...state.user.conversation[conversationIndex].messages,
          payload
        ];
      }
    },
    updateTitleConversation(state, payload) {
      const conversationIndex = state.user.conversation.findIndex(conversation => conversation.conversation_id === payload.conversation_id);
      if (conversationIndex !== -1) {
        state.user.conversation[conversationIndex].titre = payload.titre;
      }
    },
    // favoris 
    addFavoris(state, payload) {
      state.user.favorie_job = [...state.user.favorie_job, payload];
    },
    deleteFavoris(state, payload) {
      state.user.favorie_job = state.user.favorie_job.filter(job => job.job_offer.id !== payload);
    },
  },
  actions: {
    // log and token
    saveToken({ commit }, token) {
      commit("setToken", token);
      commit("setLoggedIn", true);
    },
    logout({ commit }) {
      commit("setToken", null);
      commit("setLoggedIn", false);   
      commit("resetUser");
    },
    handleLoggedIn({ commit }, value) {
      commit("setLoggedIn", value);
    },
    // user
    handleUserChange({ commit }, {type, payload}) {
      commit("updateUser", {type, payload});
    },
    handleDeleteUserPropreties({ commit }, {type, payload}) {
      commit("deleteUserPropreties", {type, payload});
    },
    // alert
    handleAlertChange({ commit }, payload) {
      commit("updateAlert", payload);
    },
    handleDeleteAlert({ commit }, payload) {
      commit("deleteAlert", payload);
    },
    // candidature
    handleCandidatureChange({ commit }, payload) {
      commit("updateCandidature", payload);
    },
    // conversation
    handleAddConversation({ commit }, payload) {
      commit("addConversation", payload);
    },
    handleConversationChange({ commit }, payload) {
      commit("updateConversation", payload);
    },
    handleTitleConversationChange({ commit }, payload) {
      commit("updateTitleConversation", payload);
    },
    // favoris
    handleAddFavoris({ commit }, payload) {
      commit("addFavoris", payload);
    },
    handleDeleteFavoris({ commit }, payload) {
      commit("deleteFavoris", payload);
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    getToken: (state) => state.token,
    getUser: (state) => {
      // sort conversation by date modification
      if (state.user.conversation) {
        state.user.conversation.sort((a, b) => {
          return new Date(b.date_modification) - new Date(a.date_modification);
        });
      }
      return state.user;
    },
  },
});
