export const EXPERIENCE_FIELDS = [
  {
    "id": 1,
    "experience_job": "Stage"
  },
  {
    "id": 2,
    "experience_job": "Alternance"
  },
  {
    "id": 3,
    "experience_job": "Junior"
  },
  {
    "id": 4,
    "experience_job": "Confirmé"
  },
  {
    "id": 5,
    "experience_job": "Senior"
  },
  {
    "id": 6,
    "experience_job": "Manager"
  }
];