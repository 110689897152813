<template>
    
    <!-- page -->
    <div class="page-container-contact">

        <!-- content -->
        <div class="content-container">

            <!-- breadcrumb -->
            <div v-if="breadcrumbOn" class="breadcrumb-container">

                <img src="@/assets/logo.png" class="breadcrumb-icon"/>

                <div class="breadcrumb-bar">
                    <p class="breadcrumb-text"> > Nous contacter</p>
                </div>
                
            </div>

            <!-- wrap the content -->
            <div class="content-wrapper">

                <!-- img & info-->
                <div class="top-container">

                    <div class="img-container">
                        <img src="@/assets/Contact.png" alt="Nous contacter" class="img-contact" />
                    </div>

                    <div class="text-container">

                        <h1 class="ffoutfit700 fs38 title">Nous contacter</h1>

                        <!-- text recruter -->
                        <div v-if="recruteurModOn">
                            <div class="text-div-wrapper">

                                <div class="subtitle-wrapper">
                                    <h3 class="ffoutfit600 fs18">Vous êtes recruteur ?</h3>
                                </div>
                        
                                <p class="ffoutfit300 fs18 text">Notre équipe est à votre écoute pour tout renseignement sur nos offres de souscription et tout soutien pour appréhender sereinement l'utilisation de Thanks Boss.</p>
                            </div>
                        </div>

                        <!-- text candidat -->
                        <div v-if="candidatModOn">
                            <div class="text-wrapper">
                                
                                <div class="subtitle-wrapper">
                                    <h3 class="ffoutfit600 fs18">Vous êtes candidat ?</h3>
                                </div>

                                <p class="ffoutfit300 fs18 text">N’hésitez pas à nous adresser toute question relative à la création de votre compte candidat ou l’utilisation de la plateforme. Important : Merci de ne pas déposer de candidature via ce formulaire. Pour vous mettre en contact avec les entreprises qui recrutent, postulez sur les offres de jobs référencées sur la plateforme.</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="form-container">

                    <!-- Vuetify form : nom, prénom, email, liste déroulange bug/suggestion, bouton envoyer -->
                    <v-sheet class="mx-auto form-container" fluid>

                        <v-form @submit.prevent :disabled="submitted" ref="contactForm" class="form-form">

                            <v-text-field
                                v-model="formData.nom"
                                bg-color="rgba(255, 255, 255, 1)"
                                :rules="nameRules"
                                :disabled="submitted"
                                label="Nom"
                                variant="solo"
                            ></v-text-field>
                    
                            <v-text-field
                                v-model="formData.prenom"
                                bg-color="rgba(255, 255, 255, 1)"
                                :rules="nameRules"
                                :disabled="submitted"
                                label="Prénom"
                                variant="solo"
                            ></v-text-field>

                            <v-text-field
                                v-model="formData.email"
                                bg-color="rgba(255, 255, 255, 1)"
                                :rules="emailRules"
                                :disabled="submitted"
                                label="Email"
                                variant="solo"
                            ></v-text-field>

                            <v-autocomplete
                                clearable
                                chips
                                label="Objet"
                                bg-color="rgba(255, 255, 255, 1)"
                                :items="selectItems"
                                :rules="notEmptyRules"
                                :disabled="submitted"
                                v-model="formData.options"
                                variant="solo"
                            ></v-autocomplete>

                            <v-textarea 
                                clearable 
                                label="Message"
                                bg-color="rgba(255, 255, 255, 1)"
                                :rules="notEmptyRules"
                                :disabled="submitted"
                                v-model="formData.message"
                                variant="solo"
                            ></v-textarea>
                    
                            <v-btn type="submit" @click="submitForm" :disabled="submitted" block>Envoyer</v-btn>

                        </v-form>

                    </v-sheet>

                </div>

            </div>

        </div>

    </div>
    
</template>

<script>
import getAxiosInstance from "@/services/axios";
import { toaster } from "@/utils/toast/toast";
export default {
    data: () => ({
        /* component data */
        breadcrumbOn: true,                 // toggle le breadcrumb 
        recruteurModOn: true,               // toggle le texte recruteur
        candidatModOn: true,                // toggle le texte candidat
        submitted: false,                   // toggle si le formulaire est envoyé
        selectItems: ['Bug', 'Suggestion'],
        formData: {type_user: "applicant"},

        /* form rules */
        nameRules: [
            v => !!v || 'Le champ est requis',
            v => v.length >= 2 || 'Le nom doit comporter au moins 2 caractères',
            v => v.length <= 50 || 'Le nom ne doit pas dépasser 50 caractères',
        ],
        emailRules: [
            v => !!v || 'Le champ est requis',
            v => !/\s/.test(v) || 'L\'adresse e-mail ne doit pas contenir d\'espace',
            v => v.length >= 5 || 'L\'adresse e-mail doit comporter au moins 5 caractères',
            v => v.length <= 50 || 'L\'adresse e-mail ne doit pas dépasser 50 caractères',
            v => /^\S+@\S+$/.test(v) || 'L\'adresse e-mail doit être valide',
        ],
        notEmptyRules: [
            v => !!v || 'Le champ est requis',
        ],


    }),

    methods: {
        // validate & submit form
        async submitForm () {

            const validate = await this.$refs.contactForm.validate()

            if (validate.valid) {
                try {
                    const response = await getAxiosInstance().post("/contact/", {
                        "nom": this.formData.nom,
                        "prenom": this.formData.prenom,
                        "email": this.formData.email,
                        "objects_name": this.formData.options,
                        "message": this.formData.message,
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    if (response) {
                        this.submitted = true;
                        toaster.showSuccessPopup("Votre message a été envoyé.");
                    } else {
                        toaster.showErrorPopup("Erreur lors de l'envoi du message.");
                    }
                } catch (error) {
                    console.log(error);
                    toaster.showErrorPopup("Erreur lors de l'envoi du message.");
                }
            } else {
                toaster.showErrorPopup("Vous devez remplir le formulaire avant de l'envoyer.");
            }
            
        },
    },
}
</script>

<style>
    .page-container-contact .v-input__details {
        background-color: rgba(245, 245, 245, 1);
    }
</style>

<style scoped>
    .page-container-contact {
        padding: 0;
        margin: 0;
        width: 100vw;
        height: fit-content;
        background-color: rgba(255, 255, 255, 1);
        background-image: url("@/assets/visuel_TB_grand.png");
        background-repeat: no-repeat;
        background-position: 0px 148px, 1246px 586px;
    }

    .content-container {
        width: 100%;
        height: 100%;
        padding: 20px;
        margin: 0 auto;
    }

    .content-wrapper {
        border: 1px solid rgba(196, 196, 196, 1);
        background-color: rgba(245, 245, 245, 1);
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
        margin-bottom: 50px;
    }

    /* breadcrumb */
    .breadcrumb-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .breadcrumb-icon {
        width: 120px;
    }

    .breadcrumb-bar {
        width: fit-content;
        margin-top: 8px;
        margin-left: 10px;
    }

    .breadcrumb-text {
        border-bottom: 3px solid #FBDE2F;
    }

    /* conteneur du haut : img & text */
    .top-container {
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;
    }

    /* image de contact */
    .img-container {
        width: 70%;
        margin: 0 auto;
        justify-content: center;
    }

    .img-contact {
        width: 100%;
        display: block;
        max-width: 400px;
        margin: 0 auto;
    }

    /* textes */
    .text-container {
        width: 100%;
        margin: 0 auto;
        justify-content: center;
    }

    .title {
        text-align: center;
        margin: 20px;
    }

    .text-div-wrapper {
        margin-top: 20px;
    }

    .subtitle-wrapper {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    /* formulaire */
    .form-container {
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 40px;
        width: 95%;
    }

    /* classes utilitaires */
    /* font family */
    .ffoutfit700 {
        font-family: "Outfit700";
    }

    .ffoutfit600 {
        font-family: "Outfit600";
    }

    .ffoutfit300 {
        font-family: "Outfit300";
    }

    /* font size */
    .fs38 {
        font-size: 38px;
    }

    .fs18 {
        font-size: 18px;
    }

    /* responsive */
    @media (min-width: 600px) {
        .content-container {
            width: 90%;
        }

        .top-container {
            width: 90%;
        }
    }

    @media (min-width: 900px) {
        .content-container {
            width: 80%;
        }
    }

    @media (min-width: 1200px) {
        .content-container {
            width: 70%;
        }
    }

    @media (min-width: 1600px) {
        .content-container {
            width: 60%;
        }
    }
</style>