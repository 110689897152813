import axios from 'axios'
import { accountService } from './account.service'
import store from '@/store'
import router from '@/router'
 
const Axios = axios.create({
    baseURL: 'https://web.thanks-boss.com/api/'
})
Axios.interceptors.request.use(request => {
    if (accountService.isLogged()){
        request.headers.Authorization = 'Bearer '+ accountService.getToken()
    }
    return request
})
 
Axios.interceptors.response.use(response => { 
    return response
}, error =>{
    if(error.response.status == 401){
        accountService.logout()        
        store.commit('setLoggedIn', false);
        router.push('/login')
    }
    return Promise.reject(error);
})
export default Axios