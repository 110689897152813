import getAxiosInstance from "./axios";
import { CRITERIA_FIELDS } from "@/utils/accompagnee";

export const getTokenForGuest = async (axiosInstance) => {
  const baseEmail = 'tempuser';
  const uniqueIdentifier = new Date().getTime();
  const tempEmail = `${baseEmail}${uniqueIdentifier}@temp.com`;
  const credentials = {
    email: tempEmail,
    username: 'temp',
    password: '123',
    password2: '123',
    type_user: 'applicant'
  };
  try {
    try {
      await axiosInstance.post('/user/register/', credentials)
      const response = await axiosInstance.post("user/token/", credentials);
      const token = response.data.access;
      return `Bearer ${token}`;
    } catch (error) {
      console.error("Error fetching guest user token:", error);
      throw error;
    }
  } catch (error) {
    console.error("Error fetching guest user token:", error);
    throw error;
  }
};

export const getAllConversation = async () => {
  const axiosInstance = getAxiosInstance();
  if (axiosInstance.defaults.headers.Authorization === undefined) {
    const token = await getTokenForGuest(axiosInstance);
    axiosInstance.defaults.headers.Authorization = token;
  }
  try {
    return await axiosInstance.get("conversation/all-conversation/");
  } catch (error) {
    console.error("Error fetching conversations:", error);
    throw error;
  }
};
export const createNewConversation = async () => {
  const axiosInstance = getAxiosInstance();

  if (axiosInstance.defaults.headers.Authorization === undefined) {
    const token = await getTokenForGuest(axiosInstance);
    axiosInstance.defaults.headers.Authorization = token;
  }
  const titre = 'Nouveau chat';
  try {
    return await axiosInstance.post("conversation/new-conversation/", { titre });
  } catch (error) {
    console.error("Error fetching conversations:", error);
    throw error;
  }
};

export const titleModification = (id, newTitle) => {
  return getAxiosInstance("application/x-www-form-urlencoded").put(
    `conversation/new-conversation/${id}/`,
    {
      titre: newTitle,
    }
  );
};

export const getWholeConversation = async (conversationId) => {
  const axiosInstance = getAxiosInstance();

  if (axiosInstance.defaults.headers.Authorization === undefined) {
    const token = await getTokenForGuest(axiosInstance);
    axiosInstance.defaults.headers.Authorization = token;
  }
  try {
    return await axiosInstance.get(`conversation/full-conversation/${conversationId}/`);
  } catch (error) {
    console.error("Error fetching conversations:", error);
    throw error;
  }
};

export const sendTextMessage = async (msgData) => {
  const axiosInstance = getAxiosInstance();

  if (axiosInstance.defaults.headers.Authorization === undefined) {
    const token = await getTokenForGuest(axiosInstance);
    axiosInstance.defaults.headers.Authorization = token;
  }
  try {
    return await axiosInstance.post("conversation/messages/", msgData);
  } catch (error) {
    console.error("Error fetching conversations:", error);
    throw error;
  }
};

export const takeCritere = (phrase) => {
  const formData = new FormData();
  formData.append("phrase", phrase);
  return getAxiosInstance("multipart/form-data").post("api/critere/", formData);
};

export const saveCriteria = (conversationId, criteria) => {
  const formData = new FormData();
  formData.append("conversation", conversationId);
  formData.append("teletravail", criteria.teletravail);
  formData.append("type_contrat", criteria.type_contrat);
  formData.append("experience", criteria.experience);
  formData.append("secteur", criteria.secteur);
  CRITERIA_FIELDS.forEach((field) => {
    formData.append(field, criteria[field].join(", "));
  });

  return getAxiosInstance("multipart/form-data").post(
    "conversation/critera/",
    formData
  );
};

export const getJobRecommendation = (data) => {
  let list = "";
  for (let key in data) {
    if (data[key].length > 0 && data[key] !== "") {
      list += `${key}=${data[key]}&`;
    }
  }
  // remove & for the last element
  list = list.slice(0, -1);
  list = list.replace(/avantages/g, "nom_avantage");
  list = list.replace(/titre/g, "title");
  return getAxiosInstance("multipart/form-data").post(`/best_job_django/?${list}`);
};

export const getAllJobs = () =>
  getAxiosInstance().get(`jobs/`);

export const addToFavouritesJobs = (jobId) =>
  getAxiosInstance().post(`favorite_job/add/${jobId}`);

export const getFavouritesJobs = () =>
  getAxiosInstance().get(`favorite_job/user_favorite_companies`);
