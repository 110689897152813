<template>
  <div class="profile-child-container">
    <div class="cv-container">
      <h1>Importer mon CV</h1>
      <p class="linkedin-import-description">Privilégiez le format Linkedin. Vous gagnerez du temps dans la complétion de
        vos informations ! </p>
      <div class="import-cv-container">
        <section class="import-cv flex-col">
          <!-- if linkedin cv is already imported -->
          <div v-if="isLinkedinImported" class="flex-col">
            <div>
              <span class="text-linkedin-imported">
                <img src="@/assets/checkmark.svg" class="linkedin-checkmark-icon" />
                Format LinkedIn importé
                <img src="@/assets/Info.png" class="linkedin-info-popup-icon" @mouseout="togglePopup"
                  @mouseover="togglePopup" />
              </span>
            </div>
            <input type="file" accept=".pdf" ref="linkedinFileInput" style="display: none;"
              @change="handleLinkedinCvChange" />
            <button @click="triggerLinkedinCvInput">Importer un nouveau</button>
            <PopupLinkedin ref="popupLinkedin" class="cv-popup-linkedin-active" />
          </div>
          <!-- if linkedin cv is not imported yet -->
          <div v-else class="flex-col">
            <div>
              <span>
                Format LinkedIn
                <img src="@/assets/Info.png" class="linkedin-info-popup-icon" @mouseout="togglePopup"
                  @mouseover="togglePopup" />
              </span>
            </div>
            <input type="file" accept=".pdf" ref="linkedinFileInput" style="display: none;"
              @change="handleLinkedinCvChange" />
            <button @click="triggerLinkedinCvInput">Importer</button>
            <PopupLinkedin ref="popupLinkedin" class="cv-popup-linkedin" />
          </div>
        </section>
        <section class="import-cv">
          <!-- if user has already a cv saved -->
          <div v-if="normalCv.cv">
            <div class="d-flex flex-row">
              <img src="@/assets/checkmark.svg" class="linkedin-checkmark-icon" />
              <span class="cv-personal-type" style="margin: 0;">Format personnel importé</span>
              <input type="file" accept=".pdf" ref="fileInput" style="display: none;" @change="handleNormalCvChange" />
            </div>
        
            <!--  or change name if user import another file  -->
            <div v-if="previewNormalCvName" class="d-flex flex-row" style="align-items: center; margin-top: 5px">
              <span style="font-family: Outfit200;">{{ }}</span>
              <img src="@/assets/trash.svg" @click="deleteNormalCv" />
            </div>
            <!-- or display default saved filename -->
            <div v-else class="d-flex flex-row" style="align-items: center; margin-top: 5px">
              <span style="font-family: Outfit200;">
                {{ getDisplayName() }}
              </span>
              <img src="@/assets/trash.svg" @click="deleteNormalCv" />
            </div>
          </div>
          <!-- if user has no cv saved -->
          <div v-else>
            <span class="cv-personal-type">Format personnel <span>(facultatif)</span></span>
            <input type="file" accept=".pdf" ref="fileInput" style="display: none;" @change="handleNormalCvChange" />
            <!-- if a file is imported, display its name -->
            <div v-if="previewNormalCvName" class="d-flex flex-row" style="align-items: center; margin-top: 5px">
              <span style="font-family: Outfit200;">{{ previewNormalCvName }}</span>
              <img src="@/assets/trash.svg" @click="deleteNormalCv" />
            </div>
          </div>
          <button @click="triggerNormalCvInput">Importer</button>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import PopupLinkedin from '../popups/linkedin/PopupLinkedin.vue';
export default {
  name: 'Cv',
  components: {
    PopupLinkedin
  },
  props: {
    normalCv: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      isLinkedinImported: false,
      previewNormalCvName: '',
      previewLinkedinCvName: '',
      isCvDeleted: false,
    }
  },

  methods: {
    // hover on the info icon to display the popup
    togglePopup() {
      this.$refs.popupLinkedin.isPopupVisible = !this.$refs.popupLinkedin.isPopupVisible;
    },
    // trigger the file input when the button is clicked
    triggerNormalCvInput() {
      this.$refs.fileInput.click();
    },
    triggerLinkedinCvInput() {
      this.$refs.linkedinFileInput.click();
    },
    handleNormalCvChange(event) {
      const selectedFile = event.target.files[0];
      this.previewNormalCvName = selectedFile.name;
      if (selectedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        this.$emit('normal-cv-change', selectedFile);
      }
    },
    handleLinkedinCvChange(event) {
      const selectedFile = event.target.files[0];
      this.previewLinkedinCvName = selectedFile.name;
      if (selectedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        this.$emit('linkedin-cv-change', selectedFile);
      }
    },
    getDisplayName() {
      if (this.normalCv.cv) {
        if (typeof this.normalCv.cv.name === 'string') {
          return this.normalCv.cv.name;
        }

        return decodeURIComponent(this.normalCv.cv.substring(this.normalCv.cv.lastIndexOf('/') + 1))
      }

      return 'Aucun CV importé';
    },
    deleteNormalCv() {
      this.isCvDeleted = true;
      this.previewNormalCvName = '';
      this.$emit('delete-normal-cv');
    }
  },
}

</script>

<style scoped>
h1 {
  font-size: 30px !important;
  padding: 0 !important;
  font-weight: 500;
}

select {
  height: 45.881px;
  width: 85%;
  padding: 12px 20px;
  margin-block-start: 11px;
  border-radius: 5px;
  border: none;
}

img {
  width: 26px;
  height: 26px;
}

button {
  padding: 8px 25px;
  border-radius: 50px;
  border: 1.5px solid #000;
  width: 120px;
  margin-left: 20px;
  margin-top: 11px;
}

span,
p {
  color: #000;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 141% !important;
  margin-left: 20px;
}

span {
  display: flex;
  align-items: center;
  line-break: anywhere;
}

.cv-container {
  width: 100%;
}

.profile-child-container {
  padding-inline: 35px;
}

.import-cv-container {
  display: flex;
  flex-direction: row;

  .text-linkedin-imported {
    color: var(--Feedback-Information, #5173D7);
  }
}

.import-cv {
  width: 50%;
}

.linkedin-import-description {
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-block: 25px !important;
}

.cv-personal-type {
  span:last-child {
    font-weight: 400 !important;
    margin-left: 5px;
  }
}

.linkedin-info-popup-icon {
  cursor: pointer;
  margin-left: 10px;
}

.linkedin-checkmark-icon {
  margin-right: 10px;
}


.cv-popup-linkedin-active {
  transform: translate(55%, -165%);
}

.cv-popup-linkedin {
  transform: translate(35%, -135%);
}


@media screen and (max-width: 991px) {
  section {
    width: 100%;
  }

  .import-cv-container {
    flex-direction: column;
    gap: 20px;
  }

  .import-cv {
    width: 100%;
  }

  .cv-popup-linkedin-active {
    transform: translate(55%, -165%);
  }

  .cv-popup-linkedin {
    transform: translate(-10%, -135%);
  }
}
</style>