<template>
  <div class="heading">
    <div class="myContainer">
      <h2>C’est parti !</h2>
      <div class="row mt-6">
        <div class="col-sm-12 col-md-7 col-lg-5 col-xl-5 col-xxl-4">
          <h3 class="mb-3 search_panel">Je me laisse accompagner</h3>
          <div class="mb-3 mt-3"></div>
          <div class="accompagnee_responsive">
            <MatchAndGo @navigate="handleNavigate" class="mb-3" />
          </div>
        </div>
        <div class="col-sm-12 col-lg-1"></div>
        <div class="col-sm-12 col-lg-5 col-xl-4 col-xxl-4 d-flex">
          <div class="search_panel">
            <div class="jemedebrouille">
              <div class="inputs">
                <h3>Je me débrouille</h3>
                <div class="form">
                  <div>
                    <h4 style="margin-left: 19.5px">Quoi ?</h4>
                    <Chip
                      :placeholder="quoiPlaceholder"
                      :index="'quoi'"
                      :initialChips="quoiChips"
                      @chip-added="updatePlaceholder('keywords')"
                      @no-chips="resetPlaceholder('keywords')"
                    />
                  </div>
                  <div>
                    <div class="label_where"></div>
                    <h4 style="margin: 15px 0 0 19.5px">Où ?</h4>
                    <div class="city_field">
                      <JustVille @justCityEntry="getJustCity" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="search_button">
                <button class="search" @click="goToAutonomePage">
                  <img
                    src="@/assets/Search.png"
                    alt="search"
                    style="width: 20px; height: 20px"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="jobs">
    <div class="job-heading text-center">
      <h1>Nos offres du moment de {{ this.$store.getters.getUser.titre }}</h1>
      <h3>CDI / CDD / alternances / stages</h3>
    </div>
    <div class="jobCards">
      <div v-if="!getJobsOffers" class="spinner">
        <v-progress-circular indeterminate/>
      </div>
      <JobsList v-else :jobListTwo="getJobsOffers" :screenSize="'lg'" />
    </div>
    
    <button v-if="!isLoading" :class="getJobsOffers ? 'viewMore mb-5 see-more' : 'd-none'" @click="loadMoreJobsOffers">
      <div>
        <div class="d-flex justify-content-center align-items-center">
          <h5>Voir plus</h5>
          <div class="ms-2">
            <img src="@/assets/collapseArrow.png" alt="downArrow" />
          </div>
        </div>
      </div>
    </button>
    <div v-else class="spinner">
      <v-progress-circular indeterminate/>
    </div>
  </div>

  <section class="flex-center">
    <div class="final">
      <div class="row">
        <div class="col-sm-12 col-lg-6" style="padding: 0">
          <img
            src="@/assets/creer_compte.png"
            alt="pic"
            style="width: 100%; height: 100%"
          />
        </div>
        <div
          class="col-sm-12 col-lg-6 d-flex flex-column justify-content-center"
          style="padding: 30px; gap: 40px"
        >
          <h2>Je crée mon profil</h2>
          <p>
            Pour bénéficier d’un matching optimal, et te rendre visible auprès
            des recruteurs, n’hésites pas à créer et renseigner ton profil.
          </p>
          <ButtonComponent
            text="C’est parti !"
            style="width: 160px"
            @click="gotoRegisterPage"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "@/services/axios";
import MatchAndGo from "@/components/layout/match-go/MatchAndGo.vue";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import Chip from "@/components/chip/Chip.vue";
import JobsList from "@/components/home/job-list/JobsList.vue";
import JustVille from "@/components/inputs/city-droplist/JustVille.vue";

export default {
  name: "home",
  components: {
    MatchAndGo,
    ButtonComponent,
    Chip,
    JobsList,
    JustVille,
  },
  data() {
    return {
      isLoading: false,   //  datas in transit, display a spinner
      currentPage: 1,
      getJobsOffers: "",
      quoiPlaceholder: "Mots clés",
      ouPlaceholder: "Ville ou code postal",
      currentText: "",
    };
  },

  methods: {
    gotoRegisterPage() {
      this.$router.push({
        name: "register",
      });
    },
    handleNavigate(routeName) {
      this.$router.push({ name: routeName });
    },
    updatePlaceholder(val) {
      if (val === "keywords") {
        this.quoiPlaceholder = "Ajouter";
      } else {
        this.ouPlaceholder = "Ajouter";
      }
      this.currentText = "";
    },
    resetPlaceholder(val) {
      if (val === "keywords") {
        this.quoiPlaceholder = "Mots clés";
      } else {
        this.ouPlaceholder = "Ville ou code postal";
      }
    },

    goToAutonomePage() {
      this.$router.push({
        name: "autonome",
      });
    },
    handleNavigate(routeName) {
      this.$router.push({ name: routeName });
    },

    async fetchJobsOffers() {
      try {
        const response = await axios().get(
          `/job-offers/top-ten/?page=${this.currentPage}`
        );
        this.getJobsOffers = [...this.getJobsOffers, ...response.data];
      } catch (error) {
        console.error("Error retrieving user experience:", error);
      } finally {
        this.isLoading = false;
      }
    },

    async loadMoreJobsOffers() {
      this.currentPage += 1;
      this.isLoading = true;
      await this.fetchJobsOffers();
    },

    goToAutonomePage() {
      this.$router.push({
        name: "autonome",
        query: { quoi: this.quoi, ou: this.ou },
      });
    },
    getJustCity(newJustCity) {
      this.$store.commit("autonome/addOneOuChip", newJustCity.ville);
    },
  },

  created() {
    this.fetchJobsOffers();
  },

  computed: {
    quoiChips() {
      return this.$store.state.autonome.quoiChips;
    },
    ouChips() {
      return this.$store.state.autonome.ouChips;
    },
  },
};
</script>

<style scoped>
.label_where {
  height: 10px;
}

.heading {
  background-color: #fbde2f;
  display: flex;
  flex-direction: column;
  background-image: url("@/assets/circle.png");
  background-size: 900px;
  background-repeat: no-repeat;
  background-position: 60vw -100px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.myContainer {
  padding: 71px 62px;
  min-height: 398px;
}

.logo {
  margin: -10px 0 0 -120px;
}

.see-more {
  margin-top: 20px;
}
.goToAccompagneePage {
  display: flex;
  margin-top: 20px;
  margin-left: 61px;
  padding: 25px 15px 25px 55px;
  justify-content: center;
  border-radius: 20px;
  border: 2px solid var(--Primary-jaune, #fbde2f);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
  position: relative;
}

h6 {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
}

span {
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 21px !important;
}

.form {
  margin-top: 20px;
}

.form-control {
  margin-top: 15px;
  padding: 12px 15px;
  border-radius: 10px 0px 10px 10px;
  border: 0 !important;
  background: var(--gray-light, #f5f5f5);
  min-height: 45px;
  margin-left: 5px;
  overflow-y: hidden;
  resize: none;
  width: 80%;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #000;
  padding: 7px 8px 8px 8px;
  width: 34px;
  height: 34px;
  margin-left: 15px;
}

.jobs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-heading {
  padding: 83px 165px 87px 175px;
}

.viewMore {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 40px;
  padding: 4px 0 4px 0;
  border: #c4c4c4 solid 1px;
  background-color: #fff;
}

.viewMore:hover {
  background-color: #f2f2f2;
  border: #c4c4c4 solid 3px;
}

.CLIN {
  border: 1px solid #c4c4c4;
  overflow: hidden;
  max-width: 80%;
}

.final {
  padding: 39px 130px 31px 130px;
  max-width: 80%;
}

.search_panel {
  width: 100%;
}

.right_panel {
  display: flex;
  flex-direction: row;
}

.search_button {
  display: flex;
  align-self: flex-end;
  padding-bottom: 5px;
  padding-left: 5px;
}

.jemedebrouille {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.inputs {
  width: 100%;
}

.jobCards {
  margin: auto;
}

@media (max-width: 1062px) {
  .final {
    padding: 30px 40px;
  }
  .heading {
    background-position: 50vw -200px;
  }
}

@media (max-width: 992px) {
  .search_panel {
    margin-top: 50px;
  }
  .jemedebrouille {
    margin-top: 20px;
  }

  .accompagnee_responsive {
    max-width: 45vw;
  }
}

@media (max-width: 768px) {
  .logo {
    margin: -10px -100px;
  }

  .go_to_acompany_page {
    margin: 20px 0;
  }

  .job-heading {
    padding: 46px 25px 53px 25px;
  }
  .heading {
    background-position: 40vw -200px;
  }

  .accompagnee_responsive {
    max-width: 75vw;
  }
}

@media (max-width: 440px) {
  .CLIN {
    padding: 30px 20px;
  }

  .final {
    padding: 30px 20px;
  }
  .heading {
    background-position: 20vw -200px;
  }

  .myContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
  .accompagnee_responsive {
    max-width: 90vw;
  }
}
@media (max-width: 340px) {
  .accompagnee_responsive {
    max-width: 100vw;
    margin-left: -10px;
    margin-right: -10px;
  }
  .jemedebrouille {
    flex-direction: column;
  }
  .search {
    margin-top: 30px;
  }
}

.city_field {
  background-color: white;
  margin-top: 12px;
  max-height: 55px;
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
