export const CONTRACT_FIELDS = [
  {
    "id": 1,
    "contrat": "CDI"
  },
  {
    "id": 2,
    "contrat": "CDD"
  },
  {
    "id": 3,
    "contrat": "Stage"
  },
  {
    "id": 4,
    "contrat": "Intérim"
  },
  {
    "id": 5,
    "contrat": "VIE"
  }
];