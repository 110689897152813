<template>
  <div class="profile-child-container">
    <div class="qualification-container">
      <div class="qualification-container-header flex-row d-flex">
        <h1>Diplômes</h1>
        <img src="@/assets/uparrow.png" @click="toggleIsActive" :class="{ 'rotate-180': isActive }">
      </div>
      <div v-show="isActive">
        <button @click="addQualification" :disabled="isAdded">
          Ajouter +
        </button>
        <!-- call QualificationTemplate for every Qualification -->
        <div v-for="(qualification, index) in qualificationForm" :key="index">
          <QualificationTemplate
            :qualification="qualification"
            :isNew="true"
            @qualification-submit="handleSubmit"
            @delete="handleDelete"
          />
        </div>
        <div v-for="(qualification, index) in qualificationList" :key="index">
          <QualificationTemplate
            :qualification="qualification"
            @qualification-submit="handleSubmit"
            @delete="handleDelete"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QualificationTemplate from '../template/QualificationTemplate.vue';
export default {
  name: 'Qualification',
  components: {
    QualificationTemplate
  },
  props: {
    qualificationList: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      isActive: false,
      isAdded: false,
      qualificationForm: []
    }
  },
  methods: {
    toggleIsActive() {
      this.isActive = !this.isActive;
    },
    handleSubmit(qualificationList) {
      this.$emit('qualification-form-submit', qualificationList);
      this.isAdded = false;
      this.qualificationForm.shift();
    },  
    handleDelete(id) {
      if(id === '') {
        this.qualificationForm.shift();
        this.isAdded = false;
        return;
      }
      this.$emit('qualification-form-delete', id);
    },
    addQualification() {
      if (this.qualificationList.length >= 20 || this.isAdded) return;
      this.isAdded = true;
      this.qualificationForm.unshift({
        id: '',
        degree: '',
        school: '',
        debut: '',
        fin: '',
      });
    }
  },
}

</script>

<style scoped>


h1 {
  font-size: 30px !important;
  padding: 0 !important;
  font-weight: 500;
}

select {
  height: 45.881px;
  width: 85%;
  padding: 12px 20px;
  margin-block-start: 11px;
  border-radius: 5px;
  border: none;
}

img {
  width: 45px;
  height: 44px;
  cursor: pointer;
}

button {
  padding: 8px 25px;
  border-radius: 50px;
  border: 1.5px solid #000;
  width: 120px;
  margin: 11px 0px 32px; 
}

span, p {
  color: #000;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 141% !important;
  margin-left: 20px;
}

span {
  display: flex;
  align-items: center;
}

.profile-child-container {
  padding: 14px 34px 14px 34px;
}

.qualification-container {
  width: 100%;
}

.qualification-container-header {
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
</style>