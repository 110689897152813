<template>
  <form @submit.prevent="saveTextMessage" class="d-flex gap-3">
    <textarea v-model="description" @keydown.enter.prevent="saveTextMessage" placeholder="..."
      class="form-control resize-none" rows="1" @input="adjustTextarea"></textarea>
    <button class="btn send-btn align-self-end p-0 bg-white border-0" :disabled="isSending" @click="saveTextMessage">
      <img src="@/assets/Send.svg" />
    </button>
  </form>
</template>

<script>
import { sendTextMessage } from "@/services/accompagnee.service";
import { mapActions } from "vuex";

export default {
  name: "UserInput",
  props: {
    selectedChat: { type: Object, required: true },
    updateChat: { type: Function, required: true },
  },
  data() {
    return {
      isSending: false,
      description: "",
    };
  },
  // watch: {
  //   description() {
  //     this.$nextTick(() => this.adjustTextarea());
  //   },
  // },
  methods: {
    ...mapActions(['handleConversationChange']),
    saveTextMessage() {
      const message = this.description.trim();
      if (!message) {
        return;
      }
      const msgData = {
        message_Mc:
          "Quels sont les missions, les domaines d’activité, mais aussi les avantages, valeurs… qui te tiennent à cœur ?",
        message_applicant: message,
        conversation: this.selectedChat.conversation_id,
      };

      this.isSending = true;
      sendTextMessage(msgData)
        .then((res) => {  
          // store the updated chat in the store
          this.handleConversationChange(res.data);
          this.$emit("newMessage", message);
          this.description = "";
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    adjustTextarea() {
      const textArea = this.$el.querySelector(".form-control");
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    },
  },
};
</script>

<style scoped>
.form-control {
  margin-top: 25px;
  margin-bottom: 5px;
  padding: 12px 15px;
  border-radius: 10px 0px 10px 10px;
  border: 0 !important;
  background: var(--gray-light, #f5f5f5);
  min-height: 45px;
  margin-left: 5px;
  overflow-y: hidden;
  resize: none;
}
</style>
