<template>
  <div class="pd">
    <div class="title-container my-4" :class="{ editing: isEditing }">
      <div v-if="!isEditing" class="d-flex justify-content-between w-100">
        <h4>
          {{ selectedChat.titre }}
        </h4>
        <div @click="toggleEditMode">
          <img src="@/assets/petit.svg" alt="petit" />
        </div>
      </div>
      <div v-else class="conversation-form">
        <input
          v-model="editedTitle"
          @keydown.enter="saveTitleModification"
          :placeholder="getSelectedChatTitle"
          class="w-100"
        />
        <button
          class="save"
          @click="saveTitleModification"
          :disabled="isSaving"
        >
          <h4>Save</h4>
        </button>
      </div>
    </div>
    <div class="mb-4">
      <p>
        <span class="foncer" style="color: black"
          >Rechercher un job, renseigner les filtres... peut être
          fastidieux...</span
        >
        Décris tes souhaits et profite d’une sélection de mots clés sur mesure !
      </p>
    </div>
  </div>
</template>

<script>
import { titleModification } from "@/services/accompagnee.service";

export default {
  name: "WelcomeMessage",
  props: {
    selectedChat: { type: Object, required: true },
    updateChat: { type: Function, required: true },
  },
  data() {
    return {
      isEditing: false,
      editedTitle: "",
      isSaving: false,
    };
  },

  methods: {
    toggleEditMode() {
      this.isEditing = !this.isEditing;
      if (this.isEditing) {
        this.editedTitle = this.selectedChat.titre;
      }
    },

    saveTitleModification: function () {
      this.isSaving = true;
      titleModification(this.selectedChat.conversation_id, this.editedTitle)
        .then((res) => {
          this.updateChat(this.selectedChat.conversation_id, {
            titre: res.data.titre,
            date_modification: res.data.date_modification,
          });
          this.isEditing = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<style scoped>
p {
  font-family: 'Outfit300';

}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 30px;
}
.conversation-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
}
.save {
  height: 21px;
  background-color: #fbde2f;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.save h4 {
  margin-bottom: 0 !important;
}

@media (min-width: 1061px) {
  .pd {
    padding: 0 20px;
  }
}
</style>
