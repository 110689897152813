<template>
  <div class="modal fade" id="randomID" tabindex="-1" aria-hidden="true" data-bs-keyboard="false" ref="randomID">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="autour">
            <BoiteHeader :create-conversation="createConversation" :selected-chat-id="selectedChatId"
              :is-disabled="isCreating || isLoading" />
            <div class="scrollable-content">
              <div v-if="isLoading">Chargement des conversations...</div>
              <div v-if="isCreating">Création de la conversation...</div>
              <div v-if="!!selectedChat && !isLoading && !isCreating"
                style="height: 100%; display: flex; flex-direction: column; justify-content:space-between;">
                <div>
                  <WelcomeMessage :selected-chat="selectedChat" :update-chat="updateChat" class="my-4" />
                  <BotMessage v-if="!selectedChat.contents.length"
                    :message="'Quels sont les missions, les domaines d’activité, mais aussi les avantages, valeurs… qui te tiennent à cœur ?'" />
                  <MessagesContainer v-for="(content, index) in selectedChat.contents" :key="index"
                    :selected-chat="selectedChat" :content="content" :update-chat="updateChat"
                    :is-last="index === selectedChat.contents.length - 1" :index="index" />
                </div>
                <div>
                  <UserMessage :selected-chat="selectedChat" :update-chat="updateChat" />
                </div>
                <div class="d-flex justify-content-end mt-4 extand-btn">
                  <img src="@/assets/colapsArrow.svg" alt="colapsArrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeMessage from "@/components/accompagnee/message-row/welcome-message/WelcomeMessage.vue";
import BotMessage from "@/components/accompagnee/message-row/bot-message/BotMessage.vue";
import UserMessage from "@/components/accompagnee/message-row/user-message/UserMessage.vue";

export default {
  name: "ChatContainerMobile",
  components: {
    WelcomeMessage,
    BotMessage,
    UserMessage,
  },

  props: {
    chats: { type: Array, required: true },
    createConversation: { type: Function, required: true },
    selectedChatId: Number,
    isCreating: Boolean,
    isLoading: Boolean,
    updateChat: { type: Function, required: true },
  },

  computed: {
    selectedChat: function () {
      return this.chats && this.chats.find(
        (chat) => chat.conversation_id === this.selectedChatId
      );
    },
  },

  data() {
    return {
      isModalVisible: false,
    };
  },
};
</script>

<style scoped>
.modal-content {
  height: 100vh;
}

.autour {
  position: relative;
  padding: 0px 42px 40px 42px;
  align-items: center;
  gap: 40px;
  border-radius: 20px;
  border: 2px solid var(--primary-jaune, #fbde2f);
  background-color: #fff;
  height: 100%;
}

.scrollable-content {
  overflow-y: auto;
  width: 100%;
  padding: 20px;
  height: 100%;
}

.modal-dialog {
  margin: 0px;
}

.modal-content {
  border-radius: 0px;
}

@media (max-width: 500px) {
  .modal-body {
    padding: 0;
  }

  .modal-header {
    background-color: #F5F5F5;
    height: 46px;
  }

  .autour {
    border: 0;
    padding: 0;
  }

  .extand-btn {
    display: none !important;
  }
}
</style>


