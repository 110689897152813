<template>
  <div>
    <Banner text="Mes alertes" :src="require('@/assets/Layer 2.png')" />
    <Sidebar v-if="isMobile" />
    <div class="my-container">
      <div class="row">
        <div class="col-sm-12 col-lg-3">
          <Sidebar v-if="!isMobile" />
        </div>
        <div class="col-sm-12 col-lg-9">
          <ButtonComponent
            text="Ajouter une alerte"
            data-bs-toggle="modal"
            data-bs-target="#alertFieldModal"
          />
          <div
            v-if="alerte.length === 0"
            class="d-flex justify-content-center align-items-center"
          >
            <h3 class="text-center">Vous n'avez pas encore d'alertes</h3>
          </div>
          <div v-for="element in alerte" :key="element.nom">
            <AlertTemplate :element="element" @delete-alerte="deleteAlerte" />
          </div>
        </div>
      </div>
    </div>
    <AlertFieldModal ref="alertFieldModal" @new-alert="newAlerte" />
    <AvantageDetailModal />
  </div>
</template>

<script>
import { toaster } from "@/utils/toast/toast";
import { mapGetters, mapActions } from "vuex";
import getAxiosInstance from "@/services/axios";
import Sidebar from "@/components/layout/profile-sidebar/Sidebar.vue";
import Banner from "@/components/layout/banner/Banner.vue";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import AlertFieldModal from "@/components/modals/alert/alert-create/AlertCreate.vue";
import AvantageDetailModal from "@/components/modals/avantage/AvantageDetail.vue";
import AlertTemplate from "@/components/alerts/alert-template/AlertTemplate.vue";

export default {
  name: "AlertPage",
  components: {
    Banner,
    Sidebar,
    ButtonComponent,
    AlertFieldModal,
    AvantageDetailModal,
    AlertTemplate,
  },
  data() {
    return {
      alerte: [],
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    ...mapActions(["handleAlertChange", "handleDeleteAlert"]),
    fetchAlerts() {
      // get alerts from store and sort them by id
      const list = this.getUser.alerte;
      list.sort((a, b) => b.id - a.id);
      // then assign them to the data
      this.alerte = list;
    },
    async newAlerte(element) {
      try {
        await getAxiosInstance().post("/alerte/", element);
        // reload page cause of pop up
        window.location.reload();
      } catch (error) {
        toaster.showErrorPopup("Erreur lors de l'ajout de l'alerte");
        console.log(error);
      }
    },
    async deleteAlerte(element) {
      try {
        await getAxiosInstance().delete(`/alerte/${element.id}`);
        const list = this.getUser.alerte;
        // delete element from list
        const index = list.findIndex((x) => x.id === element.id);
        list.splice(index, 1);
        // mutate the store
        await this.handleDeleteAlert(list);
        // and update  with new list from store
        this.fetchAlerts();
        toaster.showSuccessPopup("Alerte supprimée avec succès");
      } catch (error) {
        toaster.showErrorPopup("Erreur lors de la suppression de l'alerte");
        console.log(error);
      }
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth < 992;
    },
  },
  created() {
    this.fetchAlerts();
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style scoped>
.my-container {
  padding: 40px 65px;
  min-height: 500px;
}

.alert-card {
  display: flex;
  padding: 25px 25px 25px 35px;
  flex-direction: column;
  gap: 35px;
  border-radius: 10px;
  border: 1px solid var(--Gray-medium, #c4c4c4);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.setting-icon {
  height: 43px;
}

.text-center {
  margin-top: 60px;
}

@media (max-width: 768px) {
  .my-container {
    padding: 40px 25px;
    min-height: 500px;
  }

  li a {
    font-size: 21px !important;
    font-weight: 300 !important;
  }

  .setting-icon {
    height: 25px;
  }
}
</style>
