<template>
  <div class="modal fade" id="sendFeedbackModal" tabindex="-1" aria-hidden="true" data-bs-keyboard="false"
    ref="sendFeedbackModal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div>
            <div style="width: 100%; display:flex; justify-content: flex-end">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <h3 class="modal-title">Evaluer cette entreprise</h3>
            <span>Partage ton avis de manière anonyme.&nbsp;Tout d'abord, attribue une note à différents aspects listés
              ci-dessous.</span>
          </div>
        </div>
        <div class="modal-body">
          <div class="rating-row" v-if="valeurList > 0">
            <h4 class="row-title">Valeurs</h4>
            <div v-for="(valeurs, index) in valeurList" :key="index">
              <div>
                <span>{{ valeurs.name }}</span>
                <div>
                  <span v-for="i in 5" :key="i">
                    <span v-html="i <= valeurs.rating ? '&#9733;' : '&#9734;'"
                      :class="i <= valeurs.rating ? 'star filled' : 'star empty'"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="rating-row" v-if="avantageList > 0">
            <h4 class="row-title">Avantages</h4>
            <div v-for="(valeurs, index) in avantageList" :key="index">
              <div>
                <span>{{ valeurs.categorie }}</span>
                <div>
                  <span v-for="i in 5" :key="i">
                    <span v-html="i <= valeurs.rating ? '&#9733;' : '&#9734;'"
                      :class="i <= valeurs.rating ? 'star filled' : 'star empty'"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="feedback-row">
            <h4 class="row-title">Soumettre un avis</h4>
            <span>Apporte quelques précisions concernant un aspect défini ou partage ton appréciation globale de
              l'entreprise :</span>
            <div style="margin-top: 10px">
              <v-text-field label="Titre" v-model="feedbackFormData.titre" />
              <v-textarea label="Ton avis"  id="feedback" rows="3" placeholder="800 caractères" style="resize: none;" v-model="feedbackFormData.commentaire" />
              <div>
                <h4 class="row-title">Ta note générale</h4>
                <span v-for="i in 5" :key="i" @click="handleRating(i)">
                  <span v-html="i <= globalRating ? '&#9733;' : '&#9734;'"
                    :class="i <= globalRating ? 'star filled' : 'star empty'"></span>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <ButtonComponent text="Annuler" data-bs-dismiss="modal" />
            <ButtonComponent text="Envoyer" data-bs-dismiss="modal" @click="submitFeedback" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/buttons/button/Button.vue";
import getAxiosInstance from "@/services/axios";

export default {
  name: "SendFeedbackModal",
  components: {
    ButtonComponent,
  },
  props: {
    valeurList: {
      type: Array,
    },
    avantageList: {
      type: Array,
    },
  },
  data() {
    return {
      globalRating: 0,
      feedbackFormData: {},
    };
  },
  methods: {
    handleRating(rating) {
      this.globalRating = rating;
    },
    submitFeedback() {
      this.feedbackFormData.note = this.globalRating;
      this.$emit('sendFeedback', this.feedbackFormData);
    }
  },
};
</script>

<style scoped>
h4 {
  font-size: 24px !important;
}

input {
  border: 1px solid #c4c4c4 !important;
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 12px 20px;
}

.modal-header {
  border-bottom: 0px;
  padding: 20px 20px 0px 20px;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  padding-top: 10px;
  padding-inline: 20px;
}

.row-title {
  margin-top: 20px !important;
  margin-bottom: 15px !important;
}


.star {
  cursor: pointer;
  font-size: 30px !important;
  margin-right: 10px;
  color: #FBDE2F;
}

.rating-row {
  padding-block: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-footer {
  justify-content: center;
}
</style>
