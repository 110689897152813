<template>
  <form class="experience-template-container flex-col" ref="experienceForm" @submit.prevent="handleSubmit">
    <section class="experience-template-first-section profile-grid">
      <div class="experience-template-left">
        <div class="field">
          <label for="job_title">Titre</label>
          <input type="text" id="job_title" name="job_title" v-model="experience.position"
            placeholder="Votre fonction" required />
        </div>
        <div class="field">
          <label for="company">Entreprise</label>
          <input type="text" id="company" name="company" v-model="experience.company"
            placeholder="Nom de l'entreprise" required />
        </div>
      </div>
      <div class="experience-template-right">
        <div class="experience-template-date-input">
          <div class="field">
            <label for="debut_date">De</label>
            <input type="date" id="debut_date" name="debut_date" v-model="experience.debut" placeholder="Du"
              required />
          </div>
          <div class="field">
            <label for="fin_date">Au</label>
            <input type="date" id="fin_date" name="fin_date" v-model="experience.fin" placeholder="Au" required />
          </div>
        </div>
        <div class="field">
          <label for="place">Lieu</label>
          <input type="text" id="place" name="place" v-model="experience.place" placeholder="Ville, commune"
            required />
        </div>
      </div>
    </section>
    <section class="experience-template-second-section">
      <label for="description">Détail</label>
      <textarea id="description" name="description" maxlength="800" v-model="experience.exp_detail"
        placeholder="Description" required></textarea>
    </section>
    <div class="experience-template-buttons">
      <img src="@/assets/trash.svg" @click="handleDelete" />
      <button v-if="isNew" class="profile-save-button" type="submit">Sauvegarder</button>
    </div>
    <div class="profile-line"></div>
  </form>
</template>


<script>
import { toaster } from '@/utils/toast/toast';
export default {
  name: 'ExperienceTemplate',
  props: {
    experience: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSubmit() {
      if (new Date(this.experience.debut) > new Date(this.experience.fin)) {
        toaster.showErrorPopup('La date de début doit être antérieure à la date de fin');
        return;
      }
      this.$emit('exp-submit', this.experience);
      this.$refs.experienceForm.reset();
    },
    handleDelete() {
      this.$emit('delete', this.experience.id);
    },
  },
}
</script>

<style scoped>
input,
textarea {
  background: white;
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 12px 20px;
}

textarea {
  resize: none;
  padding: 12px 20px;
}

section {
  width: 100%;
}

label {
  font-weight: 600;
  line-height: 141%;
}

img {
  cursor: pointer;
}

.experience-template-container {
  display: flex;
  gap: 20px;

}

.profile-line {
  border-bottom: 1px solid var(--Gray-dark, #979797);
  margin-block: 32px;
}

.experience-template-left {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.experience-template-date-input {
  gap: 20px;
  justify-content: space-between;
}

.experience-template-second-section {
  label {
    margin: 25px 0px 7.8px 20px;
    font-weight: 600;
    line-height: 141%;
  }
}

.experience-template-right {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 20px;
}


.experience-template-date-input {
  .field {
    width: 46%;
  }

  .field:last-child {
    margin-left: 8%;
  }
}


.experience-template-buttons {
  display: flex;
  justify-content: space-between;
}


.field {
  display: inline-flex;
  align-items: center;
  background: white;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding-inline: 20px;
}

.field label {
  flex: 1;
}

.field input {
  flex: auto;
  margin-left: 5px;
}
</style>