import * as CookieConsent from "vanilla-cookieconsent";

export const truConversion = () => {
  if (CookieConsent.acceptedService('TruConversion', 'analytics')) {
    var _tip = _tip || [];
    (function (d, s, id) {
      var js, tjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.async = true;
      js.src = d.location.protocol + '//app.truconversion.com/ti-js/37118/cccde.js';
      tjs.parentNode.insertBefore(js, tjs);
    }(document, 'script', 'ti-js'));
  } else {
    var truConversionScript = document.getElementById('ti-js');
    if (truConversionScript) {
      truConversionScript.parentNode.removeChild(truConversionScript);
      window.location.reload();
    }
  }
}