<template>
  <div>
    <div class="modal fade" id="avantageDetail" tabindex="-1" aria-labelledby="avantageModalLabel" aria-hidden="true"
      data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="modal-container">
              <div class="title">
                <img src="@/assets/Group.svg" alt="lamp" style="margin-right: 15px;">
                <h3>Avantages</h3>
              </div>
              <div class="description">
                <span>
                  Quelques suggestions de mots clés qui peuvent t'intéresser:
                </span>
              </div>
              <div class="categorie" v-for="(elements, categorie) in avantages" :key="categorie">
                <div class="categorie-title" @click="toggleIsOpen(categorie)">
                  <h5>{{ categorie }}</h5>
                  <img src="@/assets/uparrow.png" :class="{ 'rotate-180': isOpen[categorie] }" />
                </div>
                <div v-if="isOpen[categorie]" v-for="element in elements" :key="element.id">
                  <label>
                    <input type="checkbox" v-model="selectedAvantages[element.nom]" :value="element.id">
                    {{ element.nom }}
                  </label>
                </div>
              </div>
              <div class="buttons">
                <button data-bs-dismiss="modal">Annuler</button>
                <button data-bs-dismiss="modal" @click="saveData">Enregistrer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AVANTAGE_FIELDS } from "@/utils/base/avantage";

export default {
  name: "avantageDetail",
  components: {
  },
  data() {
    return {
      AVANTAGE_FIELDS,
      avantages: [],
      isOpen: {},
      selectedAvantages: {},
    };
  },
  props: {
    savedAvantages: {
      type: Object,
      default: false,
    },
  },
  created() {
    // adding saved avantages to selectedAvantages
     Object.keys(this.savedAvantages).forEach(id => {
      const nom = this.savedAvantages[id];
      this.selectedAvantages[nom] = true;
    });
    // grouping avantages by categorie
    this.avantages = this.AVANTAGE_FIELDS.reduce((acc, element) => {
      if (!acc[element.categorie]) {
        acc[element.categorie] = [];
      }
      const existingElementIndex = acc[element.categorie].findIndex(el => el.nom === element.nom);
      if (existingElementIndex !== -1) {
        acc[element.categorie][existingElementIndex].id = element.id;
      } else {
        acc[element.categorie].push(element);
      }
      return acc;
    }, {});
  },
  methods: {
    toggleIsOpen(categorie) {
      this.isOpen[categorie] = !this.isOpen[categorie];
    },
    saveData() {
      const selectedIds = Object.keys(this.selectedAvantages).filter(id => this.selectedAvantages[id]);
      this.$emit("avantage-selected", selectedIds);
    },
  },
};
</script>

<style scoped>
.flex-col {
  flex-direction: column !important;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.categorie-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categorie {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid gray;
  cursor: pointer;
  padding-block: 10px;
}


img {
  width: 35px;
  height: 34px;
  cursor: pointer;
  margin-top: 7px;
}

.description {
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttons button {
  padding: 8px 25px;
  border-radius: 50px;
  border: 1.5px solid #000;
  width: 130px;
  margin-block: 20px;
  margin-inline: 10px;
}

.buttons button:last-child {
  color: white;
  background-color: #000;
}

.rotate-180 {
  transform: rotate(180deg);
}


.modal-dialog {
  width: 60%;
  max-height: 90%;
  overflow-y: auto;
}

.modal-content {
  width: 100%;
  height: 100%;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-body {
  padding: 0 !important;
  overflow-y: auto;
}

.modal-container {
  padding: 0 63.5px;
}

h4 {
  font-size: 27px !important;
  font-weight: 500 !important;
}

.mygap {
  margin-top: 20px;
}

.select-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 49px;
  padding: 12px 20px;
  border-radius: 5px;
  border: 1px solid var(--Gray-medium, #c4c4c4);
  background: #fff;
}

.select-option p {
  font-size: 18px !important;
  color: #979797;
}

.Polygon {
  width: 21px;
  height: 16px;
}

@media (max-width: 768px) {
  .modal-dialog {
    min-width: 96%;
    min-width: 96%;
  }

  .modal-container {
    padding: 0 24px;
  }
}
</style>