<template>
  <div class="getStarted">
    <div class="col-sm-12 col-lg-6">
      <img src="@/assets/creer_compte.png" alt="garcon qui boit un café" style="width: 90%; height: 90%" />
    </div>
    <div class="padding col-sm-12 col-lg-6 d-flex flex-column">
      <h2>Pour commencer</h2>
      <p>
        Tu peux lancer ta recherche de job et tester la plateforme dès
        maintenant. <br /><br />
        Pour bénéficier d’un matching optimal, et te rendre visible auprès des
        recruteurs, n’hesite pas à créer ton profil.
      </p>
      <div class="buttonGetStarted">
        <ButtonComponent text="Je crée mon profil"  @click="gotoRegister" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/buttons/button/Button.vue";

export default {
  name: "GetStarted",
  components: {
    ButtonComponent,
  },
  methods: {
    gotoRegister() {
      this.$router.push("/register");
    },
  },
};
</script>

<style>
.getStarted {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

h2 {
  font-family: "Outfit700";
}

.buttonGetStarted {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
}

.padding {
  padding: 0 20px 0 20px !important;
}

.padding .firstButton {
  margin-right: 20px;
}

.padding p {
  margin-bottom: 20px !important;
}

@media screen and (max-width: 991px) {
  .getStarted {
    flex-direction: column;
  }

  .buttonGetStarted {
    justify-content: start;
  }
}
</style>
