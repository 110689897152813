<template>
  <div>
    <Banner text="Mes candidatures" :src="require('@/assets/postuler.png')" />
    <Sidebar v-if="isMobile" />
    <div class="my-container">
      <div class="row">
        <div class="col-sm-12 col-lg-3">
          <Sidebar v-if="!isMobile" />
        </div>
        <div class="col-sm-12 col-lg-9">
          <div v-if="candidatureList.length === 0" class="d-flex justify-content-center align-items-center">
            <h3 class="text-center">Vous n'avez pas encore de candidatures</h3>
          </div>
          <div v-else>
            <div class="row mb-3">
              <div class="col-6 col-md-3 mt-sm-3 mt">
                <ToggleButton label="En Cours" id="cours" v-model="toggleStates.cours" @click="toggleButton('cours')"
                  color="#5173D7" />
              </div>
              <div class="col-6 col-md-3 mt-sm-3 mt">
                <ToggleButton label="Accepté" id="accepted" v-model="toggleStates.accepted"
                  @click="toggleButton('accepted')" color="#35C778" />
              </div>
              <div class="col-6 col-md-3 mt-sm-3 mt">
                <ToggleButton label="Refusé" id="refused" v-model="toggleStates.refused"
                  @click="toggleButton('refused')" color="#B8051C" />
              </div>
            </div>

            <div v-for="(candidature, index) in filteredCandidatureList" :key="index">
              <CandidatureTemplate :element="candidature" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <InfoModal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Sidebar from "@/components/layout/profile-sidebar/Sidebar.vue";
import Banner from "@/components/layout/banner/Banner.vue";
import InfoModal from "@/components/modals/info/InfoModal.vue";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import ToggleButton from "@/components/buttons/toggle-button/ToggleButton.vue";
import CandidatureTemplate from "@/components/candidatures/candidature-template/CandidatureTemplate.vue";

export default {
  name: "CandidaturePage",
  components: {
    Banner,
    Sidebar,
    ToggleButton,
    ButtonComponent,
    InfoModal,
    CandidatureTemplate,
  },
  data() {
    return {
      candidatureList: [],
      filteredCandidatureList: [],
      toggleStates: {
        cours: false,
        accepted: false,
        refused: false,
      },
      user: {},
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  mounted() {
    this.fetchCandidatureList();
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth < 992;
    },
    fetchCandidatureList() {
      this.candidatureList = this.getUser.postulation;
      this.filteredCandidatureList = this.candidatureList;
    },
    filterList() {
      // en cours candidatures
      if (this.toggleStates.cours) {
        this.filteredCandidatureList = this.candidatureList.filter((candidature) => candidature.statut === "En Cours");
      }
      // accepted candidatures
      if (this.toggleStates.accepted) {
        this.filteredCandidatureList = this.candidatureList.filter((candidature) => candidature.statut === "Accepté");
      }
      // rejected candidatures
      if (this.toggleStates.refused) {
        this.filteredCandidatureList = this.candidatureList.filter((candidature) => candidature.statut === "Refusé");
      }
      // if no filter is selected, show all candidatures
      if (!this.toggleStates.cours && !this.toggleStates.accepted && !this.toggleStates.refused) {
        this.filteredCandidatureList = this.candidatureList;
      }
    },
    toggleButton(btn) {
      this.toggleStates[btn] = !this.toggleStates[btn];
      this.filterList();
    },
  },
};
</script>

<style scoped>
.my-container {
  padding: 40px 65px;
  min-height: 500px;
}

.view-more {
  border: 1px solid #c4c4c4;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .my-container {
    padding: 40px 25px;
    min-height: 500px;
  }

  .mt {
    margin-top: 20px;
  }

  .mtb {
    margin-top: 50px;
  }
}
</style>
