<template>
  <div class="sectionHeader">
    <div class="flexDivSplit">
      <div class="divLeft">
        <img src="@/assets/logoHeaderConcept.png" alt="Logo ThanksBoss" />
      </div>
      <div class="divRight">
        <h1>Ton job idéal, notre challenge !</h1>
        <p>
          Thanks-boss, spécialisé dans l’IT, te propose une approche centrée sur
          le dialogue, pour faire matcher tes critères personnalisés avec ceux
          des entreprises.
        </p>
        <div class="button">
          <ButtonComponent class="firstButton" style="margin-right: 10px;" text="Je me lance !" @click="gotoRegisterPage" />
          <!-- <a href="https://app.thanks-boss.com/recruiter/" class="text-decoration-none">
            <ButtonComponent class="firstButton" text="Espace recruteurs" />
          </a> -->
        </div>
      </div>
    </div>
    <div class="arrow">
      <button><img src="@/assets/arrow.svg" alt="Fleche" /></button>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/buttons/button/Button.vue";

export default {
  name: "sectionHeader",
  components: {
    ButtonComponent,
  },
  methods: {
    gotoRegisterPage() {
      this.$router.push({
        name: "register",
      });
    },

  },
};
</script>

<style scoped>
.sectionHeader {
  height: 800px;
  background-color: #fff;
  overflow: hidden;
  background-image: url("@/assets/circle.png");
  background-repeat: no-repeat;
  background-position: bottom -200px right -580px;
  background-size: 1200px;
}

.sectionHeader .flexDivSplit {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 70px);
}

.sectionHeader .flexDivSplit .divLeft {
  display: flex;
  justify-content: end;
  width: 50%;
  padding-right: 40px;
}

.sectionHeader .flexDivSplit .divLeft img {
  height: 140px;
}

.sectionHeader .flexDivSplit .divRight {
  padding-left: 140px;
  width: 50%;
}

.sectionHeader .flexDivSplit .divRight h1 {
  font-size: 52px !important;
  font-family: "Outfit700";
  margin-bottom: 40px;
  width: 72%;
}

.sectionHeader .flexDivSplit .divRight p {
  font-family: "Outfit300";
  margin-bottom: 40px !important;
  width: 65%;
}

.sectionHeader .flexDivSplit .divRight .button {
  display: flex;
  align-items: center;
  width: 65%;
}

.sectionHeader .arrow {
  display: none;
  /* justify-content: center;
  align-items: center;
  height: 70px; */
}

.sectionHeader .arrow button {
  background: none;
  border: none;
}

.sectionHeader .arrow button img {
  width: 32.32px;
  height: 53.333px;
}

@media screen and (max-width: 1380px) {
  .sectionHeader .flexDivSplit .divLeft {
    justify-content: center;
    width: 60%;
    padding: 0;
  }

  .sectionHeader .flexDivSplit .divRight {
    padding: 0;
  }
}

@media screen and (max-width: 1110px) {
  .sectionHeader .flexDivSplit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 70px);
  }

  .sectionHeader .flexDivSplit .divLeft {
    display: flex;
    justify-content: start;
    width: 80%;
  }


  .sectionHeader .flexDivSplit .divRight {
    width: 80%;
  }

  .sectionHeader .flexDivSplit .divRight h1 {
    width: 100%;
  }

  .sectionHeader .flexDivSplit .divRight p {
    width: 100%;
  }

  .sectionHeader .flexDivSplit .divRight .button {
    display: flex;
    align-items: center;
    width: 65%;
  }
}

@media screen and (max-width: 618px) {
  .sectionHeader {
    height: 800px;
    background-position: bottom -380px right -380px;
    background-size: 800px;
  }

  .sectionHeader .flexDivSplit .divLeft {
    margin-bottom: 50px;
  }

  .sectionHeader .flexDivSplit .divLeft img {
    height: auto;
    width: 100%;
  }

  .sectionHeader .flexDivSplit .divRight .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  .sectionHeader .flexDivSplit .divRight .button .firstButton {
    margin-bottom: 25px;
  }
}
</style>
