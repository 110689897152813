<!-- EntrepriseDetails.vue -->
<template>
  <div class="entreprise-header">
    <div class="headerItems">
      <div class="imageContainer">
        <div class="entreprise-frame">
          <img v-if="!company.logo_img" alt="Logo de l'entreprise" src="@/assets/logo.png">
          <img v-else alt="Logo de l'entreprise" :src="getLogoImg(company.logo_img)" />
        </div>
      </div>

      <div class="info">
        <div v-if="company.company" class="entreprise-name">
          <h2>{{ company.company }}</h2>

        </div>
        <div class="entreprise-info">
          <div v-if="company.ville" style="display: flex;">
            <img src="@/assets/location.png" alt="Location" />
            <h4>{{ company.ville }}</h4>
            <h4 v-if="company.code_postal" style="margin-inline: 3px;">({{ company.code_postal }})</h4>
          </div>
          
          <div v-if="company.site_url" style="display: flex;">
            <img src="@/assets/site.png" alt="website url" style=" margin-left: 10px;" />
            <h5>{{ company.site_url }}</h5>
          </div>
          <div v-if="company.secteur" style="display: flex;">
            <img src="@/assets/secteur.png" alt="Location" style=" margin-left: 10px;" />
            <h5>{{ company.secteur }}</h5>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="entreprise">
    <div style="margin-bottom: 5%;">
      <h2>A propos</h2>
    </div>

    <div v-if="company.taille" class="info1">
      <p><strong>Taille :</strong> {{ company.taille }}</p>
    </div>

    <div v-if="company.company_details" style="margin-bottom: 5%;">
      <div style="margin-bottom: 5%;">
        <h3>Qui sommes nous ?</h3>
      </div>

      <div style="margin-left: 2%; margin-right: 2%;">
        <p>{{ company.company_details }}</p>
      </div>
    </div>

    <hr class="my-5" />

    <div v-if="company.rejoindre">
      <div style="margin-bottom: 5%;">
        <h3>Pourquoi nous rejoindre ?</h3>
      </div>
      <div style="margin-left: 2%; margin-right: 2%;">
        {{ company.rejoindre }}
      </div>
    </div>

    <hr class="my-5" />

    <div v-if="company.valeurs > 0" style="margin-bottom: 5%;">
      <h3>Valeurs</h3>
      <!-- dummy data -->
      <div class="value-row">
        <p class="job-tag">Social tech</p>
        <span class="star">&#9733;</span>
        <span class="star">&#9733;</span>
        <span class="star">&#9733;</span>
        <span class="star">&#9733;</span>
        <span class="star">&#9734;</span>
        <text class="avis-text">12 avis</text>
      </div>
      <!-- dummy data 2 -->
      <div class="value-row">
        <p class="job-tag">équipe</p>
        <span class="star">&#9733;</span>
        <span class="star">&#9733;</span>
        <span class="star">&#9733;</span>
        <span class="star">&#9733;</span>
        <span class="star">&#9733;</span>
        <text class="avis-text">19 avis</text>
      </div>

    </div>

    <div v-if="company.avantage > 0" style="margin-bottom: 5%;">
      <h3>Avantages</h3>
      <div v-for="(el, index) in company.avantage" :key="index">
        <div class="value-row">
          <p class="job-tag"> {{ el.categorie }}</p>
          <span v-for="i in 5" :key="i">
            <span v-html="i <= el.note ? '&#9733;' : '&#9734;'"
              :class="i <= el.note ? 'star filled' : 'star empty'"></span>
          </span> <text class="avis-text">14 avis</text>
        </div>
      </div>

    </div>

    <!-- <div class="view-more mt-3">
      <div class="d-flex justify-content-center align-items-center">
        <h5>Voir plus</h5>
        <div class="ms-2">
          <img src="@/assets/collapseArrow.png" alt="downArrow" />
        </div>
      </div>
    </div> -->

    <!-- <div style="margin-top: 5%;">
      <h3>Note Globale</h3>
      <div class="value-row">
        <span class="star">&#9733;</span>
        <p>4.6</p>
        <text class="avis-text">26 avis</text>
      </div>
    </div> -->
    <div class="boutons">
      <div class="d-flex justify-content-center align-items-center mt-3">
        <ButtonComponent text="Consulter les avis" data-bs-toggle="modal" data-bs-target="#allFeedbackModal" />
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <ButtonComponent text="soumettre un avis" data-bs-toggle="modal" data-bs-target="#sendFeedbackModal" />
      </div>
    </div>

  </div>

  <div class="dernier-job">
    <h2 class="dernier-job">Dernier Jobs</h2>
  </div>
  <div class="container">
    <div class="job-display row">
      <div v-for="job in jobs" :key="job.id" class="col-sm-12 col-md-6 col-lg-4 gy-4">
        <JobCard :job="job" :favoriteList="this.$store.getters.getUser.favorie_job" />
        <!-- Postuler to add -->
      </div>
    </div>
  </div>
  <SendFeedbackModal :valeurList="company.valeurs" :avantageList="company.avantage" @sendFeedback="sendFeedback" />
  <AllFeedbacksModal @openSendFeedback="openSendFeedback" :feedbackList="feedbackList" :isPageIterable="isPageIterable" @nextPage="nextPage" />
</template>

<script>
import { Modal } from "bootstrap";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import JobCard from "@/components/cards/job-card/card/JobCard.vue";
import getAxiosInstance from "@/services/axios";
import SendFeedbackModal from "@/components/modals/company-page/send-feedback/SendFeedback.vue"
import AllFeedbacksModal from "@/components/modals/company-page/all-feedbacks/AllFeedbacks.vue";

export default {
  name: "EntrepriseDetails",
  components: {
    ButtonComponent,
    JobCard,
    SendFeedbackModal,
    AllFeedbacksModal,
  },
  data() {
    return {
      feedbackList: [],
      id: this.$route.params.companyid,
      company: {},
      jobs: [],
      stars: [],
      offset: 0,
      isPageIterable: true,
    };
  },
  methods: {
    openSendFeedback() {
      const sendFeedbackModal = Modal.getOrCreateInstance("sendFeedbackModal");
      sendFeedbackModal.show();

      sendFeedbackModal._element.addEventListener('shown.bs.modal', () => {
        // Masquer la modal actuelle
        const allFeedbackModal = Modal.getOrCreateInstance("allFeedbackModal");
        allFeedbackModal.hide();
      });
    },
    getNoteSpan(note) {
      for (let i = 0; i < 5; i++) {
        if (i < note) {
          this.stars.push(`<span class="empty-star">&#9733;</span>`);
        } else {
          this.stars.push(`<span class="star">&#9733;</span>`);
        }
      }
    },
    async fetchCompanyData() {
      try {
        const response = await getAxiosInstance().get(`/user/${this.id}`)
        this.company = response.data;
      } catch (error) {
      }
    },
    async getJobs() {
      try {
        const response = await getAxiosInstance().get(`/job_author/${this.id}`)
        this.jobs = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async sendFeedback(feedback) {
      try {
        feedback.cible = this.id;
        await getAxiosInstance().post(`/commentaires/`, feedback);
        this.fetchCompanyData();
      } catch (error) {
        console.log(error);
      }
    },
    async getFeedbacks() {
      try {
        const response = await getAxiosInstance().get(`/commentaires/${this.id}/?limit=6&offset=${this.offset}`);
        this.feedbackList = [...this.feedbackList, ...response.data.results];
        if (response.data.next === null) {
          this.isPageIterable = false;
        }
      } catch (error) {
        console.log(error);
      } 
    },
    nextPage() {
      this.offset += 6;
      this.getFeedbacks();
    },
    getLogoImg(logo) {
      return `https://web.thanks-boss.com/api${logo}`;
    }
  },
  created() {
    this.fetchCompanyData();
    this.getFeedbacks();
    this.getJobs();
  }

};
</script>

<style scoped>

h2 {
  font-size: 24px !important;
  font-weight: bold;
}

h3 {
  font-size: 20px !important;
  font-weight: bold;
}

.imageContainer {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.entreprise-header {
  display: flex;
  width: 100%;
  height: 300px;
  background-color: #FFFFFF;
  margin-bottom: 5%;
  align-items: center;

}

.entreprise-frame {
  border: 2px solid #ccc;
  padding: 10px;
  width: 200px;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entreprise-frame img {
  width: 100%;

}

.entreprise-name {
  display: flex;
  padding-bottom: 10px;
}

.entreprise-name h2 {
  font-weight: bold;
  margin: 0;
}


.entreprise-info {
  font-size: 14px;
  display: flex;
  justify-content: space-around;
}

.entreprise-info h5 {
  margin-left: 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
}


.entreprise-info img {
  height: 25px;
  width: 25px;
}

.entreprise {
  display: block;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
  height: 100%;
  background-color: #FFFFFF;
  width: 80%;
  padding: 5%;
}


.info1 {
  background-color: #E5E5E5;
  padding: 10px;
  margin-bottom: 5%;
}

.info1 p {
  display: inline-block;
}

.separator {
  border: 2px solid #000;
  margin: 5px 0;
  margin-bottom: 5%;
  margin-top: 5%;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 4%;
  justify-content: space-evenly;

}

.headerItems {
  width: 100%;
  display: flex;
}


.value-row {
  margin-top: 3%;
  display: flex;
  align-items: center;
  border-radius: 50%;
}

.view-more-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.viewMore {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 4px 0 4px 0;
  border: #c4c4c4 solid 1px;
  background-color: #fff;
}

.viewMore:hover {
  background-color: #f2f2f2;
  border: #c4c4c4 solid 3px;
}


.star {
  font-size: 30px !important;
  margin-left: 10px;
  color: #FBDE2F;
}


.avis-text {
  margin-left: 10px;
  font-weight: 14px;
  color: #898888;
  font-size: 80%;
}

.boutons {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.dernier-job {
  display: flex;
  justify-content: center;
}

.job-display {
  padding: 0;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.job-card {
  width: 90%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--Gray-medium, #c4c4c4);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.job-header {
  display: flex;
  flex-direction: column;
}

.jobLogo {
  border-radius: 9px;
  background: url("@/assets/jobLogo.png") center/contain no-repeat;
  height: 154px;
  border: 1px solid lightgray;
  width: 100%;
}

.job-detail {
  display: flex;
  align-items: center;
}

.job-entreprise {
  font-size: 100px;
}

.job-contract img {
  width: 21px;
  height: 21px;
}

button {
  background-color: white;
  margin: 0;
  padding: 0;
  border: 0;
}

.job-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}

.job-tag {
  display: flex;
  padding: 4px 10px 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid var(--Primary-jaune, #fbde2f);
  background: #fff;
}

.view-more {
  border: 1px solid #c4c4c4;
  margin-top: 40px;
}

@media (max-width: 1200px) {
  .job-detail {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px !important;
  }
}

.social-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}

.socialIcon {
  display: flex;
  justify-content: center;
  align-items: center !important;
  background-color: black;
  margin-bottom: 5%;
}
</style>
