export const AVANTAGE_FIELDS = [
  {
    "id": 1,
    "categorie": "Sport",
    "nom": "Tennis",
    "note": 0
  },
  {
    "id": 2,
    "categorie": "Santé",
    "nom": "Assurance Santé",
    "note": 0
  },
  {
    "id": 3,
    "categorie": "Formation",
    "nom": "Cours en Ligne",
    "note": 0
  },
  {
    "id": 4,
    "categorie": "Transport",
    "nom": "Abonnement de Transport",
    "note": 0
  },
  {
    "id": 5,
    "categorie": "Télétravail",
    "nom": "Equipement de Bureau",
    "note": 0
  },
  {
    "id": 6,
    "categorie": "Congés",
    "nom": "Congés Payés",
    "note": 0
  },
  {
    "id": 7,
    "categorie": "Restauration",
    "nom": "Tickets Restaurant",
    "note": 0
  },
  {
    "id": 8,
    "categorie": "Loisirs",
    "nom": "Cinéma",
    "note": 0
  },
  {
    "id": 9,
    "categorie": "Assurances",
    "nom": "Assurance Vie",
    "note": 0
  },
  {
    "id": 10,
    "categorie": "Bien-être",
    "nom": "Massage",
    "note": 0
  },
  {
    "id": 11,
    "categorie": "Sport",
    "nom": "Natation",
    "note": 0
  },
  {
    "id": 12,
    "categorie": "Santé",
    "nom": "Couverture Dentaire",
    "note": 0
  },
  {
    "id": 13,
    "categorie": "Formation",
    "nom": "Certifications Professionnelles",
    "note": 0
  },
  {
    "id": 14,
    "categorie": "Transport",
    "nom": "Vélo de Service",
    "note": 0
  },
  {
    "id": 15,
    "categorie": "Télétravail",
    "nom": "Subvention Internet",
    "note": 0
  },
  {
    "id": 16,
    "categorie": "Congés",
    "nom": "Congés Sabbatiques",
    "note": 0
  },
  {
    "id": 17,
    "categorie": "Restauration",
    "nom": "Cafétéria sur Site",
    "note": 0
  },
  {
    "id": 18,
    "categorie": "Loisirs",
    "nom": "Théâtre",
    "note": 0
  },
  {
    "id": 19,
    "categorie": "Assurances",
    "nom": "Assurance Invalidité",
    "note": 0
  },
  {
    "id": 20,
    "categorie": "Bien-être",
    "nom": "Yoga",
    "note": 0
  },
]