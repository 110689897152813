<template>
  <div class="card-container mx-auto mycontainer">
    <div class="card">
      <div>
        <img src="@/assets/Group.png" alt="" class="mt-5 pt-2 login-img" />
        <h5 class="mt-4 title">Ravi de vous revoir !</h5>
        <!-- Utilisation de successDetails et errorDetails déclarées dans data() -->
        <p v-if="errorDetails" class="text-danger">{{ errorDetails }}</p>
        <p v-if="successDetails" class="text-success">{{ successDetails }}</p>
        <div
          @click="signInWithGoogle"
          class="google-container"
          style="cursor: pointer"
        >
          <img src="@/assets/Google.png" alt="Google icon" />
          <div class="google-text">
            <span style="font-weight: 600">Se connecter via Google</span>
          </div>
        </div>
        <p class="mb-3">- ou -</p>
        <form @submit.prevent="submitForm" class="form mt-3">
          <v-form ref="emailForm" @submit.prevent>
            <v-text-field
              label="Ton email"
              class="w-100 text-justify"
              type="email"
              :rules="emailRules"
              v-model="formData.email"
              placeholder="exemple@thanks-boss.com"
            />
          </v-form>
          <v-form ref="passwordForm" @submit.prevent>
            <v-text-field
              :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="show1 = !show1"
              :type="show1 ? 'text' : 'password'"
              label="Mot de passe"
              type="password"
              class="w-100 text-justify"
              v-model="formData.password"
              placeholder="Password!"
              :rules="PasswordRules"
            />
          </v-form>

          <div class="my-5 d-flex container-buttons justify-content-center">
            <v-btn
              :loading="isLoading"
              @click="submitForm()"
              size="large"
              variant="flat"
              class="register-button responsive-buttons"
            >
              Connexion
            </v-btn>
          </div>
          <p
            class="underline mb-2 clickable"
            style="font-size: 16px !important"
            @click="gotoPage('password-reset')"
          >
            Mot de passe oublié ?
          </p>
          <p
            class="d-flex"
            style="font-size: 16px !important; justify-content: center"
          >
            Vous débutez sur Thanks-Boss ?
            <span
              class="underline clickable"
              style="font-size: 16px !important; margin-left: 5px"
              @click="gotoPage('register')">
              S'inscrire
            </span
            >
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { accountService } from "@/services";
import { ref } from "vue";
import { decodeCredential } from "vue3-google-login";
import { googleOneTap } from "vue3-google-login";
import { mapActions } from "vuex";
import { getProfile } from "@/services/applyJob.service";
import { toaster } from "@/utils/toast/toast";

const errorDetails = ref(null);
const user = ref(null);

export default {
  name: "Login",
  data() {
    return {
      show1: false,
 
      formData: {
        email: "",
        password: "",
      },
      successDetails: null,
      errorDetails: null,
      isLoading: false,

      emailRules: [
        (v) => !!v || "Le champ est requis",
        (v) =>
          !/\s/.test(v) || "L'adresse e-mail ne doit pas contenir d'espace",
        (v) =>
          v.length >= 5 ||
          "L'adresse e-mail doit comporter au moins 5 caractères",
        (v) =>
          v.length <= 50 ||
          "L'adresse e-mail ne doit pas dépasser 50 caractères",
        (v) => /^\S+@\S+$/.test(v) || "L'adresse e-mail doit être valide",
      ],
      PasswordRules: [(v) => !!v || "Le champ est requis"],
    };
  },
  created() {
    const token = accountService.getToken();
    if (token) {
      this.$router.push({ path: "/" });
    }
    const fromAccompagnee = this.$route.query.acc;
    if (fromAccompagnee) {
      toaster.showInfoPopup("Connectez-vous pour accéder à cette page");
    }
    const fromRegister = this.$route.query.reg;
    if (fromRegister) {
      toaster.showSuccessPopup(
        "Votre compte a bien été créé, vous pouvez maintenant vous connecter."
      );
    }
  },
  methods: {
    ...mapActions(["handleUserChange", "handleLoggedIn"]),
    getFormData(data) {
      this.formData = { ...this.formData, ...data };
    },

    // form validators
    async validate() {
      // check if form is valid and not the last page
      return (await this.$refs.emailForm.validate()).valid;
    },
    // login with email and password
    async submitForm() {
      try {
        const valid = await this.validate();
        if (!valid) {
          return;
        }
        this.isLoading = true;

        const formData = new FormData();
        formData.append("email", this.formData.email.toLowerCase());
        formData.append("password", this.formData.password);
        // try to login
        const response = await accountService.login(formData);
        // save token in local store
        accountService.saveToken(response.data.access);
        // get user profile, and store in in Vuex store
        const userResponse = await getProfile();
        this.handleUserChange({ type: null, payload: userResponse.data });
        this.handleLoggedIn(true);
        // and go to home page
        this.$router.push({ path: "/" });
      } catch (error) {
        console.log(error);
        return (this.errorDetails = "Informations de connexion incorrectes");
      } finally {
        this.isLoading = false;
      }
    },
    // login with google
    async signInWithGoogle() {
      this.errorDetails = null;
      try {
        const response = await googleOneTap({
          clientId:
            "499109195466-3cgb1ucg8oon8ncdemjpveuqkv7n0i61.apps.googleusercontent.com",
          context: "signin",
        });

        user.value = decodeCredential(response.credential);
        const regData = decodeCredential(response.credential);

        // try to login
        const loginResponse = await accountService.login({
          email: regData.email,
          password: regData.sub,
        });
        // save token in local store
        accountService.saveToken(loginResponse.data.access);
        // get user profile, and store in in Vuex store
        const userResponse = await getProfile();
        this.handleUserChange({ type: null, payload: userResponse.data });
        this.handleLoggedIn(true);
        // and go to home page
        this.$router.push({ path: "/" });
      } catch (error) {
        return (this.errorDetails = "Ce compte n'est pas encore enregistré");
      }
    },
    gotoPage(page) {
      this.$router.push(page);
    },
  },
};
</script>

<style scoped>
.google-container {
  gap: 10px;
  width: 470px;
  display: flex;
  padding: 9px 20px;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  background-color: #fff;
  margin: 24px auto 20px;
}

.google-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  text-align: left;
  line-height: 140.9999966621399%;
}

.register-button {
  width: 50%;
  height: 49px;
  border: 0 solid;
  border-radius: 5px;
  color: #fff;
  background: #000000;
  margin-left: 15px;
  font-weight: 600px;

  padding: 12px, 20px, 12px, 20px;
}

.underline {
  text-decoration: underline;
}

.cancel-button {
  width: 50%;
  height: 49px;
  padding: 12px, 20px, 12px, 20px;
  border-radius: 5px;
  border: 1px;
  background: none;
  border: 1px solid #000000;
  font-weight: 600px;
  margin-right: 15px;
}

.form {
  width: 470px;
  margin-left: auto;
  margin-right: auto;
}

label {
  font-weight: 700;
  height: 49px;
  line-height: 49px;
  text-align: left;
  margin-left: 5px;
}

p {
  font-size: 18px !important;
}

input {
  border: 0;
  margin-right: 10px;
  flex-grow: 1;
  padding-left: 10px;
}

h5 {
  font-size: 38px;
  font-weight: 700;
}

form div {
  border-radius: 5px;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 106px;
}

.card {
  background-color: #f5f5f5;
  width: 719px;
  text-align: center;
  padding: 30px;
  font-size: 18px;
  box-shadow: 0px 0px 14px 4px #0000001f;
  border: 1px solid #c4c4c4;
}

.mycontainer {
  max-width: inherit;
  background-color: #fff;
  background-image: url("@/assets/visuel_TB.png"), url("@/assets/Ellipse_0.png");
  background-repeat: no-repeat;
  background-position: 0px 30px, 100% 700px;
}

.clickable {
  cursor: pointer;
}

.labels {
  margin-left: 25px;
  margin-right: 5px;
}

.labels-mdp {
  min-width: 110px;
}

.labels-email {
  min-width: 55px;
}

@media screen and (max-width: 768px) {
<<<<<<< candidate/src/views/login/Login.vue

=======
>>>>>>> candidate/src/views/login/Login.vue
  .login-img {
    max-width: 248px;
  }

  .card {
    /* width: 100%; */
    height: auto;
    padding: 10px;
  }

  .form {
    width: 100%;
  }

  .google-container {
    width: 100%;
  }

  label {
    font-size: 16px;
  }

  input {
    width: 100%;
    margin-right: 0;
  }

  p {
    width: 100%;
  }

  .login-img {
    width: 100%;
  }

  .mx-4 {
    margin-left: 10px !important;
    margin-right: 10px !important;
    white-space: nowrap;
  }
}

@media screen and (max-width: 530px) {
  .container-buttons {
    flex-direction: column;
  }

  .responsive-buttons {
    margin: 0;
    margin-top: 15px;
    width: 100%;
  }

  .google-container,
  .form,
  .input-field {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }

  input {
    padding-left: 5px;
  }
}
</style>
