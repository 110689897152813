<template>
  <div class="input-box input-box-no-border mt-3">
    <div v-for="(chip, index) in chips" :key="index" class="chip">
      <span class="chip-text">{{ chip }}</span>
      <span @click.stop="removeChip(index)" class="close-icon"> x </span>
    </div>
    <input
      v-model="currentText"
      @keyup.enter="addChip"
      @blur="addChip"
      @paste.stop.prevent="handlePaste"
      class="inner-input"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
export default {
  name: "Chip",
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    index: {
      type: String,
    },
    initialChips: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chips: this.initialChips,
      currentText: "",
    };
  },
  methods: {
    addChip() {
      const newChip = this.currentText.trim().toLowerCase();
      if (newChip) {
        switch (this.index) {
          case "quoi":
            this.$store.commit("autonome/addQuoiChip", newChip);
            break;
          case "ou":
            this.$store.commit("autonome/addOuChip", newChip);
            break;
          case "profession":
            this.$store.commit("autonome/addProfessionChips", newChip);
            break;
          case "skill":
            this.$store.commit("autonome/addSkillChips", newChip);
            break;
          case "avantages":
            this.$store.commit("autonome/addAvantagesChips", newChip);
            break;
          case "valeurs":
            this.$store.commit("autonome/addValeursChips", newChip);
            break;
          default:
            break;
        }
      }

      this.$emit("chip-added", this.currentText.trim());
      this.currentText = "";
    },

    removeChip(index) {
      this.chips.splice(index, 1);

      if (this.index === "quoi") {
        this.$store.commit("autonome/removeQuoiChip", index);
      }
      if (this.index === "ou") {
        this.$store.commit("autonome/removeOuChip", index);
      }
      if (this.index === "profession") {
        this.$store.commit("autonome/removeprofessionChip", index);
      }

      if (this.chips.length === 0) {
        this.$emit("no-chips");
      }
    },
  },
  watch: {
    initialChips(newChips) {
      // Watch for changes in initialChips prop
      this.chips = newChips.slice(); // Update the component's chips property with the new chips
    },
  },
  created() {
    // Update the component's chips property when the component is created
    this.chips = this.initialChips.slice();
  },
};
</script>

<style>
.input-box {
  display: flex;
  align-items: center;
  padding: 5px;
  min-height: 56px;
  /* max-width: 600px; */
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid #A5A6A9;
  background-color: #F6F6F7; /* Added background color */
  padding: 9px 15px 9px 15px;
}

.chip {
  margin-right: 5px;
  margin-top: 5px;
  border: 1px solid #e5e5e5;
  background-color: white; /* Added background color for chip */
  color: white; /* Text color for chip */
  border-radius: 50px; /* Adjusted border-radius */
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.chip-text {
  margin-right: 5px;
  color: black;
  font-size: 16px;
  font-family: Outfit;
  margin-left: 5px;
}

.inner-input {
  border: none;
  outline: none;
  flex-grow: 1;
  width: 100%;
  flex: 1;
  min-width: 60px;
}

.close-icon {
  cursor: pointer;
  background-color: white; /* Updated background color */
  color: black; /* Text color for close icon */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
</style>
