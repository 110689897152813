<template>
  <button class="bouton" type="button" @click="onClick" :disabled="disabled">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    onClick: Function,
    disabled: Boolean,
    text: String,
  },
};
</script>

<style scoped>
.bouton {
  display: flex;
  justify-content: center;
  padding: 8px 25px;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1.5px solid #000;
}

.bouton:hover {
  background-color: #000;
  color: white;
}
</style>
