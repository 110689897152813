<template>
  <!-- SECTION HEADER -->
  <SectionHeader />
  <!-- SECTION SMART SOLUTION -->
  <SectionSmartSolution />
  <!-- SECTION WHY -->
  <SectionWhy />
  <!-- SECTION TELL US ALL -->
  <SectionTellUsAll />
  <!-- SECTION SPONSOR -->
  <SectionSponsor />
  <!-- SECTION GetStarted -->
  <SectionGetStarted />
</template>

<script>
import SectionHeader from "@/components/concept/header/Header.vue";
import SectionSmartSolution from "@/components/concept/smart-solution/SmartSolution.vue";
import SectionWhy from "@/components/concept/why/Why.vue";
import SectionTellUsAll from "@/components/concept/tell-us-all/TellUsAll.vue";
import SectionSponsor from "@/components/ourAdn/sectionSponsor.vue";
import SectionGetStarted from "@/components/concept/get-started/GetStarted.vue";

export default {
  components: {
    SectionHeader,
    SectionSmartSolution,
    SectionWhy,
    SectionTellUsAll,
    SectionSponsor,
    SectionGetStarted,
  },
};
</script>

<style></style>
