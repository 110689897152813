<template>
  <form class="modal fade" id="alertFieldModal" tabindex="-1" aria-hidden="true" data-bs-keyboard="false"
    ref="alertFieldModal" @submit.prevent="handleSubmit">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="modal-container">
            <div class="row">
              <div class="col-sm-12 col-lg-6 d-flex flex-column justify-content-center">
                <h3>Mon alerte</h3>
              </div>
              <div class="col-sm-12 col-lg-6">
                <MatchAndGo @navigate="handleNavigate" />
              </div>
            </div>
          </div>
          <div class="alert mt-5">
            <div class="row justify-content-between w-100">
              <div class="col-sm-12 col-lg-6 mygap">
                <div>
                  <h4 class="ms-3">Nom de l’alerte</h4>
                  <div class="d-flex flex-row flex-wrap align-items-center">
                    <input v-model="alertFieldModal.nom" placeholder="Alerte 1" class="form-control" required />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6 mygap">
                <div>
                  <h4 class="ms-3">E-mail</h4>
                  <div class="d-flex flex-row flex-wrap align-items-center">
                    <input v-model="alertFieldModal.email" placeholder="monadresse@domaine.com" type="email"
                      class="form-control" required />
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-between w-100">
              <div class="col-sm-12 col-lg-6 mygap">
                <div>
                  <h4 class="ms-3">Quoi ?</h4>
                  <div class="d-flex flex-row flex-wrap align-items-center">
                    <Chip :placeholder="quoiPlaceholder" :index="'quoi'" :initialChips="quoiChips"
                      @chip-added="updatePlaceholder('quoi')" @no-chips="resetPlaceholder('quoi')" />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6 mygap">
                <div>
                  <h4 class="ms-3">Où ?</h4>
                  <div class="d-flex flex-row flex-wrap align-items-center">
                    <!-- <Chip v-model="alertFieldModal.ville" :placeholder="ouPlaceholder" :index="'ou'"
                      :initialChips="ouChips" @chip-added="updatePlaceholder('ou')" @no-chips="resetPlaceholder('ou')" /> -->
                    <!-- <input
                      v-model="alertFieldModal.ville"
                      placeholder="Ville"
                      class="form-control"
                      
                    /> -->
                    <div class="city_field">
                      <JustVille @justCityEntry="getJustCity" :initialChips="ouChips" v-model="alertFieldModal.ville" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-container">
            <div class="filter mt-4">
              <div class="row justify-content-between w-100">
                <div class="col-sm-12 col-lg-6 mygap">
                  <div>
                    <h4 class="ms-3">Profession</h4>
                    <select class="form-select" aria-label="Sélectionner" v-model="alertFieldModal.profession">
                      <option hidden>Sélectionner</option>
                      <option v-for="profession in PROFESSION_FIELDS" :key="profession.value" :value="profession.nom">
                        {{ profession.nom }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6 mygap">
                  <div>
                    <h4 class="ms-3">Compétences</h4>
                    <div class="d-flex flex-row flex-wrap align-items-center">
                      <Chip :placeholder="skillPlaceholder" :index="'skill'" :initialChips="skillChips"
                        @chip-added="updatePlaceholder('skill')" @no-chips="resetPlaceholder('skill')" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-between w-100">
                <div class="col-sm-12 col-lg-6 mygap">
                  <h4 class="ms-3">Télétravail</h4>
                  <select class="form-select" aria-label="Sélectionner" v-model="alertFieldModal.teletravail">
                    <option hidden>Sélectionner</option>
                    <option v-for="value in TELETRAVAIL_FIELDS" :key="value.id" :value="value.teletravail">
                      {{ value.teletravail }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-lg-6 mygap">
                  <h4 class="ms-3">Contrat</h4>
                  <select class="form-select" aria-label="Sélectionner" v-model="alertFieldModal.contrat">
                    <option hidden>Sélectionner</option>
                    <option v-for="contract in CONTRACT_FIELDS" :key="contract.value" :value="contract.contrat">
                      {{ contract.contrat }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row justify-content-between w-100">
                <div class="col-sm-12 col-lg-6 mygap">
                  <h4 class="ms-3">Secteur d'activité</h4>
                  <select class="form-select" aria-label="Sélectionner" v-model="alertFieldModal.activite">
                    <option hidden>Sélectionner</option>
                    <option v-for="activity in ACTIVITY_FIELDS" :key="activity.value" :value="activity.nom">
                      {{ activity.nom }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-lg-6 mygap">
                  <h4 class="ms-3">Experience</h4>
                  <select class="form-select" aria-label="Sélectionner" v-model="alertFieldModal.experience">
                    <option hidden>Sélectionner</option>
                    <option v-for="experience in EXPERIENCE_FIELDS" :key="experience.value"
                      :value="experience.experience_job">
                      {{ experience.experience_job }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row justify-content-between w-100">
                <div class="col-sm-12 col-lg-6 mygap">
                  <h4 class="ms-3">Salaire</h4>
                  <select class="form-select" aria-label="Sélectionner" v-model="alertFieldModal.salaire">
                    <option hidden>Sélectionner</option>
                    <option v-for="salary in SALARY_FIELDS" :key="salary.id" :value="salary.salary">
                      {{ salary.salary }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="final-filter mt-5">
            <p>
              Affine ta recherche avec des critères exclusifs.
              <br />Thanks-Boss est capable de traiter des requêtes très
              personnelles !
            </p>
            <div class="row justify-content-between w-100">
              <div class="col-sm-12 col-lg-6 mygap">
                <div>
                  <div class="d-flex align-items-center">
                    <h4 class="ms-3 me-3">Avantages</h4>
                    <!-- <img src="@/assets/Group.svg" alt="lamp" class="lamp" data-bs-toggle="modal"
                      data-bs-target="#avantageDetail" /> -->
                  </div>
                  <div class="d-flex flex-row flex-wrap align-items-center">
                    <Chip :placeholder="avantagesPlaceholder" :index="'avantages'" :initialChips="avantagesChips"
                      @chip-added="updatePlaceholder('avantages')" @no-chips="resetPlaceholder('avantages')" />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6 mygap">
                <div style="margin-top: 5px">
                  <h4 class="ms-3">Valeurs</h4>
                  <div class="d-flex flex-row flex-wrap align-items-center">
                    <Chip :placeholder="valeursPlaceholder" :index="'valeurs'" :initialChips="valeursChips"
                      @chip-added="updatePlaceholder('valeurs')" @no-chips="resetPlaceholder('valeurs')" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center w-70 gap-5 m-4 frequency-alert">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                v-model="frequency_jobs" checked />
              <label class="form-check-label" for="flexRadioDefault1">
                quotidien
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                v-model="frequency_jobs" />
              <label class="form-check-label" for="flexRadioDefault2">
                hebdomadaire
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"
                v-model="frequency_jobs" />
              <label class="form-check-label" for="flexRadioDefault3">
                mensuel
              </label>
            </div>
          </div>

          <div class="d-flex justify-content-center m-4">
            <ButtonComponent text="Je crée mon alerte" type="submit" />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { Modal } from "bootstrap";
import { CONTRACT_FIELDS } from "@/utils/base/contract";
import { PROFESSION_FIELDS } from "@/utils/base/profession";
import { TELETRAVAIL_FIELDS } from "@/utils/base/teletravail";
import { ACTIVITY_FIELDS } from "@/utils/base/activity_sector";
import { EXPERIENCE_FIELDS } from "@/utils/base/experience";
import { SALARY_FIELDS } from "@/utils/base/salary";
import Chip from "@/components/chip/Chip.vue";
import JustVille from "@/components/inputs/city-droplist/JustVille.vue";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import MatchAndGo from "@/components/layout/match-go/MatchAndGo.vue";

export default {
  name: "alertfieldmodal",
  components: {
    Chip,
    MatchAndGo,
    ButtonComponent,
    JustVille,
  },
  data() {
    return {
      alertFieldModal: {},
      quoiPlaceholder: "Ajouter un nouveau",
      ouPlaceholder: "Ajouter un nouveau",
      PROFESSION_FIELDS,
      compétences: "",
      TELETRAVAIL_FIELDS,
      CONTRACT_FIELDS,
      ACTIVITY_FIELDS,
      EXPERIENCE_FIELDS,
      SALARY_FIELDS,
      avantages: "",
      valeurs: "",
      skillPlaceholder: "Qualifications, outils...",
      avantagesPlaceholder: "Conditions de travail,...",
      valeursPlaceholder: "Ce qui vous tient à cœur",
      isNew: true,
      frequency_jobs: "quotidien",
    };
  },
  methods: {
    getJustCity(newJustCity) {
      // Receive entry with both ville and postal_code :
      this.alertFieldModal.ville = newJustCity.ville;
      // this.formData.code_postal = newJustCity.postal_code;
      // this.$store.commit("autonome/addOneOuChip", newJustCity.ville);
    },
    async handleSubmit() {
      //this.alertFieldModal.quoi = this.quoiChips;
      this.alertFieldModal.quoi = this.quoiChips.toString();
      // this.alertFieldModal.skill = this.skillChips;
      this.skillChips.forEach((skill) => {
        this.alertFieldModal.skills = skill;
      });
      this.alertFieldModal.avantages = this.avantagesChips;
      this.alertFieldModal.valeurs = this.valeursChips;
      this.alertFieldModal.frequency_best_jobs = this.frequency_jobs;

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.alertFieldModal.email)) {
        console.error("Format d'email invalide");
        return false;
      }
      this.$emit("new-alert", this.alertFieldModal);
    },
    handleNavigate(routeName) {
      const modalElement = this.$refs.alertFieldModal;
      if (modalElement) {
        const modalInstance = Modal.getInstance(modalElement);
        if (modalInstance) {
          modalInstance.hide();
        }
        modalElement.addEventListener(
          "hidden.bs.modal",
          () => {
            this.$router.push({ name: routeName });
            document.body.classList.remove("modal-open");
            document.body.style.overflow = "";
            document.body.style.paddingRight = "";
            // Remove backdrops
            const backdrops = document.querySelectorAll(".modal-backdrop");
            backdrops.forEach((backdrop) => backdrop.remove());
          },
          { once: true }
        );
      }
    },

    updatePlaceholder(val) {
      switch (val) {
        case "quoi":
          this.quoiPlaceholder = "Ajouter";
          break;
        case "ou":
          this.ouPlaceholder = "Ajouter";
          break;
        case "profession":
          this.professionPlaceholder = "Ajouter";
          break;
        case "skill":
          this.skillPlaceholder = "Ajouter";
          break;
        case "avantages":
          this.avantagesPlaceholder = "Ajouter";
          break;
        case "valeurs":
          this.valeursPlaceholder = "Ajouter";
          break;
        default:
          break;
      }
      this.currentText = "";
    },
    resetPlaceholder(val) {
      switch (val) {
        case "quoi":
          this.quoiPlaceholder = "Ajouter un nouveau";
          break;
        case "ou":
          this.ouPlaceholder = "Ajouter un nouveau";
          break;
        case "profession":
          this.professionPlaceholder = "Métier, fonction recherchée";
          break;
        case "skill":
          this.skillPlaceholder = "Qualifications, outils...";
          break;
        case "avantages":
          this.avantagesPlaceholder = "Conditions de travail,...";
          break;
        case "valeurs":
          this.valeursPlaceholder = "Ce qui vous tient à cœur";
          break;
        default:
          break;
      }
    },
  },
  computed: {
    quoiChips() {
      return this.$store.state.autonome.quoiChips;
    },
    ouChips() {
      return this.$store.state.autonome.ouChips;
    },
    professionChips() {
      return this.$store.state.autonome.professionChips;
    },
    skillChips() {
      return this.$store.state.autonome.skillChips;
    },
    avantagesChips() {
      return this.$store.state.autonome.avantagesChips;
    },
    valeursChips() {
      return this.$store.state.autonome.valeursChips;
    },
  },
};
</script>

<style scoped>
.modal-dialog {
  min-width: 80%;
  height: 90%;
}

.modal-content {
  width: 100%;
  height: 100%;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-body {
  padding: 0 !important;
  overflow-y: auto;
}

.modal-container {
  padding: 0 63.5px;
}

.alert {
  padding: 25px 65.5px 32px 64.5px;
}

.mygap {
  margin-top: 20px;
}

.form-control {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 15px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--gray-medium, #c4c4c4);
  background: #fff;
  margin-top: 1rem;
}

.form-select {
  padding: 12px 15px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid var(--gray-medium, #c4c4c4);
  margin-top: 1rem;
}

.final-filter {
  padding: 25px 65px;
  gap: 15px;
  background: var(--Primary-jaune, #fbde2f);
}

.form-check-input[type="radio"] {
  border-radius: 50%;
  border-color: black;
}

.form-check-input:checked {
  background-color: black;
}

.lamp {
  background-color: white;
  border-color: white;
  border-radius: 20px;
}

.city_field {
  margin-top: 12px;
  border-radius: 5px;
  max-height: 52px;
  width: 100%;
}

@media (max-width: 768px) {
  .modal-dialog {
    width: 96%;
    min-width: 96%;
  }

  .modal-container {
    padding: 0 24px;
  }

  .final-filter {
    padding: 25px 45px;
  }

  .frequency-alert {
    flex-direction: column;
    gap: 0.5rem !important;
  }

  .alert {
    padding: 10px;
  }
}
</style>
