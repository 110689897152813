<template>
  <div class="goToAccompagneePage">
    <div>
      <img src="@/assets/match.png" alt="match" class="logo" />
    </div>
    <div style="margin-left: 10px">
      <div class="d-flex">
        <h6>
          Mieux que de renseigner des filtres,
          <span>
            dis-moi ce que tu cherches, valide une sélection de mots clés et
            profite des offres sur mesure.
          </span>
        </h6>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <button-component text="Match & go !" @click="goToAccompagneePage" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/buttons/button/Button.vue";

export default {
  name: "MatchAndGo",
  components: {
    ButtonComponent,
  },
  data() {
    return {};
  },
  methods: {
    goToAccompagneePage() {
      this.$emit("navigate", "accompagnee");
    },
  },
};
</script>
<style scoped>
.logo {
  margin: -10px 0 0 -120px;
}
.goToAccompagneePage {
  display: flex;
  margin-top: 20px;
  margin-left: 61px;
  padding: 25px 15px 25px 55px;
  justify-content: center;
  border-radius: 20px;
  border: 2px solid var(--Primary-jaune, #fbde2f);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
  position: relative;
}

h6 {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  padding-right: 15px;
}

span {
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 21px !important;
}

@media screen and (max-width: 460px) {
  h6 {
    padding-right: 2px;
    margin-left: -10px;
  }
}
</style>
