<template>
  <div class="modal fade" id="allFeedbackModal" tabindex="-1" aria-hidden="true" data-bs-keyboard="false"
    ref="allFeedbackModal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header" style="width: 100%;">
          <div style="width: 100%; display:flex; justify-content: flex-end">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <h3 class="modal-title">Avis</h3>
        <div class="modal-body">
          <div v-for="(feedback, index) in feedbackList" :key="index">
            <FeedbackTemplate :feedback="feedback" />
          </div>
          <div style="width: 100%; display: flex; justify-content: center;">
            <button v-if="isPageIterable" class="viewMore mb-5 see-more" @click="nextPage">
              <div class="d-flex justify-content-center align-items-center">
                <h5>Voir plus</h5>
                <div class="ms-2">
                  <img src="@/assets/collapseArrow.png" alt="downArrow" />
                </div>
              </div>
            </button>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/buttons/button/Button.vue";
import FeedbackTemplate from "@/components/company/feedback-template/FeedbackTemplate.vue";

export default {
  name: "AllFeedbacks",
  components: {
    ButtonComponent,
    FeedbackTemplate
  },
  props: {
    feedbackList: {
      type: Object,
    },
    isPageIterable: {
      type: Boolean,
    },
  },
  methods: {
    nextPage() {
      this.$emit("nextPage");
    },
  },
};
</script>

<style scoped>
h4 {
  font-size: 24px !important;
}

input {
  border: 1px solid #c4c4c4 !important;
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 12px 20px;
}

.modal-header {
  border-bottom: 0px;
  padding: 20px 20px 0px 20px;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  padding-left: 20px;
}

.modal-body {
  padding-top: 10px;
  padding-inline: 20px;
}

.row-title {
  margin-bottom: 15px !important;
}

.star {
  cursor: pointer;
  font-size: 30px !important;
  margin-left: 10px;
  color: #FBDE2F;
}
.rating-row {
  padding-block: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-footer {
  justify-content: center;
}
</style>
