<template>
  <div class="profile-child-container">
    <div class="language-container">
      <div class="language-container-header flex-row d-flex">
        <h1>Langues</h1>
        <img src="@/assets/uparrow.png" @click="toggleIsActive" :class="{ 'rotate-180': isActive }" />
      </div>
      <div v-show="isActive">
        <button @click="addNewLanguage" :disabled="isAdded">
          Ajouter +
        </button>
        <!-- call LanguageTemplate for every Language -->
        <div v-for="(language, index) in languageForm" :key="index">
          <LanguageTemplate
            :language="language"
            :isNew="true"
            @language-submit="handleSubmit"
            @delete="handleDelete"
          />
        </div>
        <div v-for="(language, index) in languageList" :key="index">
          <LanguageTemplate
            :language="language"
            @language-submit="handleSubmit"
            @delete="handleDelete"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LanguageTemplate from '../template/LanguageTemplate.vue';
export default {
  name: 'Language',
  components: {
    LanguageTemplate
  },
  props: {
    languageList: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      isActive: false,
      isAdded: false,
      languageForm: []
    }
  },
  methods: {
    toggleIsActive() {
      this.isActive = !this.isActive;
    },
    handleSubmit(languageList) {
      this.$emit('language-form-submit', languageList);
      this.isAdded = false;
      this.languageForm.shift();
    },
    handleDelete(id) {
      if(id === '') {
        this.languageForm.shift();
        this.isAdded = false;
        return;
      }
      this.$emit('language-form-delete', id);
    },
    // create instance of language template when add button is clicked
    addNewLanguage() {
      if(this.languageList.length >= 10 || this.isAdded) return;
      this.isAdded = true;
      this.languageForm.push({
        id: '',
        langue: '',
        niveau: '',
      });
    },
  },
}

</script>

<style scoped>
h1 {
  font-size: 30px !important;
  padding: 0 !important;
  font-weight: 500;
}

select {
  height: 45.881px;
  width: 85%;
  padding: 12px 20px;
  margin-block-start: 11px;
  border-radius: 5px;
  border: none;
}

img {
  width: 45px;
  height: 44px;
  cursor: pointer;
}

button {
  padding: 8px 25px;
  border-radius: 50px;
  border: 1.5px solid #000;
  width: 120px;
  margin: 11px 0px 32px; 
}

span, p {
  color: #000;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 141% !important;
  margin-left: 20px;
}

span {
  display: flex;
  align-items: center;
}

.profile-child-container {
  padding: 14px 34px 14px 34px;
}

.language-container {
  width: 100%;
}

.language-container-header {
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
</style>