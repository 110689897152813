<template>
  <div class="sectionTeam">
    <div class="svgTeam">
      <img src="@/assets/capMan.svg" alt="developerIcon" />
      <img src="@/assets/woman.svg" alt="developerIcon" />
      <img class="move" src="@/assets/developer.svg" alt="developerIcon" />
    </div>

    <div>
      <h3 class="mb-5">Une équipe agile et impliquée</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tempor,
        libero eu venenatis molestie, sapien arcu fringilla purus, a
        sollicitudin risus ex ac nisl. Duis in dapibus mauris, sed sagittis
        justo. Ut vel viverra augue. Morbi bibendum tellus erat, vel imperdiet
        sem facilisis eu. Vivamus sagittis sapien at erat fermentum, in volutpat
        nisi varius.
      </p>
    </div>

    <!-- <div class="bubbleStat">
      <div class="bubble">
        <h3>12 452</h3>
        <span>Jobs</span>
      </div>
      <div class="bubble">
        <h3>9450</h3>
        <span>Profils</span>
      </div>
      <div class="bubble">
        <h3>4500</h3>
        <span>Visiteurs par mois</span>
      </div>
    </div> -->
  </div>
</template>

<script></script>

<style scoped>
.sectionTeam {
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  padding: 55px 0 55px 0;
  margin-bottom: 40px;
  gap: 40px;
}
.sectionTeam .svgTeam {
  width: 58%;
  margin-bottom: 40px;
  border-bottom: 1.2px solid black;
}

.sectionTeam .svgTeam .move {
  position: relative;
  left: -79px;
}

.sectionTeam .agileTeam {
  padding-bottom: 50px;
  margin-bottom: 100px;
  border-bottom: 1px solid #c4c4c4;
}
.sectionTeam .agileTeam h3 {
  padding-bottom: 40px;
}

.bubbleStat {
  display: flex;
  justify-content: space-around;
}

.bubbleStat .bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 250px;
  height: 125px;
  border-radius: 100px;
  border: 1px solid black;
}

.bubbleStat .bubble:first-child {
  background-color: #fbde2f;
}
.bubbleStat .bubble:nth-child(2) {
  background-color: #e5e5e5;
}

@media (max-width: 990px) {
  .bubbleStat {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 455px;
  }

  .sectionTeam .svgTeam .move {
    left: -64px;
  }

  .sectionTeam .svgTeam img:first-child {
    height: 90px;
    width: 90px;
  }
  .sectionTeam .svgTeam img:nth-child(2) {
    height: 130px;
    width: 130px;
  }
  .sectionTeam .svgTeam img:nth-child(3) {
    height: 90px;
    width: 90px;
  }
}
@media (max-width: 720px) {
  .sectionTeam {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .sectionTeam .svgTeam {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    left: -67px;
  }
  .sectionTeam .svgTeam .move {
    left: -48px;
  }
  .sectionTeam .svgTeam img:first-child {
    height: 90px;
    width: 90px;
  }
  .sectionTeam .svgTeam img:nth-child(2) {
    height: 120px;
    width: 120px;
  }
  .sectionTeam .svgTeam img:nth-child(3) {
    height: 90px;
    width: 90px;
  }
}
</style>
