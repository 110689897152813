export default {
  namespaced: true,
  state: {
    msgResponse: null,
    skill: [],
    secteur: [],
    avantages: [],
    valeurs: [],
    teletravail: [],
    type_contrat: [],
    titre: [],
    localisation: [],
    showAddInput: {
      skill: false,
      secteur: false,
      avantages: false,
      valeurs: false,
    },
    jobs: [],
    updateCreteria: false,
  },
  mutations: {
    updateMsgResponse(state, payload) {
      state.msgResponse = payload;
    },
    updateCriteriaMessage(state, payload) {
      state.updateCreteria = !state.updateCreteria;
    },
    updateCriteria(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (Array.isArray(state[key])) {
          const filteredPayload = payload[key].filter(
            (item) => item.trim() !== ""
          );
          state[key] = [...state[key], ...filteredPayload];
        }
      });
    },

    removeItem(state, { category, item }) {
      const index = state[category].indexOf(item);
      if (index !== -1) {
        state[category].splice(index, 1);
      }
    },

    toggleInputVisibility(state, { category, visibility }) {
      state.showAddInput[category] = visibility;
    },

    addItem(state, { category, newItem }) {
      if (!state[category].includes(newItem)) {
        state[category].push(newItem);
      }
    },

    emptyCriterias(state) {
      state.skill = [];
      state.secteur = [];
      state.avantages = [];
      state.valeurs = [];
    },

    gettingJobs(state, payload) {
      state.jobs = payload;
    },
  },
  actions: {
    handleMsgResponse({ commit }, payload) {
      commit("updateMsgResponse", payload);
    },

    handleUpdateCriteria({ commit }, payload) {
      commit("updateCriteria", payload);
    },

    addItem({ commit }, payload) {
      commit("addItem", payload);
    },

    removeItem({ commit }, payload) {
      commit("removeItem", payload);
    },

    handleResetCriteria({ commit }) {
      commit("emptyCriterias");
    },

    handleGettingJobs({ commit }, payload) {
      commit("gettingJobs", payload);
    },
  },
  getters: {
    getMsgResponse: (state) => state.msgResponse,
    isUpdateCreteria: state => state.updateCreteria,
  },
};
