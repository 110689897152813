<template>
  <div>
    <Banner :text="bannerText" :src="require('@/assets/recherche1.png')" />
    <div class="my-container row">
      <div class="col-sm-12 col-lg-3">
        <div>
          <h3 class="trouver-title">Trouver un job</h3>
          <nav v-if="isMobile">
            <li class="nav-item">
              <a class="nav-link active" :class="{ 'active-link': currentPath === '/autonome' }" aria-current="page"
                @click="gotoPage('autonome')">
                Recherche autonome
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" :class="{ 'active-link': currentPath === '/accompagnee' }" aria-current="page"
                @click="gotoPage('accompagnee')">
                Recherche accompagnée
              </a>
            </li>
          </nav>
        </div>
      </div>
      <div class="col-sm-12 col-lg-9">
        <div class="row justify-content-between">
          <div class="col-sm-12 col-lg-8">
            <div class="row">
              <div class="col-sm-12 col-lg-6">
                <div>
                  <h4 style="margin-left: 19.5px">Quoi ?</h4>
                  <Chip :placeholder="quoiPlaceholder" :index="'quoi'" :initialChips="quoiChips"
                    @chip-added="updatePlaceholder('quoi')" @no-chips="resetPlaceholder('quoi')" />
                </div>
              </div>
              <div class="col-sm-12 col-lg-6">
                <div>
                  <h4 style="margin-left: 19.5px" class="where">Où ?</h4>
                  <div class="city_field">
                    <JustVille @justCityEntry="getJustCity" :initialChips="ouChips" class="inner-input" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 d-flex align-items-end buttons">
            <div>
              <button class="search" @click="fillForm">
                <img src="@/assets/Search.png" alt="search" style="width: 34px; height: 34px" />
              </button>
            </div>
            <div class="filter-button">
              <button class="bouton" @click="showFilter">
                <div v-if="!filterVisibility">Voir les filtres</div>
                <div v-else>X</div>
                <span style="color: #fbde2f; margin-left: 6px" v-if="!filterVisibility">{{ filterCount }}</span>
              </button>
            </div>
          </div>
          <div :class="!filterVisibility ? 'd-none' : 'd-block'" style="margin-top: 50px;">
            <FilterField @get-filter-data="getFilterData" />
          </div>
        </div>
        <div class="row alerte">
          <div class="col-sm-12 col-md-7">
            <p>
              Pour recevoir, par mail, des nouvelles offres correspondantes à
              tes critères de recherche >>>
            </p>
          </div>
          <div class="col-sm-12 col-md-1"></div>
          <div class="col-sm-12 d-flex justify-content-end col-md-4 alerte-button">
            <button-component text="Créer une alerte" @click="goToAlertPage" />
          </div>
        </div>
        <div v-if="jobsLoading" style="
          margin-top: 4.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
        ">
          <span>Chargement des jobs en cours...</span>
        </div>
      </div>
      <div v-if="jobsDisplay" style="
          margin-top: 4.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
        ">
        <JobsList :jobListTwo="displayedJobs" :screenSize="'md'" />
      </div>
      <div v-if="jobsDisplay && jobList.length > displayedJobs.length" style="text-align: center; margin-top: 20px">
        <button class="viewMore" @click="loadMoreJobs">Voir plus</button>
      </div>
      <div v-if="jobIsNull" style="
          margin-top: 4.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
        ">
        <span>Aucun job trouvées</span>
      </div>
    </div>
    <AvantageDetail @close-avantage-detail="handleCloseAvantageDetail" />
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import Banner from "@/components/layout/banner/Banner.vue";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import Chip from "@/components/chip/Chip.vue";
import JobsList from "@/components/home/job-list/JobsList.vue";
import FilterField from "@/components/modals/autonome/filter/Filter.vue";
import AvantageDetail from "@/components/modals/avantage/AvantageDetail.vue";
import axios from "@/services/axios";
import JustVille from "@/components/inputs/city-droplist/JustVille.vue";
import { toaster } from "@/utils/toast/toast";
import getAxiosInstance from "@/services/axios";

export default {
  name: "autonome",
  components: {
    Banner,
    ButtonComponent,
    Chip,
    JobsList,
    FilterField,
    AvantageDetail,
    JustVille,
  },
  data() {
    return {
      quoiPlaceholder: "Mots clés",
      ouPlaceholder: "Ville ou code postal",
      currentText: "",
      jobsDisplay: false,
      jobList: [],
      displayedJobs: [],
      jobsPerPage: 6,
      isMobile: false,
      jobsLoading: false,
      filterVisibility: false,
      jobIsNull: false,
      
    };
  },
  mounted() {
    this.updatePlaceholdersOnMount();
    this.displayBar();
    window.addEventListener("resize", this.displayBar);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.displayBar);
  },
  computed: {
    bannerText() {
      return this.jobsDisplay ? "Résultats" : "Jobs";
    },
    currentPath() {
      return window.location.pathname;
    },
    quoiChips() {
      return this.$store.state.autonome.quoiChips;
    },
    ouChips() {
      return this.$store.state.autonome.ouChips;
    },
    filterCount() {
      return (
        this.$store.state.autonome.quoiChips.length +
        this.$store.state.autonome.ouChips.length +
        this.$store.state.autonome.professionChips.length +
        this.$store.state.autonome.skillChips.length
      );
    },
  },

  methods: {
    showFilter() {
      this.filterVisibility = !this.filterVisibility;
    },
    displayBar() {
      return (this.isMobile = window.innerWidth <= 991 ? false : true);
    },
    gotoPage(page) {
      this.$router.push(page);
    },
    getJustCity(newJustCity) {
      this.$store.commit("autonome/addOneOuChip", newJustCity.ville);
    },
    async getFilterByIA(data) {
      this.jobsLoading = true;
      // get data and remove salaire_min and salaire_max for critere IA
      const filter = { ...data };
      delete filter.salaire_min;
      delete filter.salaire_max;
      // filter data from filters, remove empty values, and put them in an array
      let filterData = [];
      if (data !== undefined) {
        filterData = Object.values(filter).filter((valeur) => {
          // Vérifier si la valeur est une chaîne non vide ou un tableau non vide
          return (
            (typeof valeur === "string" && valeur.trim() !== "") ||
            (Array.isArray(valeur) && valeur.length > 0) ||
            (typeof valeur === "number") ||
            (typeof valeur === "boolean")
          );
        });
      }
      // get quoiChips and ouChips from store and combine them with filterData
      const quoiChips = this.$store.state.autonome.quoiChips;
      const ouChips = this.$store.state.autonome.ouChips;
      const listeCombinee = quoiChips.concat(ouChips, filterData);

      // create array with phrase as key
      const formData = new FormData();
      formData.append("phrase", listeCombinee);

      // get criteria from IA
      try {
        const critereResponse = await axios().post("/api/critere/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const responseSkill = critereResponse.data.skill.filter((x) => x !== "");
        // make form data for job search
        const formData2 = new FormData();
        formData2.append("avantages", critereResponse.data.avantage);
        formData2.append("industrie", critereResponse.data.industrie);
        formData2.append("localisation", critereResponse.data.localisation);
        formData2.append("experience", critereResponse.data.experience);
        formData2.append("skill", responseSkill);
        formData2.append("teletravail", critereResponse.data.teletravail);
        formData2.append("title", critereResponse.data.title);
        formData2.append("type_contrat", critereResponse.data.type_contrat);
        formData2.append("valeurs", critereResponse.data.valeur);
        if (data !== undefined) {
          formData2.append("salaire_min", data.salaire_min);
          formData2.append("salaire_max", data.salaire_max);
        }
        const listForm = this.formDataToObject(formData2);
        // get jobs from IA by critere
        let list = "";
        for (let key in listForm) {
          if (listForm[key].length > 0 && listForm[key] !== "" && listForm[key] !== "undefined") {
            list += `${key}=${listForm[key]}&`;
          }
        }
        list = list.slice(0, -1);
        // list = list.replace(/,/g, "");

        ///const jobs = await getAxiosInstance("multipart/form-data").post(`/best_job_django/?${list}`);
        let jobs;
        if (quoiChips[0] == undefined && ouChips[0] == undefined) {
          jobs = await getAxiosInstance("multipart/form-data").get(`/jobs/`);
        } else if (ouChips[0] == undefined) {
          jobs = await getAxiosInstance("multipart/form-data").get(`/jobs/?title=${quoiChips[0]}`);
        } else if (quoiChips[0] == undefined) {
          jobs = await getAxiosInstance("multipart/form-data").get(`/jobs/?local=${ouChips[0]}`);
        } else {
          jobs = await getAxiosInstance("multipart/form-data").get(`/jobs/?title=${quoiChips[0]}&local=${ouChips[0]}`);
        }

        this.jobList = jobs.data['results'];

        // la route /best_job_django/?title= renvoit une liste de job, la route /best_job_django/ renvoit une liste contenant une liste de job
        if (this.jobList.length == 1) this.jobList = this.jobList[0]; // route /best_job_django/
        if (this.jobList.length>6) this.displayedJobs = this.jobList.slice(0, this.jobsPerPage); // /best_job_django/?title
        else this.displayedJobs = this.jobList;

        this.jobsDisplay = this.jobList.length > 0;
        this.jobsLoading = false;
        if(this.jobList.length === 0) return this.jobIsNull = true;
      } catch (error) {
        this.jobsLoading = false;
        toaster.showErrorPopup("Erreur lors de la récupération des jobs");
        console.log("Erreur lors de la récupération des jobs:", error);
      }
    },
    getFilterData(data) {
      // get filter data by emit, and send it to filterIA function
      this.getFilterByIA(data);
      this.jobsDisplay = true;
      this.displayedJobs = this.jobList.slice(0, this.jobsPerPage);
    },
    updatePlaceholder(val) {
      switch (val) {
        case "quoi":
          this.quoiPlaceholder = "Ajouter";
          break;
        case "ou":
          this.ouPlaceholder = "Ajouter";
          break;
        default:
          break;
      }
      this.currentText = "";
    },
    formDataToObject(formData) {
      const object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });
      return object;
    },
    updatePlaceholdersOnMount() {
      if (this.quoiChips.length > 0) {
        this.quoiPlaceholder = "Ajouter";
      }
      if (this.ouChips.length > 0) {
        this.ouPlaceholder = "Ajouter";
      }
    },
    fillForm() {
      this.jobsDisplay = true;
      this.getFilterData();
      this.displayedJobs = this.jobList.slice(0, this.jobsPerPage);
    },
    loadMoreJobs() {
      const nextJobs = this.jobList.slice(
        this.displayedJobs.length,
        this.displayedJobs.length + this.jobsPerPage
      );
      this.displayedJobs = [...this.displayedJobs, ...nextJobs];
    },
    resetPlaceholder(val) {
      switch (val) {
        case "quoi":
          this.quoiPlaceholder = "Mots clés";
          break;
        case "ou":
          this.ouPlaceholder = "Ville ou code postal";
          break;
        default:
          break;
      }
    },
    handleCloseAvantageDetail() {
      const filterModalElement = document.getElementById("filterField");
      const filterModal = new Modal(document.getElementById("filterField"));
      filterModal.show();

      filterModalElement.addEventListener(
        "shown.bs.modal",
        () => {
          document.body.style.overflow = "hidden";
        },
        { once: true }
      );
    },
    goToAlertPage() {
      this.$router.push({ name: "alert" });
    },
  },
};
</script>

<style scoped>
.my-container {
  padding: 40px 60px;
  min-height: 500px;
}

.autonome {
  padding: 0 10px;
}

li {
  list-style-type: none;
  cursor: pointer;
}

li a {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 141% !important;
  margin: 15px 0 15px 0 !important;
}

.nav-item:hover {
  text-decoration: underline;
  text-decoration-color: #fbde2f;
}

.viewMore {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 4px 0 4px 0;
  border: #c4c4c4 solid 1px;
  background-color: #fff;
}

.active-link {
  text-decoration: underline;
  text-decoration-color: #fbde2f;
}

.form-control {
  margin-top: 15px;
  padding: 12px 15px;
  border-radius: 5px;
  border: 1px solid lightgray !important;
  background: white;
  min-height: 45px;
  margin-left: 5px;
  overflow-y: hidden;
  resize: none;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #000;
  padding: 7px 8px 8px 8px;
  width: 50px;
  height: 49px;
  border: none;
}

.filter-button {
  margin: 0 0 3px 15px;
}

.bouton {
  display: flex;
  justify-content: center;
  padding: 8px 15px;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #000;
  min-width: 160px;
}

.bouton:hover {
  background-color: #000;
  color: white;
}

.alerte {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  margin-top: 59px;
  padding: 20px 0;
}

.city_field {
  margin-top: 12px;
  border-radius: 5px;
  max-height: 52px;
}

.row {
  margin-inline: 0;
}

@media (max-width: 992px) {
  .title {
    display: none;
  }

  .trouver-title {
    margin-bottom: 20px !important;
  }

  .search {
    margin-top: 20px;
  }

  .buttons {
    justify-content: start !important;
    margin-top: 10px;
  }

  .row {
    --bs-gutter-x: 0;
  }

  .where {
    margin-top: 25px;
  }
}

@media (max-width: 768px) {
  .my-container {
    padding: 40px 20px;
  }

  .title {
    display: none;
  }

  li a {
    font-size: 21px !important;
    font-weight: 300 !important;
  }

  .alerte-button {
    margin-top: 25px;
    justify-content: start !important;
  }
}
</style>
