<template>

    <!-- Create the editor container -->
    <div v-bind:id=quillID></div>

</template>

<script>
import Quill from 'quill';
import "quill/dist/quill.core.css";
import 'quill/dist/quill.bubble.css';

export default {
    name: "QuillRenderContainer",

    props: {
        // ID du conteneur quill dans lequel sera append l'éditeur
        quillID: {
            type: String,
            required: true,
        },

        // les datas du conteneur
        quillDatas: {
            type: String,
            required: true,
        },
    },

    data() {
        return{
            quillInstance: null,                //  bind l'instance
        }
    },
    
    mounted() {
        this.quillSetup();
    },

    methods: {
        // setup l'editeur de texte
        quillSetup() {
            const quill = new Quill(`#${this.quillID}`, {
                theme: 'bubble',
                placeholder: 'Votre texte ici.',
            });
            const delta = JSON.parse(this.quillDatas);
            quill.setContents(delta);
            this.quillInstance = quill;
        },
    }
}
</script>

<style scoped>
</style>