<template>
  <div class="style">
    <div class="bordered">
      <div class="logo">
        <img src="@/assets/match.png" alt="match" />
      </div>
      <h6>Je suis ton MatchCoach.</h6>
      <p>
        Mon point fort ? Assurer un matching optimal entre ton profil, tes
        souhaits et les offres d’emploi !
      </p>
    </div>
    <div>
      <div>
        <h3 class="trouver-title">Trouver un job</h3>
        <nav v-if="isMobile">
          <li class="nav-item">
            <a class="nav-link active" :class="{ 'active-link': currentPath === '/autonome' }" aria-current="page"
              @click="gotoPage('autonome')">
              Recherche autonome
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" :class="{ 'active-link': currentPath === '/accompagnee' }" aria-current="page"
              @click="gotoPage('accompagnee')">
              Recherche accompagnee
            </a>
          </li>
        </nav>
      </div>
      <div class="bordered-2">
        <div class="title-container">
          <h3 class="title">Chats</h3>
          <ButtonComponent :onClick="createConversation" :disabled="isCreating || isLoading" :text="buttonText"
            style="min-width: 100px" class="createBtn" />
        </div>
        <div class="chats-container">
          <div v-if="isLoading">Chargement des conversations...</div>
          <div v-if="conversations && conversations.length > 0" v-for="(chat, index) in visibleChats"
            :key="chat.conversation_id" @click="changeSelectedChat(chat)" class="chat-title">
            <h4 :hidden="!chatChange || isLoading || isCreating" :class="
              { 'mb-2': index < conversations.length - 1,
                'bordered-p': true,
                'pb-1': true,
                underline: selectedChatId === chat.conversation_id,
                clickable: true
              }">
              {{ chat.titre }}
            </h4>
          </div>
          <div v-else>
            <h4>Aucune conversation créees</h4>
          </div>
        </div>
        <div v-if="chat && chats.length > 3" @click="toggleDisplay" class="arrow-icon">
          <div :class="isExpanded ? 'rotated' : ''">
            <img src="@/assets/collapseArrow.png" alt="downside" style="width: 25px;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/buttons/button/Button.vue";

export default {
  name: "JobSideBar",
  props: {
    conversations: {
      type: Array,
      default: () => [],
    },
    createConversation: {
      type: Function,
      required: true,
    },
    chatChange: Boolean,
    isCreating: Boolean,
  },
  components: {
    ButtonComponent,
  },
  data() {
    return {
      isExpanded: false,
      isMobile: false
    };
  },
  mounted() {
    this.displayBar();
    window.addEventListener('resize', this.displayBar);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.displayBar);
  },
  methods: {
    changeSelectedChat(conversation) {
      this.$emit("selectedConversation", conversation);
    },
    displayBar() {
      return this.isMobile = window.innerWidth <= 991 ? false : true;
    },
    toggleDisplay() {
      this.isExpanded = !this.isExpanded;
    },
    gotoPage(page) {
      this.$router.push(page);
    },
  },
  computed: {
    visibleChats() {
      if (!this.conversations) {
        return [];
      }
      if (this.isExpanded) {
        return this.conversations;
      }
      return this.conversations;
    },
    buttonText() {
      if (this.isCreating) {
        return "Crée";
      } else {
        return "Nouveau";
      }
    },
    currentPath() {
      return window.location.pathname;
    },
  },
};
</script>

<style scoped>
@media (min-width: 501px) and (max-width:991px) {
  .style {
    margin-top: 40px;
  }

  .bordered-2 {
    margin-top: 10px;
  }
}

.bordered {
  padding: 20px;
  margin-bottom: 40px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px 0px 10px 10px;
  border: 2px solid var(--primary-jaune, #fbde2f);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

@media (max-width: 500px) {
  .style {
    display: flex;
    flex-direction: column;
  }

  .bordered {
    padding: 20px;
    margin-top: 100px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 10px 0px 10px 10px;
    border: 2px solid var(--primary-jaune, #fbde2f);
    background: #fff;
    box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
  }

  .trouver-title {
    display: none;
  }
}

.logo {
  margin: -100px 0 0 -11px;
}

li {
  list-style-type: none;
  cursor: pointer;
}

li a {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 141% !important;
  margin: 15px 0 15px 0 !important;
}

.nav-item:hover {
  text-decoration: underline;
  text-decoration-color: #fbde2f;
}

.active-link {
  text-decoration: underline;
  text-decoration-color: #fbde2f;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 15px;
}

.bordered-2 {
  margin-top: 40px;
  padding: 15px 20px 10px 20px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--gray-medium, #c4c4c4);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.bordered-p {
  transition: box-shadow 0.3s;
}

.underline {
  text-decoration: underline;
  text-decoration-color: #fbde2f;
}

.clickable {
  cursor: pointer;
}

.chat-title:hover h4 {
  text-decoration: underline;
  text-decoration-color: #fbde2f;
}

.arrow-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.chats-container {
  overflow-y: auto;
  max-height: 180px;
}

.rotated {
  transform: rotate(180deg);
}

@media (min-width: 1061px) {
  .logo {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1060px) {
  .logo {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .title-container {
    display: block;
  }

  .title {
    text-align: center;
  }

  .createBtn {
    margin: auto;
    margin-top: 20px;
  }
}
</style>
