<template>
  <footer>
    <div class="row gap-40">
      <div class="col-md-12 col-lg-6 foot1">
        <div class="col-md-6 col-sm-12">
          <div style="padding-bottom: 29px">
            <img src="@/assets/footer-logo.png" class="logo-img" alt="Logo" />
          </div>
          <p>Plateforme de recrutement intelligente propulsée par Targech.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="row gap-40">
          <div class="col-md-6 col-sm-12">
            <h4>ESSENTIEL</h4>
            <p><a class="anim_link" href="https://www.thanks-boss.com/">Concept</a></p>
            <p class="anim_link" @click="gotoPage('/')">Jobs</p>
            <p class="anim_link" @click="gotoPage('/notreAdn')">Notre ADN</p>
            <p class="anim_link" @click="gotoPage('/login')">Se connecter</p>
          </div>
          <div class="col-md-6 col-sm-12 pratique">
            <h4>PRATIQUE</h4>
            <div style="margin-bottom: 20px">
              <!--<a href="mailto:fdam@targech.com" class="anim_link" style="text-decoration: none;">-->
              <a @click="gotoPage('/contact')" class="anim_link" style="text-decoration: none;">
                Contact
              </a>
            </div>
            <div>
              <ul class="link-group">
                <li>
                  <a class="anim_link" href="https://www.linkedin.com/company/thanks-boss/" target="_blank"><img
                      src="@/assets/LinkedIn.png" alt="linkedin" /></a>
                </li>
                <li>
                  <a class="anim_link" href="https://www.facebook.com/people/Thanks-Boss/100063984647627/"
                    target="_blank"><img src="@/assets/Facebook.png" alt="facebook" /></a>
                </li>
                <li>
                  <a class="anim_link" href="https://www.tiktok.com/@thanks.boss/" target="_blank"><img
                      src="@/assets/Tiktok.png" alt="tiktok" /></a>
                </li>
                <li>
                  <a class="anim_link" href="https://discord.gg/H9NNtcm4mS" target="_blank"><img style="height: 23px"
                      src="@/assets/discord.png" alt="discord" /></a>
                </li>
              </ul>
            </div>
            <!-- <a class="bouton" href="https://app.thanks-boss.com/recruiter/">Espace recruteurs</a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="finish row">
      <div class="col-md-3 col-sm-6 col-xs-6">
        <p>@Targech 2023</p>
      </div>
      <!-- <div class="col-md-3 col-sm-6 col-xs-6">
        <p>Mentions légales</p>
      </div> -->
      <div class="col-sm-6 col-md-3">
        <p><a class="anim_link" href="https://www.thanks-boss.com/politique-confidentialite" target="_blank">Politique
            de
            confidentialité</a></p>
      </div>
      <div class="col-sm-6 col-md-3">
        <p><a class="anim_link" href="https://www.thanks-boss.com/cgu" target="_blank">CGU</a></p>
      </div>
      <p type="button" data-cc="show-preferencesModal">Gérer ses cookies</p>
    </div>
  </footer>
</template>

<script setup>
import router from "@/router/index.js"

const gotoPage = (url) => {
  router.push({ path: url });
}
</script>

<style scoped>
footer {
  background-color: #000000;
  color: white;
  padding: 41px 61px;
  width: 100%;
}

h4 {
  padding-bottom: 38px;
}

p {
  padding-bottom: 10px;
  width: auto;
}

.anim_link {
  text-decoration: none;
  color: white;
}

.anim_link:hover {
  cursor: pointer;
}

.link-group {
  display: flex;
  align-items: flex-start;
  gap: 13.33px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.link-group ul li:hover {
  cursor: pointer;
}

.bouton {
  max-width: 75%;
  display: flex;
  justify-content: center;
  padding: 8px 25px;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1.5px solid white;
  color: white;
  background-color: #000000;
  margin-top: 10.22px;
  text-decoration: none;
}

.bouton:hover {
  border: 1.5px solid black;
  color: black;
  background-color: white;
}

.finish {
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 41px;
}

@media (max-width: 768px) {
  footer {
    padding: 25px 40px;
  }
  .logo-img {
    width: 100%;
  }

  .gap-40 {
    gap: 40px;
    display: flex;
    justify-content: center;
  }

  h4 {
    padding-bottom: 23px;
  }

  .finish>div {
    padding: 0;
    width: 50%;
  }
}
</style>
