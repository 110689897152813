<template>
  <div class="wrapperJobOffers large_screen" v-if="screenSize === 'lg'">
    <div v-for="job in jobListTwo" :key="job.id" class="jobCards">
      <JobCard
        :job="job"
        :favoriteList="user.favorie_job"
        @update-favoris="fetchUser"
      />
    </div>
  </div>
  <div class="wrapperJobOffers medium_screen" v-if="screenSize === 'md'">
    <div v-for="job in jobListTwo" :key="job.id" class="jobCards">
      <JobCard
        :job="job"
        :favoriteList="user.favorie_job"
        @update-favoris="fetchUser"
      />
    </div>
  </div>
  <div class="wrapperJobOffers small_screen" v-if="screenSize === 'sm'">
    <div v-for="job in jobListTwo" :key="job.id" class="jobCards">
      <JobCard
        :job="job"
        :favoriteList="user.favorie_job"
        @update-favoris="fetchUser"
      />
    </div>
  </div>
  <InfoModal />
</template>

<script>
import JobCard from "@/components/cards/job-card/card/JobCard.vue";
import InfoModal from "@/components/modals/info/InfoModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "JobsList",
  components: {
    InfoModal,
    JobCard,
  },
  props: {
    jobListTwo: {
      type: Object,
      required: true,
    },
    screenSize: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedJob: null,
      user: "",
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    fetchUser() {
      this.user = this.getUser;
    },
  },
  mounted() {
    this.fetchUser();
  },
};
</script>

<style scoped>
.wrapperJobOffers {
  display: grid;
  grid-template-columns: repeat(3, 423px);
  grid-gap: 25px;
  max-width: 1440px;
  overflow: hidden;
  margin-bottom: 50px;
}
.jobCards {
  margin: auto;
  max-width: 95vw;
  padding-left: 10px;
  padding-right: 10px;
}

/* breakpoints on the homepage */
@media screen and (max-width: 1360px) {
  .large_screen {
    grid-template-columns: repeat(2, 423px);
  }
}
@media screen and (max-width: 910px) {
  .large_screen {
    grid-template-columns: repeat(1, 423px);
  }
}

/* breakpoints on the autonome page */
@media screen and (max-width: 1470px) {
  .medium_screen {
    grid-template-columns: repeat(2, 423px);
  }
}
@media screen and (max-width: 1010px) {
  .medium_screen {
    grid-template-columns: repeat(1, 423px);
  }
}

/* breakpoint on the ADN page */
@media screen and (max-width: 1760px) {
  .small_screen {
    grid-template-columns: repeat(2, 423px);
  }
}
@media screen and (max-width: 1320px) {
  .small_screen {
    grid-template-columns: repeat(1, 423px);
  }
}

/* START keep cards with shadows */

@media screen and (max-width: 470px) {
  .wrapperJobOffers {
    grid-template-columns: repeat(1, 95vw);
    margin: auto;
  }

  .jobCard {
    margin-top: 20px;
    padding-left: 8px;
    padding-right: 10px;
    padding-bottom: 60px;
    margin: auto;
    max-width: 87vw;
  }

  .wrapperJobOffers {
    padding: 0;
    left: 0;
    margin: auto;
  }
  .jobCards {
    margin: 5px;
  }

  .medium_screen {
    margin-left: -10px;
  }
  .small_screen {
    margin-left: -15px;
  }
}
/* END keep cards with shadows */

/* START Remove card shadows and turn it into space bars */

/* @media screen and (max-width: 470px) {
  .wrapperJobOffers {
    grid-template-columns: repeat(1, 95vw);
    margin: auto;
  }
  .jobCard {
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #c4c4c4;
    margin-top: 20px;
    padding-left: 8px;
    padding-right: 10px;
    padding-bottom: 60px;
    margin: auto;
    max-width: 87vw;
  }

  .wrapperJobOffers {
    padding: 0;
    left: 0;
    margin: auto;
  }
  .jobCards {
    margin: 5px;
  }

  .medium_screen {
    margin-left: -10px;
  }
  .small_screen {
    margin-left: -15px;
  }
} */

/* END Remove card shadows and turn it into space bars */
</style>
