<template>
  <div class="sectionOurDna">
    <div class="wrapperTitle">
      <h4>NOTRE ADN</h4>
      <h2>Un défi et des valeurs fortes au fondement de notre histoire</h2>
    </div>
  </div>
</template>

<script></script>

<style scoped>
.sectionOurDna {
  height: 506px; 
  background-color: #fbde2f;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url("@/assets/circle.png");
  background-repeat: no-repeat;
  background-position: bottom -90px right -600px;
  background-size: 1200px;
}

.wrapperTitle {
  width: 50%;
  margin: 200px 0 0 50px;
}

.wrapperTitle h4 {
  font-family: "Outfit100";
  font-size: 62px;
  font-weight: 100;
  line-height: 141%;
}

.wrapperTitle h2 {
  font-family: "Outfit700";
  font-size: 62px;
  font-weight: 700;
  line-height: 78px;
}

.circle {
  height: 100%;
  width: 50%;
}

@media screen and (max-width: 990px) {
}

@media screen and (max-width: 720px) {
  .sectionOurDna {
    height: 684px;
    background-position: bottom 80px right -600px;
  }
  .wrapperTitle {
    width: 90%;
    margin: 318px 25px 0 25px;
  }
  .wrapperTitle h2 {
    font-family: "Outfit700";
    font-size: 40px !important;
    font-weight: 700 !important;
    line-height: 58px !important;
  }
}
</style>
