<template>
<div class="sectionSponsor">
  <h2>Ils nous soutiennent et nous font confiance</h2>
  <div class="ourSponsor">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(sponsor, index) in sponsors" :key="index">
        <div class="sponsor">
          <div class="placeolder">
            <a target="_blank" rel="noopener noreferrer" :href="sponsor.link">
              <img :src="sponsor.logo" :alt="sponsor.alt" class="sponsor-img" />
            </a>
          </div>
          <p>{{ sponsor.description }}</p>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</div>
</template>

<script>

export default {
  data() {
    return {
      sponsors: [
        { logo: require('@/assets/cci-toulouse.png'), alt: 'Logo cci Toulouse Haute-Garonne', link: 'https://www.toulouse.cci.fr/produit/5-jours-pour-entreprendre-identifier-les-points-cles-dune-creation-dentreprise-reussie?gad_source=1&gclid=CjwKCAjw8diwBhAbEiwA7i_sJeej6huKvtctUkayohlfuOi-yR8QboZjs4wjAGHjcHH_rNkcS01ILRoCDMIQAvD_BwE', description: 'Accompagnement stratégique' },
        { logo: require('@/assets/blackHive.png'), alt: 'Logo Black hive', link: 'https://blackhive.fr/?utm_source=thanks-boss&utm_medium=landing&utm_campaign=applicant&utm_id=tb', description: 'Partenaire marketing' },
        { logo: require('@/assets/eigsi-violet-alumni.png'), alt: 'Eigsi Violet Alumni', link: 'https://www.linkedin.com/company/eva-eigsi-violet-alumni-le-r%C3%A9seau-des-ing%C3%A9nieurs-violet-eemi-eigsi/?utm_source=thanks-boss&utm_medium=landing&utm_campaign=applicant&utm_id=tb', description: 'Sponsor' },
        { logo: require('@/assets/insightNest.png'), alt: 'Insight Nest', link: 'https://www.insightnest.fr/?utm_source=thanks-boss&utm_medium=landing&utm_campaign=applicant&utm_id=tb', description: 'Partenaire technique' },
        { logo: require('@/assets/reso-incubateur.png'), alt: 'Réso Incubateur', link: 'https://www.agence-adocc.com/-evenements-/la-startup-se-met-au-vert/', description: 'Nous sommes Lauréat 2023' },
        { logo: require('@/assets/BEAUVOIR.webp'), alt: 'Beauvoir', link: 'https://www.beauvoir.co/?utm_source=thanks-boss&utm_medium=nao&utm_campaign=lp-tb&utm_id=partenaire', description: 'École du numérique et de l\'IA' },
        { logo: require('@/assets/airoom.png'), alt: 'AI Room Styles', link: 'https://www.airoomstyles.com/?utm_source=thanks-boss&utm_medium=landing&utm_campaign=applicant&utm_id=tb', description: 'AI Room Styles' },
      ]
    };
  }
};
</script>

<style scoped>
.sectionSponsor {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 60px;
  background-color: #fbde2f;
}

.sectionSponsor h2 {
  text-align: center;
  margin-bottom: 38px;
}

.ourSponsor {
  display: flex;
  border-radius: 20px;
  background-color: #fff;
  height: auto;
  width: 700px;
  padding: 26px 26px 26px 26px;
}

.sponsor {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: -webkit-fill-available;
}

.sponsor p {
  padding-top: 20px;
  text-align: center;
  font-size: 18px !important;
  font-weight: 500;
}

.placeolder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 95px;
}

.placeolder img {
  max-height: 100px;
}

@media (max-width: 1080px) {
  .sectionSponsor {
    height: auto;
  }

  .sectionSponsor .ourSponsor {
    flex-direction: column;
    justify-content: space-between;
    height: 775px;
    padding: 26px 70px 26px 70px;
  }

  .sectionSponsor .ourSponsor .sponsor {
    justify-content: center;
  }
}
</style>
