<template>
  <div class="mx-auto mycontainer" v-if="user">
    <Banner text="Jobs" :src="require('@/assets/recherche1.png')" />
    <div
      :class="{ 'main-container': !this.$store.state.autonome.isExpandedChat, 'main-expanded-container': this.$store.state.autonome.isExpandedChat, 'row': true, 'justify-content-between': true, 'm-0': true }">
      <div v-if="!this.$store.state.autonome.isExpandedChat" class="col-sm-12 col-lg-4">
        <JobSideBar v-if="selectedConversation" :conversations="user.conversation"
          :createConversation="createConversation" :chatChange="chatIsVisible" :isCreating="isCreating"
          @selectedConversation="currentConversation" />
      </div>
      <div
        :class="{ 'col-sm-12': true, 'col-lg-8': !this.$store.state.autonome.isExpandedChat, 'col-lg-12': this.$store.state.autonome.isExpandedChat, 'autour': true }">
        <!-- <component :is="displayedComponent" /> -->
        <ChatContainer :conversation="selectedConversation" :createConversation="createConversation"
          :isCreating="isCreating" @chatChangeTrue="chatChangeTrue" @chatChangeFalse="chatChangeFalse" />
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/layout/banner/Banner.vue";
import JobSideBar from "@/components/layout/job-sidebar/JobSideBar.vue";
import ChatContainerMobile from "@/components/accompagnee/chat-container/ChatContainerMobile.vue";
import ChatContainer from "@/components/accompagnee/chat-container/ChatContainer.vue";
import { accountService } from "@/services/account.service";
import { createNewConversation, getWholeConversation } from '@/services/accompagnee.service';
import { mapGetters, mapActions } from "vuex";

export default {
  name: "accompagnee",
  components: {
    Banner,
    JobSideBar,
    ChatContainerMobile,
    ChatContainer,
  },
  data() {
    return {
      user: null,
      windowWidth: window.innerWidth,
      selectedConversation: null,
      chatIsVisible: true,
      isCreating: false,
    };
  },
  beforeCreate() {
 

    // // Update window width when the component is mounted
    // this.updateWindowWidth();
    // // Add a window resize listener to update window width dynamically
    // window.addEventListener('resize', this.updateWindowWidth);
  },
  mounted() {
   const token = accountService.getToken();

    if (!token) {
      // redirect to login page
      return this.$router.push({ path: 'login', query: { acc: true } });
    }
    // get user from store
    this.user = this.getUser;
    // get last conversation by sorting  date modification
    if (this.user && this.user.conversation && this.user.conversation.length > 0) {
      this.selectedConversation = this.user.conversation[0];
    } else {
      try {
        this.createConversation();
      } catch (error) {
        console.log(error);
      }
    }
  },
  computed: {
    ...mapGetters(['getUser']),
    displayedComponent() {
      // Choose which component to display based on the window width
      return this.windowWidth <= 500 ? 'ChatContainerMobile' : 'ChatContainer';
    }
  },
  methods: {
    ...mapActions(['handleAddConversation']),
    chatChangeTrue() {
      this.chatIsVisible = true;
    },
    chatChangeFalse() {
      this.chatIsVisible = false;
    },
    currentConversation(conversation) {
      return this.selectedConversation = conversation;
    },
    async createConversation() {
      this.isCreating = true;
      try {
        const response = await createNewConversation()
        const conversation = await getWholeConversation(response.data.conversation_id);
        this.handleAddConversation(conversation.data);
        this.selectedConversation = conversation.data;
        this.isCreating = false;
      } catch (error) {
        console.log(error);
      }
    },
    updateWindowWidth() {
      // Update the window width property
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style>
.main-container {
  padding: 83px 172px 110px 172px;
}

.main-expanded-container {
  padding: 40px 72px 40px 72px;
}

@media (max-width: 768px) {
  .main-container {
    padding: 0 !important;
  }
}

@media (max-width: 1146px) {
  .main-container {
    padding: 20px 20px;
  }
}
</style>
