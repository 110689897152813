<template>
  <div class="body">
    <div class="errorImgDiv"></div>
    <h2 class="text-center">
      Oups ! La page que vous recherchez n’existe pas.
    </h2>
    <p class="text-center clickable" @click="gotoHomepage">
      Pour retourner à la page d’accueil, c’est par ici.
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    gotoHomepage() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped>
.body {
  margin-top: 137px;
  margin-bottom: 127px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.errorImgDiv {
  width: 100%;
  max-width: 444px;
  height: 304px;
  background-image: url("@/assets/error.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
