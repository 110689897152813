<template>
  <div class="card-container ">
    <div class="card">
      <!-- <img src="@/assets/Group.png" alt="" class="mt-5 pt-2 login-img" /> -->
      <h5 style="margin-bottom: 20px !important;">Mot de passe oublié ?</h5>
      <p v-if="errorDetails" class="text-danger">{{ errorDetails }}</p>
      <p v-if="successDetails" class="text-success">{{ successDetails }}</p>
      <form @submit.prevent="submitForm" class="form mt-3">
        <div class="mb-3 bg-white d-flex">
          <label for="email" class="labels labels-email">Email :</label>
          <input type="email" id="email" class="input-field" v-model="formData.email" required
            placeholder="exemple@thanks-boss.com">
        </div>
        <div class="my-3 d-flex container-buttons" style="justify-content: center;">
          <button v-if="!isLoading" type="submit" class="register-button responsive-buttons" style="margin-top: 12px;"
            @click="sendEmail">Réinitialiser</button>
          <button v-else type="submit" class="register-button responsive-buttons" style="margin-top: 12px;" disabled>
            <i class="fa fa-spinner fa-spin"></i> Envoi en cours...
          </button>
        </div>
        <p class="d-flex" style="font-size: 16px !important; justify-content: center;">Vous vous souvenez de votre mot
          de passe ?
        <div class="underline clickable" style="font-size: 16px !important; margin-left: 5px" @click="gotoLoginpage">
          Connectez-vous
        </div>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PasswordReset',
  data() {
    return {
      formData: {
        email: '',
      },
      successDetails: null,
      errorDetails: null,
      isSent: false,
      isLoading: false,
    };
  },
  created() {

  },
  methods: {
    gotoLoginpage() {
      this.$router.push('/login');
    },
    async sendEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.formData.email)) {
        return false;
      }
      // reset details
      this.errorDetails = null;
      // trim email and convert to lowercase
      this.formData.email = this.formData.email.toLocaleLowerCase().trim();
      try {
        this.isLoading = true;
        await axios.post('https://web.thanks-boss.com/api/user/password_reset_request/', { email: this.formData.email });
        this.isSent = true;
        this.successDetails = `Un email de réinitialisation de mot de passe vous a été envoyé à ${this.formData.email}.`;
        this.errorDetails = null;
        this.formData.email = '';
        this.isLoading = false;
      } catch (error) {
        this.errorDetails = error.response.data.error;
        this.isLoading = false;
        console.log(error);
      }
    },
  }
};
</script>

<style scoped>
.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 106px;
}

.card {
  background-color: #F5F5F5;
  width: 719px;
  text-align: center;
  font-size: 18px;
  padding: 30px;
  box-shadow: 0px 0px 14px 4px #0000001F;
  border-radius: 20px;
  border: 1px solid #C4C4C4;
}

.register-button {
  width: 50%;
  height: 49px;
  border: 0 solid;
  border-radius: 5px;
  color: #fff;
  background: #000000;
  margin-left: 15px;
  font-weight: 600px;
  padding: 12px, 20px, 12px, 20px;
}

.underline {
  text-decoration: underline;
}

.cancel-button {
  width: 50%;
  height: 49px;
  padding: 12px, 20px, 12px, 20px;
  border-radius: 5px;
  border: 1px;
  background: none;
  border: 1px solid #000000;
  font-weight: 600px;
  margin-right: 15px;
}

.form {
  width: 470px;
  margin-left: auto;
  margin-right: auto;
}

label {
  font-weight: 700;
  height: 49px;
  line-height: 49px;
  text-align: left;
  margin-left: 5px;
}

p {
  font-size: 18px !important;
}

input {
  border: 0;
  margin-right: 10px;
  flex-grow: 1;
  padding-left: 10px;
}

h5 {
  font-size: 38px;
  font-weight: 700;
}

form div {
  border-radius: 5px;
}


.mycontainer {
  max-width: inherit;
  background-color: #fff;
  background-image: url("@/assets/visuel_TB.png"), url("@/assets/Ellipse_0.png");
  background-repeat: no-repeat;
  background-position: 0px 30px, 100% 700px;
}

.clickable {
  cursor: pointer;
}

.labels {
  margin-left: 25px;
  margin-right: 5px;
}

.labels-mdp {
  min-width: 110px;
}

.labels-email {
  min-width: 55px;
}

@media screen and (max-width: 768px) {

  .login-img {
    max-width: 248px;
  }

  .card {
    /* width: 100%; */
    height: auto;
    padding: 10px;
  }

  .form {
    width: 100%;
  }

  .google-container {
    width: 100%;
  }

  label {
    font-size: 16px;
  }

  input {
    width: 100%;
    margin-right: 0;
  }

  p {
    width: 100%;
  }

  .login-img {
    width: 100%;
  }

  .mx-4 {
    margin-left: 10px !important;
    margin-right: 10px !important;
    white-space: nowrap;
  }
}

@media screen and (max-width: 530px) {
  .container-buttons {
    flex-direction: column;
  }

  .responsive-buttons {
    margin: 0;
    margin-top: 15px;
    width: 100%;
  }

  .google-container,
  .form,
  .input-field {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }

  input {
    padding-left: 5px;
  }
}
</style>