<template>
  <form class="profile-child-container">
    <div class="main-informations-container">
      <h1>Mes informations</h1>
      <div class="profile-picture">
        <img v-if="informationList.photo || previewImage" :src="previewImage || getFullImageUrl()"
          @click="openFileInput" />
        <p v-else @click="openFileInput">Importer ma photo</p>
        <input ref="fileInput" type="file" style="display: none" @change="handlePhotoChange" accept="image/*" />
      </div>
      <div class="profile-grid">
        <div>
          <input type="text" id="firstname" name="firstname" placeholder="Prénom" v-model="informationList.first_name"
            required />
          <input type="text" id="name" name="name" placeholder="Nom" v-model="informationList.last_name" required />
          <input type="text" id="address" name="address" placeholder="Adresse" v-model="informationList.adress"
            required />
        </div>
        <div>
          <input type="text" id="phone" name="phone" placeholder="Téléphone" v-model="informationList.numberPhone"
            required />
          <!-- <input type="text" id="city" name="city" placeholder="Ville" v-model="informationList.ville" required /> -->
          <div class="city_field">
            <Ville @cityEntry="getCity" :sendingCity="informationList.ville" :sendingCode="informationList.code_postal" />
          </div>
          <!-- <input type="text" id="code_postal" name="code_postal" placeholder="Code postal"
            v-model="informationList.code_postal" required /> -->
        </div>
      </div>
      <div></div>
    </div>
    <div class="internet-informations-container profile-line-topbar">
      <h1>Présence sur internet</h1>
      <div class="profile-grid">
        <div>
          <label for="website">Site internet</label>
          <input type="text" id="website" name="website" placeholder="https://www.mon-site.com"
            v-model="informationList.site_url" />

          <label for="linkedin">Compte LinkedIn</label>
          <input type="text" id="linkedin" name="linkedin" placeholder="https://www.linkedin.com/in/mon-nom"
            v-model="informationList.linkedin" />
        </div>
        <div>
          <label for="portfolio">Portfolio</label>
          <input type="text" id="portfolio" name="portfolio" placeholder="https://www.mon-portfolio.com"
            v-model="informationList.porfolio_url" />

          <label for="other">Autre</label>
          <input type="text" id="other" name="other" placeholder="https://www.other.com"
            v-model="informationList.autre_url" />
        </div>
      </div>
    </div>
    <div class="myprofile-informations-container profile-line-topbar">
      <h1>Mon profil</h1>
      <div class="profile-grid">
        <div>
          <label for="profile_title">Titre du profil</label>
          <input type="text" id="profile_title" name="profile_title" placeholder="Titre du profil"
            v-model="informationList.titre" />

          <label for="experience">Expérience</label>
          <select id="experience" name="experience" v-model="informationList.experiences">
            <option value="" disabled>Niveau</option>
            <option v-for="(type, index) in EXPERIENCE_FIELDS" :key="index" :value="type.experience_job">
              {{ type.experience_job }}
            </option>
          </select>
        </div>
        <div>
          <label for="contract">Contrat souhaité</label>
          <select id="contract" name="contract" v-model="informationList.contrat">
            <option value="" disabled>Type de contrat</option>
            <option v-for="(type, index) in CONTRACT_FIELDS" :key="index" :value="type.contrat">
              {{ type.contrat }}
            </option>
          </select>

          <label for="salary">Prétention salariale</label>
          <select id="salary" name="salary" v-model="informationList.desired_salary">
            <option value="" disabled>Montant</option>
            <option v-for="(type, index) in SALARY_FIELDS" :key="index" :value="type.salary">
              {{ type.salary }}
            </option>
          </select>
        </div>
      </div>
      <div>
        <label for="about">A propos de moi</label>
        <textarea type="text" id="about" name="about" placeholder="Description" v-model="informationList.about" />
      </div>
      <div class="button-container">
        <div style="width: 100%;">
          <p data-bs-toggle="modal" data-bs-target="#confirmationModal" style="cursor: pointer;">Supprimer le compte</p>
        </div>
        <div>
          <button class="profile-save-button" @click="handleSubmit">
            Sauvegarder
          </button>
        </div>

      </div>
    </div>
    <PopupMc ref="popUpMc" />
    <ConfirmationModal :title="title" @confirm="deleteAccount" />
  </form>
</template>

<script>
import PopupMc from "../popups/mc/Mc.vue";
import ConfirmationModal from "@/components/modals/confirmation/Confirmation.vue";
import { EXPERIENCE_FIELDS } from "@/utils/base/experience";
import { CONTRACT_FIELDS } from "@/utils/base/contract";
import { SALARY_FIELDS } from "@/utils/base/salary";
import Ville from "@/components/inputs/city-droplist/Ville.vue";

export default {
  name: "Informations",
  components: {
    PopupMc,
    ConfirmationModal,
    Ville,
  },
  props: {
    informationList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previewImage: "",
      EXPERIENCE_FIELDS,
      CONTRACT_FIELDS,
      SALARY_FIELDS,
      title: "Etes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible.",
    };
  },
  methods: {
    getCity(city) {
      this.informationList.ville = city.ville;
      this.informationList.code_postal = city.postal_code;
    },
    deleteAccount() {
      this.$emit("delete-account");
    },
    getJustCity(newJustCity) {
      // Receive entry with both ville and postal_code :
      this.informationList.ville = newJustCity.ville;
      this.informationList.code_postal = newJustCity.postal_code;
      // this.$store.commit("autonome/addOneOuChip", newJustCity.ville);
    },
    togglePopup() {
      this.$refs.popUpMc.isPopupVisible = !this.$refs.popUpMc.isPopupVisible;
    },
    // handle information form submit
    handleSubmit(event) {
      event.preventDefault();

      if (this.fileUploaded) {
        this.$emit("information-form-submit", this.informationList);
      } else {
        const formDataWithoutFile = { ...this.informationList };
        delete formDataWithoutFile.photo;
        delete formDataWithoutFile.cv;
        this.$emit("information-form-submit", formDataWithoutFile);
      }
    },

    // handle user photo changes
    handlePhotoChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.informationList.photo = file;
        this.fileUploaded = true;

        const reader = new FileReader();
        reader.onload = () => {
          this.previewImage = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    getFullImageUrl() {
      if (this.informationList.photo) {
        if (this.informationList.photo.name) {
          if (typeof (this.informationList.photo.name) === 'string') {
            return URL.createObjectURL(this.informationList.photo);
          }
        }
        const baseUrl = "https://web.thanks-boss.com/api";
        return baseUrl + this.informationList.photo;
      } else if (this.previewImage) {
        return this.previewImage;
      } else {
        return "";
      }
    },
    closePopup() {
      this.submissionStatus = null;
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 30px !important;
  padding: 0 !important;
}

input,
select,
textarea {
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 12px 20px;
}

textarea {
  min-height: 125px;
  resize: none;
}

label {
  margin: 25px 0px 7.8px 20px;
  font-weight: 600;
  line-height: 141%;
}

.main-informations-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    margin-bottom: 32px;
  }

  input {
    margin-top: 25px;
  }
}

.profile-picture {
  padding: 0px 1.975px 0px 20px;

  img,
  p {
    border-radius: 155px;
    width: 153.025px;
    height: 155px;
    object-fit: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9d9d9;
  }
}

.informations-skills {
  margin-top: 20px;
}

.mc-popup-informations {
  margin-top: 15px;
}

.internet-informations-container {
  width: 100%;
  margin-top: 50px;
  padding-top: 35px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 50px;
}

.mc-popup-informations {
  position: absolute;
  transform: translate(-10%, -45%);
}

.margin-info {
  margin-top: 20px;
}

.city_field {
  margin-top: 25px;
  border-radius: 5px;
  max-height: 52px;
}
</style>
