<template>
  <div class="jobCard">
    <div style="width: 100%; display: flex; justify-content: flex-end;">
      <button class="favButton" v-if="job.id" @click="handleFavoriteClick(job.id)">
        <img v-if="!isFavored" src='@/assets/favIcon-outlined.png' ref="favIcon" alt="Favorite Icon" class="favorieIcon"
          @mouseover="hoverImage(true, false)" @mouseout="hoverImage(false, false)" />
        <img v-else src='@/assets/favIcon-filled.svg' ref="favIcon" alt="Favorite Icon" class="favorieIcon"
          @mouseover="hoverImage(true, true)" @mouseout="hoverImage(false, true)" />
      </button>
    </div>
    <img v-if="job.logo_url" class="logo pointer" :src="job.logo_url" alt="company logo" @click="gotoDetail(job)" />
    <img v-else-if="job.source == 'FT' && job.logo_url == null" class="logo pointer" src='@/assets/francetravail.svg' alt="company logo" @click="gotoDetail(job)" />
    <img v-else src="@/assets/logo.png" class="logo pointer" style="margin-bottom: 50px;" @click="gotoDetail(job)" />

    <button class="favButton" v-if="job.job_id" @click="handleFavoriteClick(job.job_id)">
      <img v-if="!isFavored" src='@/assets/favIcon-outlined.png' ref="favIcon" alt="Favorite Icon" class="favorieIcon"
        @mouseover="hoverImage(true, false)" @mouseout="hoverImage(false, false)" />
      <img v-else src='@/assets/favIcon-filled.svg' ref="favIcon" alt="Favorite Icon" class="favorieIcon"
        @mouseover="hoverImage(true, true)" @mouseout="hoverImage(false, true)" />
    </button>
    <div class="detailWrapper">
      <div class="jobHeader">
        <h3 class="jobTitle" v-if="job.title || job.titre" @click="gotoDetail(job)">{{ job.title || job.titre }}</h3>
      </div>

      <div class="jobInfo">
        <p v-if="job.company && job.source">
        <h5 @click="gotoPage(`/company/${job.author}`)" class="pointer" style="width: fit-content;">
          {{ job.company }}
          <img v-if="getSourceImage(job.source)" :src="getSourceImage(job.source)" alt="Source Image" class="taille" />
          <img v-else-if="job.source == 'FT'" src='@/assets/francetravail.svg' alt="Source Image" class="source-icon" />
        </h5>
        </p>
        <p v-else>
          non communiqué
          <img v-if="getSourceImage(job.logo_url)" :src="getSourceImage(job.logo_url)" alt="Source Image" class="taille" />
          <img v-else-if="job.source == 'FT'" src='@/assets/francetravail.svg' alt="Source Image" class="source-icon" />
        </p>

        <!-- source: FRANCE TRAVAIL -->
        <div class="jobLocalization" v-if="job.source === 'FT'">
          <img src="@/assets/location.png" alt="Localisation" />
          <p>{{ `${formatLocation(job.local)}` }}</p>
        </div>

        <!-- source: Thanks boss -->
        <div class="jobLocalization" v-else-if="job.source === 'Thank-boss'">
          <img src="@/assets/location.png" alt="Localisation" />
          <p>{{ `${job.code_postal} ${formatLocation(job.local)} / ${getRemoteOption(job.jours_teletravail)}` }}</p>
        </div>

        <!-- source: -->
        <div class="jobLocalization" v-if="job.local && job.presence">
          <img src="@/assets/location.png" alt="Localisation" />
          <p>{{ `${formatLocation(job.local)} / ${job.presence}` }}</p>
        </div>

        <!-- source: -->
        <div class="jobLocalization" v-if="job.localisation">
          <img src="@/assets/location.png" alt="Localisation" />
          <p>{{ `${formatLocation(job.localisation)} / ${job.teletravail}` }}</p>
        </div>
        
        <div class="jobDetails" v-if="job.contract">
          <div class="jobWage">
            <img src="@/assets/Conditions.png" alt="Conditions" />
            <p>
              {{ job.contract }}
            </p>
            <p v-if="job.salaire_min && job.salaire_max">
              / {{ job.salaire_min }}€ -
              {{ job.salaire_max }}€
            </p>
          </div>
        </div>
        <div class="jobDetails" v-if="job.expérience">
          <div class="jobExperience" v-if="job.expérience">
            <img src="@/assets/Briefcase.png" alt="Experience" />
            <p>{{ getContractType(job.expérience) || 'senior' }}</p>
            <button data-bs-toggle="modal" data-bs-target="#infoModal">
              <img src="@/assets/Info.png" alt="info" />
            </button>
          </div>
        </div>
        <div class="jobDetails" v-if="job.type_contrat">
          <div class="jobWage">
            <img src="@/assets/Conditions.png" alt="Conditions" />
            <p>
              {{ job.type_contrat }}
            </p>
            <p v-if="job.salaire_min && job.salaire_max">
              / {{ job.salaire_min }}k -
              {{ job.salaire_max }}k €
            </p>
          </div>
          <div class="jobExperience" v-if="job.experience">
            <img src="@/assets/Briefcase.png" alt="Experience" />
            <p>{{ getContractType(job.experience) || 'senior' }}</p>
            <button data-bs-toggle="modal" data-bs-target="#infoModal">
              <img src="@/assets/Info.png" alt="info" />
            </button>
          </div>
        </div>
        <!-- savoir faire -->
        <!-- <div class="jobTags" style="margin-top: 40px;" v-if="job.savoir">
          <p v-for="keyword in splitKeywords(job.savoir, 3) " :key="keyword" class="primary-jobtag-item">
            {{ keyword.libelle }}
          </p>
        </div> -->
        <!-- keywords principale -->
        <div class="jobTags" style="margin-top: 40px;" v-if="job.principal_keywords && job.secondary_keywords">
          <p v-for="keyword in splitKeywords(job.principal_keywords, 3) " :key="keyword" class="primary-jobtag-item">
            {{ keyword }}
          </p>
        </div>
        <!-- keywords secondaires -->
        <div class="jobTags" style="margin-top: 7px;" v-if="job.principal_keywords && job.secondary_keywords">
          <p v-for="keyword in splitKeywords(job.secondary_keywords, 3)  " :key="keyword" class="secondary-jobtag-item">
            {{ keyword }}
          </p>
        </div>
        <!-- principales compétences -->
        <div class="jobTags" style="margin-top: 40px;" v-else-if="job.compétences_principales">
          <p v-for="keyword in splitPrimaryKeywords(job.compétences_principales, 3)" :key="keyword"
            class="secondary-jobtag-item">
            {{ keyword }}
          </p>
        </div>
        <div class="jobTags" style="margin-top: 40px;" v-else-if="job.keywords">
          <p v-for="keyword in splitPrimaryKeywords(job.keywords, 3) " :key="keyword" class="secondary-jobtag-item">
            {{ keyword }}
          </p>
        </div>
      </div>
    </div>
    <div class="jobFooter">
      <div class="footerLine">
        <ButtonComponent text="Détail" @click="gotoDetail(job)" />
        <p class="jobDate" v-if="job.created_at">Publié : {{ formatDate(job.updated_at) }}</p>
        <div class="favCountJob" v-if="job.favorie">
          <img src="@/assets/favIcon-outlined.png" alt="Favorite Count" /> {{ job.favorie }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toaster } from "@/utils/toast/toast";
import { mapActions, mapGetters } from "vuex";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import linkedinImage from '@/assets/linkedin.svg';
import thankBossImage from '@/assets/oeil_TB.svg';
import getAxiosInstance from "@/services/axios";

export default {
  name: "JobCard",
  components: {
    ButtonComponent,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
    favoriteList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isFavored: false,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  beforeMount() {
    if (this.job.id) {
      this.isFavored = this.checkFav(this.job.id);
    } else {
      this.isFavored = this.checkFav(this.job.job_id);
    }
  },
  methods: {
    ...mapActions(["handleAddFavoris", "handleDeleteFavoris"]),
    checkFav(jobId) {
      try {
        if (jobId === null || jobId === undefined) return false;
        return this.isFavored = this.favoriteList.some(favJob => favJob.job_offer.id === jobId);
      } catch (error) {
        console.log("Erreur lors de la vérification des favoris :", error);
        return false;
      }
    },
    gotoPage(url) {
      if (this.job.source.toLowerCase() !== 'Thank-boss'.toLowerCase()) {
        return false;
      }
      return this.$router.push({ path: url });
    },
    gotoDetail(job) {
      const id = job.id || job.job_id;
      const routeData = this.$router.resolve({ name: 'jobdetails', params: { id: id }});
      window.open(routeData.href, '_blank');
    },
    async handleFavoriteClick(id) {
      try {
        if (!this.$store.getters.isLoggedIn) {
          return toaster.showErrorPopup("Connectez-vous à votre compte pour ajouter un candidat en favori.");
        }

        if (this.isFavored) {
          await getAxiosInstance().post(`/favorite_job/add/${id}`);
          this.job.favorie = this.job.favorie - 1;
          this.handleDeleteFavoris(id);
          this.isFavored = false;
          this.$emit("update-favoris");
          this.$emit("isFavored", false);
          toaster.showSuccessPopup("Cette offre d'emploi a bien été retirée des favoris.");
        } else {
          await getAxiosInstance().post(`/favorite_job/add/${id}`);
          this.job.favorie = this.job.favorie + 1;
          this.handleAddFavoris({ job_offer: this.job });
          this.isFavored = true;
          this.$emit("update-favoris");
          this.$emit("isFavored", true);
          toaster.showSuccessPopup("Cette offre d'emploi a bien été ajoutée en favori.");
        }
      } catch (error) {
        toaster.showErrorPopup("Erreur lors de l'ajout en favori.");
        console.error("Erreur lors de l'ajout en favori :", error);
      }
    },

    getSourceImage(source) {
      if (source === null || source === undefined) return;
      if (source.toLowerCase() === 'linkedin') {
        return linkedinImage;
      } else if (source.toLowerCase() === 'thank-boss') {
        return thankBossImage;
      }
      return null;
    },
    getContractType(expertise) {
      if (!expertise || expertise === undefined || expertise === null || expertise === 'Not Applicable') {
        return 'senior';
      }
      return expertise;
    },
    formatLocation(location) {
      if (!location || location === undefined || location === null) {
        return 'voir l\'annonce';
      }
      const commaIndex = location.indexOf(',');
      return commaIndex === -1 ? location : location.substring(0, commaIndex);
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, options);
    },
    splitKeywords(keywords, limit = null) {
      if (keywords === null || keywords === undefined) {
        return false;
      } 
      const keywordFiltered = keywords.filter(key => key !== '');
      if (keywordFiltered.length > 0) {
        if (limit !== null) {
          return keywordFiltered.slice(0, limit);
        } else {
          return keywordFiltered;
        }
      }
    },
    splitPrimaryKeywords(keywords) {
      if (keywords === null || keywords === undefined) {
        return [];
      }
      if (keywords.length > 0) {
        const keywordsArray = keywords.split(",");
        return keywordsArray.slice(0, 3);
      }
    },
    hoverImage(isHovered, isFavored) {
      if (isFavored) {
        const imgSrc = isHovered ? require('@/assets/favIcon-outlined.png') : require('@/assets/favIcon-filled.svg')
        return this.$refs.favIcon.src = imgSrc;
      } else {
        const imgSrc = isHovered ? require('@/assets/favIcon-filled.svg') : require('@/assets/favIcon-outlined.png')
        return this.$refs.favIcon.src = imgSrc;
      }
    },
    // manipule les datas du champ télétravail pour envoyer le bon paramètre dans la requête
    getRemoteOption(remoteOption) {
      switch(remoteOption) {
        case 5:
          return "Télétravail complet";
        case 2:
          return "Télétravail partiel";
        case 1:
          return "En présentiel";
        case 3:
          return "Hybride";
        default:
          return null;
      }
    },
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit300";
  font-size: 18px;
  font-style: normal;
  line-height: 141% !important;
}

p {
  margin-right: 7px !important;
}

.primary-jobtag-item {
  margin-bottom: 10px !important;
}

.secondary-jobtag-item {
  display: flex;
  padding: 4px 15px 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-bottom: 10px !important;
  background-color: var(--gray-clear, #e5e5e5);
}

.jobCard {
  display: flex;
  height: 683px;
  width: 400px;
  padding: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.jobCard .logo {
  width: 100%;
  height: 154px;
  border-radius: 9px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 20px;
  border-radius: 10px;
}

.jobCard .detailWrapper {
  width: 100%;
  height: 80%;
}

.jobCard .jobHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.jobCard .jobHeader .jobTitle {
  font-family: "Outfit500";
  font-size: 21px !important;
  height: 68px;
  overflow: hidden;
  line-height: normal;
  white-space: nowrap;
  cursor: pointer;
}

.jobCard .jobHeader .favButton {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin-left: 8px;
  cursor: pointer;
}

.jobCard .jobHeader .favButton:hover {
  height: 45px;
  width: 45px;
}

.jobCard .jobHeader .already-favored {
  background: none;
  border: none;
}

.jobCard .jobInfo {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -40px;
  margin-bottom: 10px !important;
}

.jobCard .jobInfo .jobCompany {
  font-size: 24px !important;
  text-align: start;
}

.jobCard .jobInfo .jobLocalization {
  display: flex;
  justify-content: start;
  align-items: center;
}

.jobCard .jobInfo .jobLocalization img {
  width: 21px;
  height: 21px;
  margin-right: 8px;
}

.jobCard .jobInfo .jobLocalization p {
  font-size: 18px;
}

.jobCard .jobInfo .jobDetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.jobCard .jobInfo .jobDetails .jobWage {
  display: flex;
  justify-content: start;
  align-items: center;
}

.jobCard .jobInfo .jobDetails .jobExperience {
  display: flex;
  justify-content: start;
  align-items: center;
}

.jobCard .jobInfo .jobDetails .jobExperience button {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.jobCard .jobInfo .jobDetails .jobWage img,
.jobCard .jobInfo .jobDetails .jobExperience img {
  width: 21px;
  height: 21px;
  margin-right: 8px;
}

.jobCard .jobInfo .jobTags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.jobCard .jobInfo .job-tag {
  padding: 4px 15px 4px 15px;
  border-radius: 50px;
  text-align: center;
}

.jobCard .jobFooter {
  width: 100%;
}

.jobCard .jobFooter .jobDate {
  font-size: 14px !important;
}

.jobCard .jobFooter .footerLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
}

.jobCard .jobFooter .footerLine .favCountJob {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jobCard .jobFooter .footerLine .favCountJob img {
  width: 21px;
  height: 21px;
  margin-right: 5px;
}

.taille {
  height: 25px;
  width: 25px;
  margin-bottom: 3px;
}

.source-icon {
  width: 50px;
  height: 50px;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: 1360px) {
  .wrapperJobOffers {
    grid-template-columns: repeat(2, 423px);
  }
}

@media screen and (max-width: 990px) {
  .wrapperJobOffers {
    grid-template-columns: 423px;
  }
  .jobDate {
    margin-left: 6px !important;
  }
}

@media screen and (max-width: 720px) {
  .jobCard {
    height: 660px;
    max-width: 300px;
  }
  .wrapperJobOffers {
    grid-template-columns: 339px;
  }

  .jobCard .jobHeader .jobTitle {
    width: 100%;
    font-size: 22px !important;
  }

  .jobCard .jobHeader .favButton {
    align-items: start;
  }

  .jobCard .jobHeader .favButton img {
    width: 32px;
    height: 32px;
  }

  .jobCard .jobInfo .jobCompany {
    font-size: 21px !important;
  }
}
</style>