<template>

  <div>
    <div class="row">
      <div class="col-sm-12 col-lg-6 d-flex flex-column justify-content-center">
        <h3>Tous les filtres</h3>
      </div>
      <div class="col-sm-12 col-lg-6">
        <MatchAndGo @click="handleNavigate('accompagnee')" />
      </div>
    </div>
    <div class="filter mt-4">
      <div class="row justify-content-between w-100">
        <div class="col-sm-12 col-lg-6 mygap">
          <div>
            <h4 class="ms-3">Profession</h4>
            <select class="form-select" aria-label="Sélectionner" v-model="selectedProfession">
              <option value="" disabled hidden>Sélectionner</option>
              <option v-for="profession in PROFESSION_FIELDS" :key="profession.value" :value="profession.nom">
                {{ profession.nom }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-lg-6 mygap">
          <h4 class="ms-3">Où ?</h4>
          <div class="city_field">
            <JustVille @justCityEntry="getJustCity" :initialChips="ouChips" />
          </div>
        </div>
      </div>

      <div class="row justify-content-between w-100">
        <div class="col-sm-12 col-lg-6 mygap">
          <div>
            <h4 class="ms-3">Compétences</h4>
            <div class="d-flex flex-row flex-wrap align-items-center">
              <Chip :placeholder="skillPlaceholder" :index="'skill'" :initialChips="skillChips"
                @chip-added="updatePlaceholder('skill')" @no-chips="resetPlaceholder('skill')" />
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-lg-6 mygap">
          <h4 class="ms-3">Contrat</h4>
          <select class="form-select" aria-label="Sélectionner" v-model="selectedContract">
            <option value="" disabled hidden>Sélectionner</option>
            <option v-for="contract in CONTRACT_FIELDS" :key="contract.value" :value="contract.contrat">
              {{ contract.contrat }}
            </option>
          </select>
        </div>
      </div>

      <div class="row justify-content-between w-100">
        <div class="col-sm-12 col-lg-6 mygap">
          <h4 class="ms-3">Secteur d'activité</h4>
          <select class="form-select" aria-label="Sélectionner" v-model="selectedActivity">
            <option value="" disabled hidden>Sélectionner</option>
            <option v-for="activity in ACTIVITY_FIELDS" :key="activity.value" :value="activity.nom">
              {{ activity.nom }}
            </option>
          </select>
        </div>
        <div class="col-sm-12 col-lg-6 mygap">
          <h4 class="ms-3">Experience</h4>
          <select class="form-select" aria-label="Sélectionner" v-model="selectedExperience">
            <option value="" disabled hidden>Sélectionner</option>
            <option v-for="experience in EXPERIENCE_FIELDS" :key="experience.value" :value="experience.experience_job">
              {{ experience.experience_job }}
            </option>
          </select>
        </div>
      </div>

      <div class="row justify-content-between w-100">
        <div class="col-sm-12 col-lg-6 mygap">
          <h4 class="ms-3">Télétravail</h4>
          <select class="form-select" aria-label="Sélectionner" v-model="selectedTeletravail">
            <option value="" disabled hidden>Sélectionner</option>
            <option v-for="value in TELETRAVAIL_FIELDS" :key="index" :value="value.teletravail">
              {{ value.teletravail }}
            </option>
          </select>
        </div>
        <div class="col-sm-12 col-lg-6 mygap">
          <h4 class="ms-3">Salaire</h4>
          <YellowSlider :salaire_min="salaire_min" :salaire_max="salaire_max" @salary="setNewSalaryValues" />
        </div>
      </div>
    </div>
  </div>
  <div class="final-filter mt-5">
    <p>
      Affine ta recherche avec des critères exclusifs.
      <br />Thanks-Boss est capable de traiter des requêtes très
      personnelles !
    </p>
    <div class="row justify-content-between w-100">
      <div class="col-sm-12 col-lg-6 mygap">
        <div>
          <div class="d-flex align-items-center">
            <h4 class="ms-3 me-3">Avantages</h4>
            <button class="lamp" data-bs-toggle="modal" data-bs-target="#avantageDetail">
              <img src="@/assets/Group.svg" alt="lamp" />
            </button>
          </div>
          <div class="d-flex flex-row flex-wrap align-items-center">
            <!-- <input
                        v-model="this.avantages"
                        placeholder="Conditions de travail,..."
                        class="form-control"
                      /> -->
            <Chip :placeholder="avantagesPlaceholder" :index="'avantages'" :initialChips="avantagesChips"
              @chip-added="updatePlaceholder('avantages')" @no-chips="resetPlaceholder('avantages')" />
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6 mygap">
        <div style="margin-top: 5px">
          <h4 class="ms-3">Valeurs</h4>
          <div class="d-flex flex-row flex-wrap align-items-center">
            <!-- <input
                        v-model="this.valeurs"
                        placeholder="Ce qui vous tient à cœur"
                        class="form-control"
                      /> -->
            <Chip :placeholder="valeursPlaceholder" :index="'valeurs'" :initialChips="valeursChips"
              @chip-added="updatePlaceholder('valeurs')" @no-chips="resetPlaceholder('valeurs')" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center w-70 gap-5 m-4">
    <button-component text="Réinitialiser" @click="resetFilter" />
    <button-component text="Valider" @click="emitFilterData" />
  </div>
</template>

<script>
import { ACTIVITY_FIELDS } from "@/utils/base/activity_sector";
import { CONTRACT_FIELDS } from "@/utils/base/contract";
import { EXPERIENCE_FIELDS } from "@/utils/base/experience";
import { PROFESSION_FIELDS } from "@/utils/base/profession";
import { SALARY_FIELDS } from "@/utils/base/salary";
import { TELETRAVAIL_FIELDS } from "@/utils/base/teletravail";
import MatchAndGo from "@/components/layout/match-go/MatchAndGo.vue";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import Chip from "@/components/chip/Chip.vue";
import JustVille from "@/components/inputs/city-droplist/JustVille.vue";
import YellowSlider from "@/components/inputs/slider/Slider.vue";

export default {
  name: "filterfield",
  components: {
    Chip,
    MatchAndGo,
    ButtonComponent,
    JustVille,
    YellowSlider,
  },
  data() {
    return {
      skillPlaceholder: "Qualifications, outils...",
      avantagesPlaceholder: "Conditions de travail,...",
      valeursPlaceholder: "Ce qui vous tient à cœur",
      ACTIVITY_FIELDS,
      CONTRACT_FIELDS,
      EXPERIENCE_FIELDS,
      PROFESSION_FIELDS,
      SALARY_FIELDS,
      TELETRAVAIL_FIELDS,
      selectedProfession: "",
      selectedTeletravail: "",
      selectedContract: "",
      selectedActivity: "",
      selectedExperience: "",
      salaire_min: "",
      salaire_max: "",
    };
  },
  methods: {
    setNewSalaryValues(newSalary) {
      this.salaire_min = newSalary.salaire_min;
      this.salaire_max = newSalary.salaire_max;
    },
    getJustCity(newJustCity) {
      this.$store.commit("autonome/addOneOuChip", newJustCity.ville);
    },
    emitFilterData() {
      this.$emit("get-filter-data", {
        titre: this.selectedProfession,
        teletravail: this.selectedTeletravail,
        contrat: this.selectedContract,
        secteur: this.selectedActivity,
        experience: this.selectedExperience,
        salaire_min: this.salaire_min,
        salaire_max: this.salaire_max,
        skill: this.skillChips,
        avantages: this.avantagesChips,
        valeurs: this.valeursChips,
      });
    },
    resetFilter() {
      this.selectedProfession = "";
      this.selectedTeletravail = "";
      this.selectedContract = "";
      this.selectedActivity = "";
      this.selectedExperience = "";
      this.salaire_min = "";
      this.salaire_max = "";
      this.skillChips = [];
      this.avantagesChips = [];
      this.valeursChips = [];
    },
    handleNavigate(routeName) {
      this.$router.push({ name: routeName });
    },
    updatePlaceholder(val) {
      switch (val) {
        case "skill":
          this.skillPlaceholder = "Ajouter";
          break;
        case "avantages":
          this.avantagesPlaceholder = "Ajouter";
          break;
        case "valeurs":
          this.valeursPlaceholder = "Ajouter";
          break;
        default:
          break;
      }
      this.currentText = "";
    },
    resetPlaceholder(val) {
      switch (val) {
        case "skill":
          this.skillPlaceholder = "Qualifications, outils...";
          break;
        case "avantages":
          this.avantagesPlaceholder = "Conditions de travail,...";
          break;
        case "valeurs":
          this.valeursPlaceholder = "Ce qui vous tient à cœur";
          break;
        default:
          break;
      }
    },
  },
  computed: {
    skillChips() {
      return this.$store.state.autonome.skillChips;
    },
    avantagesChips() {
      return this.$store.state.autonome.avantagesChips;
    },
    valeursChips() {
      return this.$store.state.autonome.valeursChips;
    },
    ouChips() {
      return this.$store.state.autonome.ouChips;
    },
  },
};
</script>

<style scoped>
.modal-dialog {
  min-width: 80%;
  height: 90%;
}

.modal-content {
  width: 100%;
  height: 100%;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-body {
  padding: 0 !important;
  overflow-y: auto;
}

.modal-container {
  padding: 0 63.5px;
}

.mygap {
  margin-top: 20px;
}

.form-control {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--gray-medium, #c4c4c4);
  background: #fff;
  margin-top: 8px;
}

.form-select {
  padding: 12px 15px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid var(--gray-medium, #c4c4c4);
  margin-top: 16px;
  height: 51px;
}

.final-filter {
  padding: 25px 65px;
  gap: 15px;
  background: var(--Primary-jaune, #fbde2f);
}

.lamp {
  background-color: white;
  border-color: white;
  border-radius: 20px;
  border: none;
  padding-inline: 10px;
  padding-block: 5px;
}

.city_field {
  margin-top: 16px;
  border-radius: 5px;
  max-height: 52px;
}

.vue-slider-dot-tooltip-inner {
  border-color: #0f0 !important;
  background-color: #0f0 !important;
}

@media (max-width: 768px) {
  .modal-dialog {
    min-width: 94%;
    min-width: 94%;
  }

  .modal-container {
    padding: 0 24px;
  }

  .final-filter {
    padding: 25px 45px;
  }
}
</style>
