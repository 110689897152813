<template>
    
    <div class="page-content">

        <h1>Actualités</h1>

        <div v-for="card in news" class="card-content">

            <div class="card-header">
                <img src="@/assets/circle.png" alt="tb logo" class="avatar"/>
                <h4>{{ card.header_title }}</h4>
            </div>

            <div class="card-body">
                <h5 class="card-title">{{ card.title }}</h5>
                <p class="card-p">{{ card.body }}</p>
            </div>

            <div class="card-footer">
                
            </div>

        </div>

    </div>

</template>

<script>
import getAxiosInstance from '@/services/axios';

export default {
    name: "news",
    data() {
        return {
            news: [
                {
                    "header_title": "Community Manager",
                    "title": "Test Title",
                    "body": "Merol muspi",
                },
                {
                    "header_title": "Community Manager",
                    "title": "Test Title",
                    "body": "Merol muspi",
                },
            ],
        }
    },
    mounted() {
        this.getNews();
    },
    methods: {
        async getNews() {
            try {
                console.log("are u getting news ?")
                const response = getAxiosInstance().get('');
                if (response.status === 200) this.news = response.data;
            } catch(e) {
                console.log(e);
            }

        },
    },
}
</script>

<style setup>
.page-content {
    min-height: 70vh;
    width: 100%;
    border: 1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(248, 249, 250);
    padding-bottom: 100px;
}

.card-content {
    margin-top: 30px;
    min-width: 800px;
    min-height: 300px;
    height: fit-content;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
    padding: 20px;
}

.card-header {
    display: flex;
    justify-content: start;
    height: 60px;
}

.avatar {
    margin-right: 20px;
    width: 40px;
    height: 40px;
}

.card-title {
    margin-top: 20px !important;
}

.card-p {
    margin-top: 20px !important;
}
</style>