<template>
  <div class="favoris-card mb-3">
    <div class="row mt">
      <div class="col-sm-12 col-md-4 d-flex justify-content-center">
        <div class="logo">
          <img v-if="element.job.logo_url" :src="element.job.logo_url" alt="company logo" />
          <img v-else src="@/assets/jobLogo.png" alt="company logo" />
        </div>
      </div>
      <div class="col-sm-12 col-md-8">
        <div class="job-info">
          <div class="d-flex justify-content-between align-items-center">
            <h3 class="job-title mt" data-bs-toggle="modal" data-bs-target="#jobDetail" style="cursor: pointer;"
              v-if="element.job.titre_post">{{ element.job.titre_post }}</h3>
            <h3 class="job-title mt" data-bs-toggle="modal" data-bs-target="#jobDetail" style="cursor: pointer;"
              v-if="element.job.title">{{ element.job.title }}</h3>

            <div class="mt">
              <span v-if="element.statut" :class="getStatusClass(element.statut)" class="status">{{ element.statut
                }}</span>
              <button v-else-if="element.postulation.statut" class="status">{{ element.postulation.statut }}</button>
            </div>
          </div>
          <h4 class="job-company mt">{{ element.company_post }}</h4>
          <div class="d-flex align-items-center mt">
            <img src="@/assets/location.png" alt="Location" style="height: 21px" />
            <p class="ms-1">
              {{ element.job.local }}
            </p>
          </div>
          <div class="row mt">
            <div class="d-flex align-items-center col-sm-12 col-md-8">
              <img src="@/assets/Conditions.png" alt="Conditions" style="height: 21px" />
              <p class="ms-1">
                {{ element.job.contract }} / {{ element.job.salaire_min }}k -
                {{ element.job.salaire_max }}k €
              </p>
            </div>
            <div class="d-flex align-items-center col-sm-12 col-md-4 mt">
              <img src="@/assets/Briefcase.png" alt="Experience" style="height: 21px" />
              <p class="ms-1">
                {{ element.job.expérience }}
              </p>
              <div class="d-flex align-items-center ms-2">
                <button class="ms-2" data-bs-toggle="modal" data-bs-target="#infoModal">
                  <img src="@/assets/Info.png" alt="info" />
                </button>
              </div>
            </div>
          </div>
          <div class="job-footer mtb">
            <div class="d-flex justify-content-between align-items-center">
              <p class="job-date">{{ formatDate(element.date_creation) }}</p>
              <div class="d-none d-lg-flex gap-2">
                <ButtonComponent text="Détail" @click="gotoDetail(element.job)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/buttons/button/Button.vue";

export default {
  name: "CandidatureTemplate",
  components: {
    ButtonComponent,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  methods: {
    gotoDetail(job) {
      this.$router.push("/details/" + job.id);
    },
    formatDate(dateString) {
      const options = { day: '2-digit', month: 'long', year: 'numeric' };
      return new Date(dateString).toLocaleDateString('fr-FR', options);
    },
    getStatusClass(element) {
      const status = element;
      if (status === 'En Cours') {
        return 'pending';
      } else if (status === 'Accepté') {
        return 'accepted';
      } else if (status === 'Refusé') {
        return 'refused';
      } else {
        return '';
      }
    },
  },
};
</script>

<style scoped>
.favoris-card {
  display: flex;
  padding: 25px 25px 25px 35px;
  flex-direction: column;
  gap: 35px;
  border-radius: 10px;
  border: 1px solid var(--Gray-medium, #c4c4c4);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.logo {
  display: flex;
  padding: 16.541px;
  justify-content: center;
  align-items: center;
  border-radius: 8.271px;
  height: 220px;
}

.logo img {
  max-height: 100%;
  width: 100%;
}

.status {
  color: #979797;
  padding: 5px 10px;
  font-size: 20px;
  font-family: 'Outfit300';
  background-color: white;
}

.pending {
  color: rgb(81, 115, 215);;
}

.accepted {
  color: #35C778;
}

.refused {
  color: #FF0000;
}

.job-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

button {
  background-color: white;
  margin: 0;
  padding: 0;
  border: 0;
}

@media (max-width: 992px) {
  .favoris-card {
    margin-top: 30px;
  }

  .logo {
    height: 184px;
  }
}

@media (max-width: 768px) {
  .mt {
    margin-top: 20px;
  }

  .mtb {
    margin-top: 50px;
  }
}
</style>