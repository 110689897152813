<template>
  <div class="popup-mc-container" v-if="isPopupVisible">
    <p>
      Trouver de bons mots clés peut être fastidieux. 
    </p>
    <p>
      Clique sur moi, décris tes souhaits et profite d’une sélection de tags sur mesure !
    </p>
  </div>
</template>

<script>
export default {
  name: 'PopupMc',
  data() {
    return {
      isPopupVisible: false,
    };
  },
}
</script>

<style scoped>
.popup-mc-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 193px;
  font-size: 16px;
  padding: 20px;
  border-radius: 10px 10px 10px 0px;
  border: 2px solid var(--Primary-jaune, #FBDE2F);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>