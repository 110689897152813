<template>
  <div class="alert-card mt-4">
    <div class="d-flex justify-content-between">
      <h3>{{ element.nom }}</h3>
      <div class="setting d-flex align-items-center">
        <img src="@/assets/Delete.png" alt="delete" class="setting-icon" @click="deleteAlerte(element)" />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button-component text="Voir les offres" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertTemplate",
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  methods: {
    editAlerte(element) {
      this.$emit("edit-alerte-modal", element);
    },
    deleteAlerte(element) {
      this.$emit("delete-alerte", element);
    },
  },
};
</script>

<style scoped></style>