export const CRITERIA_FIELDS = [
  "skill",
  "titre",
  "localisation",
  "avantages",
  "valeurs",
];

export const CRITERIA_FIELDS_MAP = {
  secteur: "industrie",
  avantages: "avantage",
  valeurs: "valeur",
};

export const SHORTEN_CRITERIA_FIELDS = [
  { title: "Compétences", key: "skill" },
  { title: "Avantages", key: "avantages" },
  { title: "Valeurs", key: "valeurs" },
];

export const EXPANDED_CRITERIA_FIELDS = [
  { title: "Quoi ?", key: "quoi" },
  { title: "Où ?", key: "localisation" },
  { title: "Compétences", key: "skill" },
  { title: "", key: "" },
  { title: "Avantages", key: "avantages" },
  { title: "Valeurs", key: "valeurs" },
];

export const convertConversationData = (data) => {
  return data.map((item) => {
    let date_modification = item.date_modification;
    const contents = [...item.messages, ...item.criterias]
      .sort((a, b) => (a.date_modification < b.date_modification ? -1 : 1))
      .reduce((prev, curr) => {
        date_modification =
          date_modification > curr.date_modification
            ? date_modification
            : curr.date_modification;
        if (curr.message_applicant) {
          if (prev.length) {
            if (prev[prev.length - 1].criteria) {
              return [...prev, { messages: [{ ...curr }] }];
            }
            return [
              ...prev.slice(0, prev.length - 1),
              {
                ...prev[prev.length - 1],
                messages: [...prev[prev.length - 1].messages, { ...curr }],
              },
            ];
          }
          return [{ messages: [{ ...curr }] }];
        }
        if (prev.length) {
          return [
            ...prev.slice(0, prev.length - 1),
            { ...prev[prev.length - 1], criteria: { ...curr } },
          ];
        }
        return [];
      }, []);
    return {
      conversation_id: item.conversation_id,
      titre: item.titre,
      contents,
      date_modification,
    };
  });
};

export const sortConversationWithLatest = (chats) => {
  const newChats = [...chats];
  newChats.sort((a, b) => (a.date_modification > b.date_modification ? -1 : 1));
  return newChats;
};
