<template>
  <nav class="navigation navbar navbar-expand-lg bg-body-tertiary">
    <!-- MOBILE SCREEN -->
    <div v-if="isMobile">
      <div class="nav-items-left">
        <!-- BURGER BUTTON -->
        <button v-if="!visible" class="navbar-toggler" type="button" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          @click="toggleNavBar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- MARK BUTTON -->
        <button v-else class="navbar-mark" type="button" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          @click="toggleNavBar">
          <span style="font-size: 26px !important;">x</span>
        </button>
        <!-- BURGER MENU -->
        <div class="navbar-collapse justify-content-between" id="navbarSupportedContent"
          :class="!visible ? 'collapse' : ''">
          <div class="navbar-nav-container">
            <ul class="navbar-nav nav-items" :style="!visible ? 'margin-top: 20px;' : ''">
              <li>
                <a aria-current="page" @click="gotoPage('/')">Accueil</a>
              </li>
              <li>
                <a aria-current="page" @click="gotoPage('concept')">Concept</a>
              </li>
              <li>
                <a aria-current="page" @click="gotoPage('autonome')">Recherche Autonome</a>
              </li>
              <li>
                <a aria-current="page" @click="gotoPage('accompagnee')">Recherche Accompagnée</a>
              </li>
              <li>
                <a aria-current="page" @click="gotoPage('notreAdn')">Notre ADN</a>
              </li>
              <!-- <li style="margin-top: 20px;">
                <a href="https://app.thanks-boss.com/recruiter/" class="text-decoration-none">
                  <button-component style="font-size: 18px;" text="Espace recruteurs"></button-component>
                </a>
              </li> -->
              <li class="nav-item" v-if="!isLoggedIn">
                <button-component style="font-size: 168x;" @click="gotoPage('login')" text="Se connecter" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- DISPLAY IMAGE AT THE MIDDLE OF THE NAVBAR ON MOBILE -->
    <img v-if="isMobile" src="@/assets/logo.png" alt="Logo" width="140" class="d-inline-block align-text-top"
      :style="{ cursor: 'pointer' }" @click="gotoHomepage" />

    <!-- LARGE SIZE SCREEN -->
    <div :class="!isMobile ? 'nav-items-1' : ''">
      <div class="nav-items-left" v-show="!isMobile">
        <img src="@/assets/logo.png" alt="Logo" width="210" class="d-inline-block align-text-top"
          :style="{ cursor: 'pointer' }" @click="gotoHomepage" />
        <div style="margin-left: 20px; align-items:center">
          <ul class="navbar-nav nav-items" style=" width: 100%; display: flex; flex-direction: row;">
            <li class="nav-item">
              <a aria-current="page" @click="gotoPage('concept')">Concept</a>
            </li>
            <li class="nav-item">
              <a aria-current="page" @click="gotoPage('autonome')">Jobs</a>
            </li>
            <li class="nav-item">
              <a aria-current="page" @click="gotoPage('notreAdn')">Notre ADN</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- AVATAR DROPDOWN MENU -->
      <div class="nav-recruteurconnect-connexion">
        <div v-if="isLoggedIn" class="nav-recruteurconnect-compteentreprise1">
          <div class="nav-dropdown">
            <div v-if="isMobile">
              <div v-if="!showDropdown" @click="toggleDropdown" style="cursor:pointer;">
                <img src="@/assets/nav/Vector.png" alt="PersongrandI111 0" class="nav-recruteurconnect-persongrand1" />
                <img src="@/assets/nav/CollapseArrow.png" alt="CollapseArrowI1110" />
              </div>
              <button v-else @click="toggleDropdown" class="navbar-mark" type="button"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span style="font-size: 26px !important;">x</span>
              </button>
            </div>
            <div v-else @click="toggleDropdown">
              <img src="@/assets/nav/Vector.png" alt="PersongrandI111 0" class="nav-recruteurconnect-persongrand1" />
              <img src="@/assets/nav/CollapseArrow.png" alt="CollapseArrowI1110" />
            </div>
            <div class="dropdown-content" v-if="showDropdown" @mouseleave="toggleDropdown">
              <ol class="no-bullets bottom-border">
                <li v-for="(item, index) in dropDownItemsTop" :key="index">
                  <a @click="gotoPage(item.text)">
                    {{ item.text }}
                  </a>
                </li>
              </ol>
              <ol class="no-bullets bottom-border" style="margin-top: 8px">
                <li v-for="(item, index) in dropDownItemsMid" :key="index">
                  <a @click="gotoPage(item.text)">
                    {{ item.text }}
                  </a>
                </li>
              </ol>
              <ol class="no-bullets" style="margin-top: 8px">
                <li v-for="(item, index) in dropDownItemsBottom" :key="index">
                  <a @click.prevent="handleItemClick(item)">
                    {{ item.text }}
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <!-- LOGIN AND RECRUITER BTN -->
        <div v-else>
          <div class="d-flex justify-content-center" v-if="!isMobile">
            <!-- <li class="nav-btn-group">
              <a href="https://app.thanks-boss.com/recruiter/" class="text-decoration-none">
                <button-component text="Espace recruteurs"></button-component>
              </a>
            </li> -->
            <li class="nav-btn-group">
              <button-component text="Se connecter" @click="gotoLoginpage"></button-component>
            </li>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { accountService } from "@/services/account.service";
import { mapGetters } from "vuex";
import ButtonComponent from "@/components/buttons/button/Button.vue";
import { toaster } from "@/utils/toast/toast";

export default {
  name: "Navbar",
  data() {
    return {
      visible: false,
      dropDownItemsTop: [
        { text: "Mon profil" },
        { text: "Favoris" },
        { text: "Candidatures" },
        { text: "Alertes" },
      ],
      dropDownItemsMid: [
        { text: "Recherche autonome" },
        { text: "Recherche accompagnée" },
      ],
      dropDownItemsBottom: [{ text: "Déconnexion" }],
      showDropdown: false,
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  components: {
    ButtonComponent,
  },
  mounted() {
    this.myEventHandler();
    window.addEventListener('resize', this.myEventHandler);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      if (window.innerWidth <= 991) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    gotoPage(page) {
      this.visible = false;
      this.showDropdown = false;
      if(page === "Recherche accompagnée") {
        return this.$router.push({ path: "/accompagnee" });
      } else if (page === "Recherche autonome") {
        return this.$router.push({ path: "/autonome" });
      } else if (page === "Mon profil") {
        return this.$router.push({ path: "/account" });
      } else if (page === "Favoris") {
        return this.$router.push({ path: "/favoris" });
      } else if (page === "Candidatures") {
        return this.$router.push({ path: "/candidatures" });
      } else if (page === "Alertes") {
        return this.$router.push({ path: "/alert" });
      } else {
        return this.$router.push({ path: `/${page}` });
      }
    },
    gorecrutement() {
      window.location.href = "https://app.thanks-boss.com/recruiter/";
    },
    gotoHomepage() {
      this.$router.push({ path: "/" });
    },
    gotoLoginpage() {
      this.$router.push({ path: "/login" });
    },
    gotoAdnPage() {
      this.$router.push({ path: "/notreAdn" });
    },
    gotoJobsPage() {
      this.$router.push({ path: "/autonome" });
    },
    toggleNavBar() {
      this.showDropdown = false;
      this.visible = !this.visible;
    },
    toggleDropdown() {
      this.visible = false;
      this.showDropdown = !this.showDropdown;
    },
    handleItemClick(item) {
      if (item.text === "Déconnexion") {
        this.logout();
      }
    },
    logout() {
      try {
        accountService.logout();
        toaster.showSuccessPopup("Vous êtes déconnecté");
        this.$router.push({ path: "/" });
        this.toggleDropdown();
      } catch (error) {
        toaster.showErrorPopup("Erreur lors de la déconnexion");
        this.toggleDropdown();
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .navigation {
    padding: 7px 12px !important;
  }

  .navbar-nav-container {
    height: calc(100vh - 54px);
    width: 300px;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 100%;
    z-index: 2999;
  }

  .dropdown-content {
    height: calc(100vh - 54px);
    width: 300px;
    position: absolute;
    background-color: #fff;
    right: 0;
    z-index: 2999;
  }

  a {
    font-size: 20px;
  }

  .nav-items li {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 436px) {

  .d-inline-block {
    display: none !important;
  }

  .navbar-nav-container {
    height: calc(100vh - 54px);
    width: 100%;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 100%;
    z-index: 2999;
  }

  .nav-items {
    margin-left: 20px;
    margin-top: 20px;
  }

  .dropdown-content {
    height: calc(100vh - 54px);
    width: 100% !important;
    position: absolute;
    background-color: #fff;
    z-index: 2999;
  }
}

.navigation {
  padding: 7px 54px;
}

.nav-items {
  margin-inline: 20px;
  align-items: flex-start !important;
}

.navbar-mark {
  border: none;
  background-color: transparent;
}

.nav-items-1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nav-items-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-items {
  align-items: center;
  gap: 10px;
}


.dropdown-content {
  position: absolute;
  width: 286px;
  font-size: 18px;
  padding: 11px 20px;
  z-index: 1;
  top: 100%;
  right: 0;
  border-radius: 0px 0px 5px 5px;
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(119, 93, 93, 0.12);
}

.nav-recruteurconnect-connexion {
  display: flex;
  align-items: center;
}

.nav-recruteurconnect-compteentreprise1 {
  display: flex;
  align-items: center;
}

.nav-recruteurconnect-persongrand1 {
  width: 30px;
  margin-right: 12px;
}

a {
  text-decoration: none;
}

.nav-item a {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-right: 15px;
}

.nav-item a:hover {
  border-bottom: 3px solid #fbde2f;
}

li a:hover:not(.nav-btn-group a) {
  border-bottom: 3px solid #fbde2f;
}

.nav-btn-group {
  display: flex;
  align-items: center;
  padding-left: 19px;
  list-style: none;
}

li {
  cursor: pointer;
}

li a {
  color: black;
  text-decoration: none;
}

.bottom-border {
  border-bottom: 1px solid black;
  padding-bottom: 110px;
}

.no-bullets li:not(:last-child) {
  margin-bottom: 10px;
}

.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.bottom-border li:last-child {
  margin-bottom: 8px;
}
</style>
