export const SALARY_FIELDS = [
  { id: 1, salary: '0 - 20k€' },
  { id: 2, salary: '20 - 30k€' },
  { id: 3, salary: '30 - 40k€' },
  { id: 4, salary: '40 - 50k€' },
  { id: 5, salary: '50 - 60k€' },
  { id: 6, salary: '60 - 70k€' },
  { id: 7, salary: '70 - 80k€' },
  { id: 8, salary: '80 - 90k€' },
  { id: 9, salary: '90 - 100k€' },
  { id: 10, salary: '100 - 110k€' },
  { id: 11, salary: '110 - 120k€' }
];