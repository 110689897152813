export default {
  namespaced: true,
  state: {
    quoiChips: [],
    ouChips: [],
    professionChips: [],
    skillChips: [],
    avantagesChips: [],
    valeursChips: [],
    isExpandedChat: false,
  },
  mutations: {
    addQuoiChip(state, chip) {
      state.quoiChips = [...state.quoiChips, chip];
    },
    addOuChip(state, chip) {
      state.ouChips = [...state.ouChips, chip];
    },
    addOneOuChip(state, chip) {
      state.ouChips = [chip];
    },
    addProfessionChips(state, chip) {
      state.professionChips = [...state.professionChips, chip];
    },
    addSkillChips(state, chip) {
      state.skillChips = [...state.skillChips, chip];
    },
    addAvantagesChips(state, chip) {
      state.avantagesChips = [...state.avantagesChips, chip];
    },
    addValeursChips(state, chip) {
      state.valeursChips = [...state.valeursChips, chip];
    },
    removeQuoiChip(state, index) {
      state.quoiChips.splice(index, 1);
    },
    removeOuChip(state, index) {
      state.ouChips.splice(index, 1);
    },
    removeProfessionChip(state, index) {
      state.professionChips.splice(index, 1);
    },
    removeSkillChip(state, index) {
      state.skillChips.splice(index, 1);
    },
    removeAvantagesChip(state, index) {
      state.avantagesChips.splice(index, 1);
    },
    removeValeursChip(state, index) {
      state.valeursChips.splice(index, 1);
    },
    updateExpandChat(state, newValue) {
      state.isExpandedChat = newValue;
    },
  },
  actions: {},
  getters: {},
};
