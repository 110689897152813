<template>
  <div>
    <div v-if="job">
      <div class="job-header mt-4">
        <!-- bouton cloture / go back -->
        <div class="goback-btn-wrapper">
          <button class="goback-btn" @click="goBackArrow()">
            <img src="@/assets/Back.png" alt="go back image" class="goback-img" />
          </button>
        </div>

        <div class="row">
          <div class="col-sm-12 col-lg-4 job-logo">
            <img v-if="job.logo_url" class="logo" :src="job.logo_url" alt="company logo" />
            <img v-else-if="job.source == 'FT' && job.logo_url == null" class="logo" src='@/assets/francetravail.svg' alt="Source Image" />
            <img v-else src="@/assets/logo.png" class="logo" @click="gotoPage(`/company/${job.author || job.author_id}`)" />
          </div>
          <div class="col-sm-12 col-lg-8">
            <div class="job-info">
              <h3 v-if="job.company" @click="gotoPage(`/company/${job.author}`)" class="job-company" style='cursor:pointer'>
                {{ job.company }}
                <img v-if="job.source == 'FT'" src='@/assets/francetravail.svg' alt="Source Image" class="source-icon" />
              </h3>
              <h2 v-if="job.title || job.titre" class="job-title mt-3">
                {{ job.title || job.titre }}
              </h2>
              <div class="row mt-4">
                <div class="col-sm-12 col-lg-4 d-flex align-items-center mt-2">
                  <img
                    src="@/assets/location.png"
                    alt="Location"
                    style="height: 21px"
                  />

                  <!-- source: -->
                  <p v-if="job.local && job.presence" class="ms-1">
                    {{ `${formatLocation(job.local)} / ${job.presence}` }}
                  </p>

                  <!-- source: -->
                  <p v-if="job.localisation" class="ms-1">
                    {{
                      `${formatLocation(job.localisation)} / ${job.teletravail}`
                    }}
                  </p>

                   <!-- source: FRANCE TRAVAIL -->
                   <p v-if="job.local" class="ms-1">
                      {{
                        `${formatLocation(job.local)}`
                      }}
                    </p>
                </div>
                <div class="col-sm-12 col-lg-5 d-flex align-items-center mt-2">
                  <img
                    src="@/assets/Conditions.png"
                    alt="Conditions"
                    style="height: 21px"
                  />
                  <p v-if="job.contract">
                    {{ job.contract }}
                  </p>
                  <p v-if="job.type_contrat">
                    {{ job.type_contrat }}
                  </p>
                  <p v-if="job.salaire_min && job.salaire_max">
                    &nbsp;/ {{ job.salaire_min }}k - {{ job.salaire_max }}k €
                  </p>
                </div>
                <div
                  class="experience col-sm-12 col-lg-3 d-flex align-items-center mt-2"
                >
                  <img
                    src="@/assets/Briefcase.png"
                    alt="Experience"
                    style="height: 21px"
                  />
                  <!--<p v-if="job.expertise" class="ms-1">
                    {{ job.expertise }}
                  </p>-->
                  <p v-if="job.experience" class="ms-1">
                    {{ job.experience }}
                  </p>
                  <p v-if="job.expérience" class="ms-1">
                    {{ job.expérience }}
                  </p>
                </div>
              </div>
              <div
                class="jobTags"
                style="margin-top: 40px"
                v-if="job.compétences_principales"
              >
                <p
                  v-for="keyword in splitKeywords(
                    job.compétences_principales,
                    3
                  )"
                  :key="keyword"
                  class="primary-jobtag-item"
                >
                  {{ keyword }}
                </p>
              </div>
              <div
                class="jobTags"
                style="margin-top: 40px"
                v-else-if="job.principal_keywords"
              >
                <p
                  v-for="keyword in splitPrimaryKeywords(
                    job.principal_keywords.skill
                  )"
                  :key="keyword"
                  class="primary-jobtag-item"
                >
                  {{ keyword }}
                </p>
              </div>
              <div class="jobTags" v-else>
                <p
                  v-for="keyword in splitKeywords(job.keywords, 3)"
                  :key="keyword"
                  class="primary-jobtag-item"
                >
                  {{ keyword }}
                </p>
              </div>
              <div
                class="jobTags"
                style="margin-top: 10px"
                v-if="job.compétences_secondaires"
              >
                <p
                  v-for="keyword in splitKeywords(
                    job.compétences_secondaires,
                    3
                  )"
                  :key="keyword"
                  class="job-tag secondary-jobtag-item"
                >
                  {{ keyword }}
                </p>
              </div>
              <div
                class="jobTags"
                style="margin-top: 10px"
                v-if="job.secondary_keywords"
              >
                <p
                  v-for="keyword in splitSecondaryKeywords(
                    job.secondary_keywords
                  )"
                  :key="keyword"
                  class="job-tag secondary-jobtag-item"
                >
                  {{ keyword }}
                </p>
              </div>
              <div class="job-footer mt-3">
                <div class="d-flex justify-content-between">
                  <p v-if="job && job.updated_at" class="job-date mt-3">
                    Publié: {{ formatDate(job.updated_at) }}
                  </p>
                  <div class="job-fav-count d-flex align-items-center mt-3">
                    <img
                      src="@/assets/favIcon-outlined.png"
                      alt="Favorite Count"
                      style="height: 21px"
                      class="d-flex align-items-center"
                    />
                    <p>
                      <!-- {{ job.favoritesCount }} -->
                    </p>
                  </div>
                </div>

                <div class="d-flex align-items-center mt-5">
                  <ButtonComponent v-if="!isCandidaturePage" text="Postuler" @click="gotoPostuler(job)" />

                  <button class="favButton ml-5" @click="handleFavoriteClick(job.id)">
                    <img v-if="!isFavored" src='@/assets/favIcon-outlined.png' ref="favIcon" alt="Favorite Icon" class="favorieIcon"
                      @mouseover="hoverImage(true, false)" @mouseout="hoverImage(false, false)" />
                    <img v-else src='@/assets/favIcon-filled.svg' ref="favIcon" alt="Favorite Icon" class="favorieIcon"
                      @mouseover="hoverImage(true, true)" @mouseout="hoverImage(false, true)" />
                  </button>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="job-description">
        <div class="prev-content">
          <div class="content-box" v-if="job.mission_detail">
            <h1>Qui sommes-nous ?</h1>
            <span>{{ job.mission_detail }}</span>
          </div>
          <div class="content-box" v-if="job.job_detail">
            <h1>Poste à pourvoir</h1>
            <div
              v-html="contenuAvecSauts"
              v-if="job.source !== 'Thank-boss'"
            ></div>
            <QuillRenderContainer v-else="getDataSource(job.job_detail)" quillID="conteneurQuill1" :quillDatas="job.job_detail"/>
          </div>

          <div class="content-box" v-if="job.role_détail">
            <h1>Tâches attendues</h1>
            <QuillRenderContainer v-if="getDataSource(job.role_détail)" quillID="conteneurQuill2" :quillDatas="job.role_détail"/>
            <p v-else>{{ job.role_détail }}</p>
          </div>

          <div class="content-box" v-if="job.descriptif">
            <!-- <h1>Profil recherché</h1> -->
            <h1>Description du Poste</h1>
            <QuillRenderContainer v-if="getDataSource(job.descriptif)" quillID="conteneurQuill3" :quillDatas="job.descriptif"/>
            <p v-else v-for="job in job.descriptif.split('\n')">{{ job }}</p>
          </div>

          <!-- Informations provenant de France Travail -->
          <div class="content-box" 
            v-if="job.source == 'FT' 
            && (
              job.formation_recruteur.length > 0 
              || job.savoir.length > 0 
              || job.permis.length > 0
              || job.langue_recruteur.length > 0
              )"
          >
            <h1>Profil souhaité</h1>

            <div class="information-content-box" v-if="job.formation_recruteur.length > 0">
              <h6>Formation</h6>
              <div class="information-content-wrapper">
                <p v-for="(formation, index) in job.formation_recruteur" class="pastille">{{ formation['libelle'] }}</p>
              </div>
            </div>
            
            <div class="information-content-box" v-if="job.savoir.length > 0">
              <h6>Compétences</h6>
              <div class="information-content-wrapper">
                <p v-for="(savoir, index) in job.savoir" class="pastille">{{ savoir['libelle'] }}</p>
              </div>
            </div>
           
            <div class="information-content-box" v-if="job.permis.length > 0">
              <h6>Permis</h6>
              <div class="information-content-wrapper">
                <p v-for="(permis, index) in job.permis" class="pastille">{{ permis['libelle'] }}</p>
              </div>
            </div>
            
            <div class="information-content-box" v-if="job.langue_recruteur.length > 0">
              <h6>Langues</h6>
              <div class="information-content-wrapper">
                <p v-for="(langue, index) in job.langue_recruteur" class="pastille">{{ langue['libelle'] }}</p>
              </div>
            </div>
            
          </div>

          <!-- Informations provenant de France Travail -->
          <div class="content-box" v-if="job.source == 'FT'">
            <h1>Informations complémentaires</h1>

            <ul v-if="job.qualification != null || job.industrie != null">
              <li v-if="job.qualification != null">Qualification: {{ job.qualification }}</li>
              <li v-if="job.industrie != null">Secteur d'activité: {{ job.industrie }}</li>
            </ul>
          </div>

        </div>

      </div>
      <hr class="my-5" />
      <div class="d-flex justify-content-center align-items-center mt-3" style="margin-bottom: 40px">
        <ButtonComponent v-if="!isCandidaturePage" text="Postuler" @click="gotoPostuler(job)" />
        <button class="favButton ml-5" @click="handleFavoriteClick(job.id)">
          <img v-if="!isFavored" src='@/assets/favIcon-outlined.png' ref="favIcon" alt="Favorite Icon" class="favorieIcon"
            @mouseover="hoverImage(true, false)" @mouseout="hoverImage(false, false)" />
          <img v-else src='@/assets/favIcon-filled.svg' ref="favIcon" alt="Favorite Icon" class="favorieIcon"
            @mouseover="hoverImage(true, true)" @mouseout="hoverImage(false, true)" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/buttons/button/Button.vue";
import QuillRenderContainer from "@/components/job-details/QuillRenderContainer.vue";
import getAxiosInstance from "@/services/axios";
import { mapActions } from "vuex";
import { toaster } from "@/utils/toast/toast";

export default {
  name: "JobDetail",
  components: {
    ButtonComponent,
    QuillRenderContainer,
  },
  data() {
    return {
      isCandidaturePage: false,
      job: null,
      isFavored: false,
      favoriteList: null,
    };
  },
  mounted() {
    this.checkRoute();
    this.getJob();
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    contenuAvecSauts() {
      const contenu = this.job.job_detail;
      return contenu.replace(/\n/g, "<br>" + "<br>");
    },
  },
  methods: {
    ...mapActions(["handleAddFavoris", "handleDeleteFavoris"]),

    checkFav(jobId) {
      try {
        if (jobId === null || jobId === undefined) return false;
        return this.isFavored = this.favoriteList.some(favJob => favJob.job_offer.id === jobId);
      } catch (error) {
        console.log("Erreur lors de la vérification des favoris :", error);
        return false;
      }
    },

    // utilise un string pour voir si les datas du job viennent de thanksBoss ou de l'extérieur
    getDataSource(data) {
      if (data.substring(0,7)=='{"ops":') return true;
      else return false;
    },
    goBackArrow() {
      this.$router.go(-1);
    },
    async getJob() {
      await getAxiosInstance().get(`/jobs/${this.$route.params.id}/`)
      .then((response) => {
        this.job = response.data;
        this.favoriteList = this.$store.state.user.favorie_job;
        if (this.job.id) {
          this.isFavored = this.checkFav(this.job.id);
        } else {
          this.isFavored = this.checkFav(this.job.job_id);
        }
      });
    },
    gotoPostuler(job) {
      if (job.source.toLowerCase() === "linkedin" || job.source === "FT") {
        window.open(job.url, "_blank");
      } else {
        this.$router.push("/postuler/" + this.job.id);
      }
    },
    checkRoute() {
      if (this.currentRouteName === "candidatures") {
        this.isCandidaturePage = true;
      }
    },
    splitKeywords(keywords, limit = null) {
      if (keywords === null || keywords === undefined) {
        return [];
      }
      if (keywords.length > 0) {
        const keywordsArray = keywords.split(",");
        if (limit !== null) {
          return keywordsArray.slice(0, limit);
        } else {
          return keywordsArray;
        }
      }
    },
    splitPrimaryKeywords(keywords) {
      if (keywords === null || keywords === undefined) {
        return [];
      }
      if (keywords.length > 0) {
        const keywordsArray = keywords.split(",");
        return keywordsArray.slice(0, 3);
      }
    },
    splitSecondaryKeywords(keywords) {
      if (
        keywords === null ||
        keywords === undefined ||
        keywords.skill_list === null ||
        keywords.skill_list === undefined
      ) {
        return [];
      }
      if (keywords.skill_list.length > 0) {
        return keywords.skill_list.slice(0, 3);
      }
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, options);
    },
    formatLocation(location) {
      if (!location || location === null || location === undefined) {
        return "voir l'annonce";
      }
      const commaIndex = location.indexOf(",");
      return commaIndex === -1 ? location : location.substring(0, commaIndex);
    },
    async handleFavoriteClick(id) {
      try {
        if (!this.$store.getters.isLoggedIn) {
          return toaster.showErrorPopup("Connectez-vous à votre compte pour ajouter un candidat en favori.");
        }

        if (this.isFavored) {
          await getAxiosInstance().post(`/favorite_job/add/${id}`);
          this.job.favorie = this.job.favorie - 1;
          this.handleDeleteFavoris(id);
          this.isFavored = false;
          toaster.showSuccessPopup("Cette offre d'emploi a bien été retirée des favoris.");
        } else {
          await getAxiosInstance().post(`/favorite_job/add/${id}`);
          this.job.favorie = this.job.favorie + 1;
          this.handleAddFavoris({ job_offer: this.job });
          this.isFavored = true;
          toaster.showSuccessPopup("Cette offre d'emploi a bien été ajoutée en favori.");
        }
      } catch (error) {
        toaster.showErrorPopup("Erreur lors de l'ajout en favori.");
        console.error("Erreur lors de l'ajout en favori :", error);
      }
    },
    hoverImage(isHovered, isFavored) {
      if (isFavored) {
        const imgSrc = isHovered ? require('@/assets/favIcon-outlined.png') : require('@/assets/favIcon-filled.svg')
        return this.$refs.favIcon.src = imgSrc;
      } else {
        const imgSrc = isHovered ? require('@/assets/favIcon-filled.svg') : require('@/assets/favIcon-outlined.png')
        return this.$refs.favIcon.src = imgSrc;
      }
    },
    gotoPage(url) {
      if (this.job.source.toLowerCase() !== 'Thank-boss'.toLowerCase()) {
        return false;
      }
      return this.$router.push({ path: url });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 24px !important;
  font-weight: 500;
  margin-bottom: 20px;
}
.jobTags {
  display: flex;
  flex-direction: row;
}

.jobTags p {
  width: fit-content;
  margin-top: 20px;
  margin-right: 10px !important;
}

.content-box {
  margin: 30px 0;
  padding-bottom: 40px;
  border-bottom: 1px solid #c4c4c4;
}

.prev-content {
  margin: 0px auto 87px;
  padding: 50px 90px 40px;
  background-color: white;
}

.modal-dialog {
  min-width: 90%;
  height: 90%;
}

.modal-content {
  width: 100%;
  height: 100%;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-body {
  padding: 0 30px !important;
  overflow-y: auto;
}

.modal-container {
  padding: 0 40px;
}

.job-header {
  display: flex;
  flex-direction: column;
  padding: 10px 40px 70px 40px;
  border-bottom: 1px solid var(--Gray-medium, #c4c4c4);
}

img {
  margin-right: 8px;
}

.source-icon {
  width: 50px;
  height: 50px;
}

.job-logo {
  flex-shrink: 0;
  height: 266px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-company {
  font-size: 22px !important;
}

.job-title {
  font-size: 26px !important;
}

.job-logo img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-height: 100%;
  width: 100%;
}

.job-info {
  flex-grow: 1;
  margin-left: 90px;
}

.job-info ul {
  padding: 0;
  display: flex;
  flex-direction: row;
}

.job-info ul li {
  list-style: none;
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 4px 15px 4px 15px;
  border-radius: 50px;
  margin-right: 10px;
}

.job-card button {
  background-color: white;
}

.job-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}

.logo {
  width: 100%;
  height: 154px;
  border-radius: 9px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #ffffff;
}

.primarytag {
  display: flex;
  padding: 4px 10px 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #fbde2f;
}

.secondarytag {
  display: flex;
  padding: 4px 10px 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #fbde2f;
}

.information-content-box {
  width: 100%;
  margin-bottom: 30px;
}

.information-content-box h6 {
  margin-bottom: 10px !important;
}

.information-content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  height: fit-content;
}

.pastille {
  width: fit-content;
  padding: 4px 10px 4px 15px;
  border-radius: 50px;
  background: #fbde2f;
  height: fit-content;
}

h3 {
  font-weight: 300 !important;
}

p {
  font-size: 18px !important;
  line-height: 141% !important;
}

.job-description h3 {
  font-weight: 500 !important;
}

.mission-list {
  list-style: none;
  padding-left: 0;
}

@media (max-width: 767px) {
  p {
    font-size: 16px !important;
  }
}

@media (max-width: 991px) {
  .job-info {
    flex-grow: 1;
    margin-left: 0px;
    margin-top: 50px;
  }

  .modal-body {
    padding: 0 !important;
  }

  .prev-content {
    padding: 20px;
    margin: 5px;
    margin-top: 20px;
  }

  .modal-container {
    padding: 0;
  }
}
</style>
