<template>
  <div class="card-container ">
    <div class="card mt-5">
      <!-- <img src="@/assets/Group.png" alt="" class="mt-5 pt-2 login-img" /> -->
      <h5 style="margin-bottom: 20px !important;">Changement du mot de passe</h5>
      <p v-if="errorDetails" class="text-danger">{{ errorDetails }}</p>
      <p v-if="successDetails" class="text-success">{{ successDetails }}</p>

      <v-form ref="passwordForm" @submit.prevent class="form mt-3">

        <v-text-field 
          label="Nouveau mot de passe" 
          class="mb-3"
          bg-color="rgba(255, 255, 255, 1)"
          type="password" 
          id="password1"
          v-model="formData.password1" 
          :rules="passwordRules" 
          placeholder="P@ssword!!" 
        />

        <v-text-field 
          label="Confirmer mot de passe" 
          class="mb-3"
          bg-color="rgba(255, 255, 255, 1)"
          type="password" 
          id="password2"
          v-model="formData.password2" 
          :rules="passwordRules2" 
          placeholder="P@ssword!!" 
        />

        <div v-if="!isSent" class="my-3 d-flex container-buttons" style="justify-content: center;">
          <v-btn 
            v-if="!isLoading"
            class="bg-dark text-white"
            @click="resetPassword"
            size="large" 
            variant="outlined">
            Confirmer
          </v-btn>

          <v-btn v-else type="submit" class="register-button responsive-buttons" style="margin-top: 12px;" disabled>
            <i class="fa fa-spinner fa-spin"></i> Réinitialisation en cours...
          </v-btn>
        </div>
        
        <div v-else class="my-3 d-flex container-buttons" style="justify-content: center;">
          <v-btn 
            class="bg-dark text-white"
            @click="gotoLoginpage" 
            size="large" 
            variant="outlined">
            Se connecter
          </v-btn>
        </div>
        
      </v-form>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PasswordConfirm',

  data() {
    return {
      formData: {
        password1: '',
        password2: '',
      },
      successDetails: null,
      errorDetails: null,
      isSent: false,
      isLoading: false,
      passwordRules: [
        v => !!v || 'Le champ est requis',
        v => !/\s/.test(v) || 'Le mot de passe ne doit pas contenir d\'espace',
        v => v.length >= 8 || 'Le mot de passe doit comporter au moins 8 caractères',
        v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).*$/.test(v) || 'Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial',
      ],
      passwordRules2: [
        v => !!v || 'Le champ est requis',
        v => this.formData.password1 === this.formData.password2 || 'Les mots de passe ne correspondent pas',
      ],
    };
  },

  methods: {
    gotoLoginpage() {
      this.$router.push('/login');
    },

    async resetPassword() {
      try {
        const validate = await this.$refs.passwordForm.validate()
        if (validate.valid) {
          this.isLoading = true;
          await axios.post(`https://web.thanks-boss.com/api/user/password_reset_confirm/${this.$route.query.uid}/${this.$route.query["amp;token"]}/`, {
            new_password: this.formData.password1,
          });
          this.isSent = true;
          this.successDetails = `Votre mot de passe a été réinitialisé avec succès. Cliquez sur le bouton pour vous connecter avec votre nouveau mot de passe.`;
          this.errorDetails = null;
          this.formData.email = '';
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.errorDetails = "Une erreur s'est produite lors de la réinitialisation de votre mot de passe. Veuillez réessayer.";
        this.isLoading = false;
      }
    },

  }
};
</script>

<style scoped>
.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 106px;
}

.card {
  background-color: #F5F5F5;
  width: 719px;
  text-align: center;
  font-size: 18px;
  padding: 30px;
  box-shadow: 0px 0px 14px 4px #0000001F;
  border-radius: 20px;
  border: 1px solid #C4C4C4;
}

.register-button {
  width: 50%;
  height: 49px;
  border: 0 solid;
  border-radius: 5px;
  color: #fff;
  background: #000000;
  margin-left: 15px;
  font-weight: 600px;
  padding: 12px, 20px, 12px, 20px;
}

.underline {
  text-decoration: underline;
}

.cancel-button {
  width: 50%;
  height: 49px;
  padding: 12px, 20px, 12px, 20px;
  border-radius: 5px;
  border: 1px;
  background: none;
  border: 1px solid #000000;
  font-weight: 600px;
  margin-right: 15px;
}

.form {
  width: 470px;
  margin-left: auto;
  margin-right: auto;
}

label {
  font-weight: 700;
  height: 49px;
  line-height: 49px;
  text-align: left;
  margin-left: 5px;
}

p {
  font-size: 18px !important;
}

input {
  border: 0;
  margin-right: 10px;
  flex-grow: 1;
  padding-left: 10px;
}

h5 {
  font-size: 38px;
  font-weight: 700;
}

form div {
  border-radius: 5px;
}


.mycontainer {
  max-width: inherit;
  background-color: #fff;
  background-image: url("@/assets/visuel_TB.png"), url("@/assets/Ellipse_0.png");
  background-repeat: no-repeat;
  background-position: 0px 30px, 100% 700px;
}

.clickable {
  cursor: pointer;
}

.labels {
  margin-left: 10px;
  margin-right: 5px;
}

.labels-mdp {
  min-width: 180px;
}

.labels-email {
  min-width: 55px;
}

.input-field {
  background-color: #fff;
  border-radius: 5px;
  min-height: 49px;
}

@media screen and (max-width: 768px) {

  .login-img {
    max-width: 248px;
  }

  .card {
    /* width: 100%; */
    height: auto;
    padding: 10px;
  }

  .form {
    width: 100%;
  }

  .google-container {
    width: 100%;
  }

  label {
    font-size: 16px;
  }

  input {
    width: 100%;
    margin-right: 0;
  }

  p {
    width: 100%;
  }

  .login-img {
    width: 100%;
  }

  .mx-4 {
    margin-left: 10px !important;
    margin-right: 10px !important;
    white-space: nowrap;
  }
}

@media screen and (max-width: 530px) {
  .container-buttons {
    flex-direction: column;
  }

  .responsive-buttons {
    margin: 0;
    margin-top: 15px;
    width: 100%;
  }

  .google-container,
  .form,
  .input-field {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }

  input {
    padding-left: 5px;
  }
}
</style>