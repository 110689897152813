<template>
  <div>
    <div class="banniere d-flex justify-content-between">
      <h2 class="banniere-heading">{{ text }}</h2>
      <img :src="src" class="d-flex img_recherche" :alt="text" />
    </div>
    <div class="white-space"></div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    text: String,
    src: String,
  },
};
</script>

<style scoped>
.banniere {
  background-color: #fbde2f;
  height: 169px;
}

.banniere-heading {
  margin-top: 50px;
  margin-left: 62px;
}

.img_recherche {
  height: 154px;
  margin-right: 62px;
  margin-top: 45px;
}

.white-space {
  height: 30px;
}

@media (max-width: 768px) {
  .banniere-heading {
    margin-top: 30px;
    margin-left: 30px;
  }
  .img_recherche {
    height: 120px;
    margin-right: 25px;
    margin-top: 70px;
  }
}

@media (max-width: 512px) {
  .banniere-heading {
    margin-top: 14px;
    margin-left: 24px;
  }
  .img_recherche {
    height: 82px;
    margin-right: 25px;
    margin-top: 105px;
  }
}
@media (max-width: 355px) {
  .banniere {
    flex-direction: column;
  }
  .img_recherche {
    width: fit-content;
    height: 82px;
    margin: 0;
    margin-left: 50%;
  }
}
</style>
