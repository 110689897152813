<template>
  <div class="sectionWhy">
    <h2>Pourquoi Thanks-Boss ?</h2>
    <div class="wrapperWhy">
      <div class="item">
        <div class="icon">
          <img
            src="@/assets/iconeTemps.svg"
            alt="Main jaune avec une horloge jaune au dessus"
          />
        </div>
        <h4>Création du profil instantanée</h4>
        <p>
          En te connectant via ton compte LinkedIn, tu bénéficies d’une
          complétion automatique et cohérente de tes informations.
          <br /><br />Un gain de temps inestimable !
        </p>
      </div>
      <div class="item">
        <div class="icon">
          <img
            src="@/assets/sourcing.svg"
            alt="Main jaune avec une horloge jaune au dessus"
          />
        </div>
        <h4>Des jobs exclusifs pré-sourcés par nos soins</h4>
        <p>
          Missions data, développement web / mobile, cybersécurité… Sur
          Thanks-Boss, tu trouveras une large variété de jobs IT, dont une
          sélection de perles rares soigneusement dénichées à travers le web.
          Notre secret : combiner des critères fins et variés.
        </p>
      </div>
      <div class="item">
        <div class="icon">
          <img
            src="@/assets/matching.svg"
            alt="Main jaune avec une horloge jaune au dessus"
          />
        </div>
        <h4>Un matching optimisé et accompagné !</h4>
        <p>
          Notre IA spécialisée en IT assure une corrélation fine entre tes
          souhaits et les attentes des entreprises. La qualité du matching est
          amplifiée par la présence de #MatchCoach. Il est à tes côtés pour
          traduire tes préférences en mots-clés intelligibles pour l’IA.
        </p>
      </div>
    </div>
  </div>
</template>

<script></script>

<style>
.sectionWhy {
  height: 675px;
  background-color: black;
  padding: 65px 104px 65px 104px;
  color: #fff;
}

.sectionWhy h2 {
  text-align: center;
  margin-bottom: 60px;
}

.sectionWhy .wrapperWhy {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionWhy .wrapperWhy .item {
  width: calc(100% / 3);
}

.sectionWhy .wrapperWhy .item:nth-child(1) {
  margin-right: 140px;
}
.sectionWhy .wrapperWhy .item:nth-child(2) {
  margin-right: 140px;
}

@media screen and (max-width: 1140px) {
  .sectionWhy .wrapperWhy .item:nth-child(1) {
    margin-right: 60px;
  }
  .sectionWhy .wrapperWhy .item:nth-child(2) {
    margin-right: 60px;
  }
}

@media screen and (max-width: 860px) {
  .sectionWhy {
    height: 1314px;
    padding: 24px 50px 24px 50px;
  }

  .sectionWhy h2 {
    font-size: 34px !important;
  }

  .sectionWhy .wrapperWhy {
    flex-direction: column;
  }

  .sectionWhy .wrapperWhy .item {
    width: 100%;
  }

  .sectionWhy .wrapperWhy .item:nth-child(1) {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .sectionWhy .wrapperWhy .item:nth-child(2) {
    margin-right: 0;
    margin-bottom: 40px;
  }
}
</style>
