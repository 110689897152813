<template>
  <div v-if="jobs" id="jobCarousel">
    <BotMessage style="margin-top: 25px"
      :message="'Voici quelques jobs rien que pour toi ! N’hésite pas à mettre en favoris ceux qui te tentent le plus !'" />
  </div>
  <div style="padding: 20px 0px 20px 0px;">
    <!-- <Carousel :itemsToShow="itemShow" :wrapAround="true" :snapAlign="center - odd" :modelValue="0"
      style="margin-top: 0.1rem">
      :modelValue="0" style="margin-top: 2.5rem;">
      <Slide v-for="job in jobs" :key="job.id" style="margin-top: 2.5rem; padding: 10px 0px 10px 0px;">
        <JobCard :job="job" :favoriteList="user.favorie_job" @checkFav="sendFavoriteMessage" @update-favoris="fetchUser"
          @isFavored="isFavored" />
      </Slide>
      <template #addons>
        <Navigation />
      </template>
</Carousel> -->
    <v-slide-group hide-delimiters height="auto" center-active="true" show-arrows="always">
      <v-slide-group-item v-for="job in jobs" :key="job.id" width="100%">
        <JobCard :job="job" :favoriteList="user.favorie_job" @checkFav="sendFavoriteMessage" @update-favoris="fetchUser"
          @isFavored="isFavored" style="margin-right: 20px" />
      </v-slide-group-item>
    </v-slide-group>

    <BotMessage style="margin-top: 25px;"
      :message="'Pour préciser ta recherche, dis-moi plus ou clique sur le bouton filtre.'" />
    <BotMessage v-if="isFavoriteMessage" style="margin-top: 25px;"
      :message="`Tu viens de placer quelques jobs en favoris (d'autres pas). Cela m'apprend des choses et m’aide à m’approcher toujours plus de tes aspirations !`" />
    <InfoModal />
  </div>
</template>

<script>
// import "vue3-carousel/dist/carousel.css";
// import { Carousel, Navigation, Slide } from "vue3-carousel";
import { mapGetters } from "vuex";
import BotMessage from "@/components/accompagnee/message-row/bot-message/BotMessage.vue";
import JobCard from "@/components/cards/job-card/card/JobCard.vue";
import InfoModal from "@/components/modals/info/InfoModal.vue";
import ButtonComponent from "@/components/buttons/button/Button.vue";

export default {
  name: "JobCarousel",
  components: {
    // Carousel,
    // Slide,
    // Navigation,
    ButtonComponent,
    InfoModal,
    JobCard,
    BotMessage
  },

  data() {
    return {
      selectedJob: null,
      user: "",
      favoriteList: [],
      isFavoriteMessage: false,
      screenWidth: window.innerWidth,
    };
  },
  props: {
    jobs: { type: Array, required: true },
  },
  created() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
  computed: {
    ...mapGetters(["getUser"]),
    itemShow() {
      if (this.$store.state.autonome.isExpandedChat) {
        return 1;
      } else {
        if (this.screenWidth < 1700) {
          return 1;
        }
        else if (this.screenWidth < 2400) {
          return 2;
        }
        else {
          return 3;
        }
      }
    },
  },
  methods: {
    updateWindowWidth() {
      this.screenWidth = window.innerWidth;
    },
    sendFavoriteMessage() {
      this.isFavoriteMessage = true;
    },
    fetchUser() {
      this.user = this.getUser;
    },
    isFavored(fav) {
      this.$emit("jobFavored", fav)
    },
  },
  created() {
    this.fetchUser();
  },
};
</script>

<style>
@media screen and (max-width: 768px) {
  .v-slide-group__next, .v-slide-group__prev {
    display: none !important;
  }
}
</style>

<style scoped>
.carousel__prev {
  display: flex !important;
  width: 42px !important;
  padding: 10px 0px 10px 13px !important;
  align-items: flex-start !important;
  gap: 10px !important;
  border-radius: 50px !important;
  border: 1px solid var(--gray-clear, #e5e5e5) !important;
  background: #fff !important;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12) !important;
}

.carousel__viewport ol li {
  padding: 0 1rem 0 1rem;
}

.jobItem {
  display: flex;
  margin-left: 25px;
  padding: 25px;
  gap: 18px;
  width: 100%;
  min-height: 900px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  border: 1px solid var(--gray-medium, #c4c4c4);
  background: #fff;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.jobLogo {
  border-radius: 9px;
  background: url("@/assets/jobLogo.png") center/contain no-repeat;
  min-width: 100%;
  height: 154px;
  border: 1px solid lightgray;
}

button {
  border: 0;
  padding: 0;
  background-color: white;
}

.tag {
  list-style-type: none;
  padding: 0;
  gap: 12px;
}

.secondary-jobtag-item {
  display: flex;
  padding: 4px 10px 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--gray-clear, #e5e5e5);
}

.mini-fav {
  width: 21px;
  height: 21px;
}

@media screen and (min-width: 750px) {
  .carousel-container {
    height: 100%;
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;

    .mob-carousel-img {
      align-self: center;
      height: auto;
      max-height: 100%;
    }
  }
}
</style>