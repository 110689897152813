<template>
  <div class="card-container mycontainer">
    <div class="card">
      <div>
        <img src="@/assets/Group.png" alt="" class="mt-5 pt-2" />
        <h5 class="mt-4 title">Bienvenue !</h5>
        <p v-if="errorDetails" class="text-danger">{{ errorDetails }}</p>
        <p v-if="successDetails" class="text-danger">{{ successDetails }}</p>
        <div @click="signUpWithGoogle" class="google-container" style="cursor: pointer">
          <img src="@/assets/Google.png" alt="Google icon" />
          <div class="google-text">
            <span style="font-weight: 600">S'inscrire via Google</span>
          </div>
        </div>
        <p class="mb-3">- ou -</p>
        <div @click="signupWithEmail" class="google-container" style="cursor: pointer">
          <img src="@/assets/mail-icon.svg" alt="Google icon" style="height: 30px;" />
          <div class="google-text">
            <span style="font-weight: 600">S'inscrire par email</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { accountService } from "@/services";
import { googleOneTap } from "vue3-google-login";
import { decodeCredential } from "vue3-google-login";
import { toaster } from "@/utils/toast/toast";
import { mapActions } from "vuex";
export default {
  name: "Register",
  data() {
    return {
      formData: {
        email: "",
        password: "",
        password2: "",
        type_user: "applicant",
      },
      errorDetails: null,
      successDetails: null,
    };
  },
  methods: {
    ...mapActions(["handleUserChange", "handleLoggedIn"]),
    signupWithEmail() {
      this.$router.push("/register/email");
    },
    async signUpWithGoogle() {
      this.errorDetails = null;
      this.successDetails = null;
      try {
        const response = await googleOneTap({
          clientId:
            "499109195466-3cgb1ucg8oon8ncdemjpveuqkv7n0i61.apps.googleusercontent.com",
          context: "signup",
        });

        let registrationData = decodeCredential(response.credential);

        const finalSubmit = {
          email: registrationData.email,
          password: registrationData.sub,
          password2: registrationData.sub,
          type_user: "applicant",
        };

        accountService
          .register(finalSubmit)
          .then(() => {
            accountService.login({ email: finalSubmit.email, password: finalSubmit.password })
              .then((response) => {
                accountService.saveToken(response.data.access);
                accountService.getProfile().then((response) => {
                  this.handleUserChange({ type: null, payload: response.data })
                  this.handleLoggedIn(true);
                  this.$router.push('/');
                }).catch((error) => {
                  console.log(error);
                })
              });
          })
          .catch((error) => {
            if (error.response.data) {
              console.error("Register failed:", error.response.data);
            }
            // if (
            //   error.response.data.email.includes(
            //     "Un objet account avec ce champ email existe déjà."
            //   )
            // ) {
            //   this.errorDetails = "Ce compte est déjà enregistré";
            // }
          });
      } catch (error) {
        console.error("Google Register Failed:", error);
        this.errorDetails = "Google Register Failed";
      }
    },
    gotoPage(page) {
      this.$router.push(page);
    },
  },
};
</script>

<style scoped>
.underline {
  text-decoration: underline;
}

p {
  font-size: 18px !important;
}

.register-button {
  width: 50%;
  height: 49px;
  border: 0 solid;
  border-radius: 5px;
  color: #fff;
  background: #000000;
  margin-left: 15px;
  font-weight: 600px;
}

.cancel-button {
  width: 50%;
  height: 49px;
  padding: 12px, 20px, 12px, 20px;
  border-radius: 5px;
  border: 1px;
  background: none;
  border: 1px solid #000000;
  font-weight: 600px;
  margin-right: 15px;
}

.google-container {
  gap: 10px;
  width: 470px;
  display: flex;
  padding: 9px 20px;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  background-color: #fff;
  margin: 24px auto 20px;
}

.google-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  text-align: left;
  line-height: 140.9999966621399%;
}

.form {
  width: 470px;
  margin-left: auto;
  margin-right: auto;
}

label {
  font-weight: 700;
  height: 49px;
  line-height: 49px;
  text-align: left;
  margin-right: 5px;
  font-size: 16px;
}

.labels {
  margin-left: 25px;
  margin-right: 10px;
}

.labels-confirmer {
  min-width: 85px;
}

.labels-mpd {
  min-width: 105px;
}

.labels-email {
  min-width: 50px;
}

input {
  width: 100%;
  padding-left: 15px;
}

form div {
  border-radius: 5px;
  margin-bottom: 20px;
}

input {
  border: 0;
  /* margin-right: 10px; */
  flex-grow: 1;
  padding-left: 10px;
}

h5 {
  font-size: 38px;
  font-weight: 700;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 106px;
  padding-bottom: 106px;
}

.card {
  background-color: #f5f5f5;
  text-align: center;
  font-family: "Outfit";
  font-size: 18px;
  box-shadow: 0px 0px 14px 4px #0000001f;
  width: 719px;
  border-radius: 20px;
  border: 1px solid #c4c4c4;
  padding: 30px;
}

.mycontainer {
  max-width: inherit;
  background-color: #fff;
  background-image: url("@/assets/visuel_TB.png"), url("@/assets/Ellipse_0.png");
  background-repeat: no-repeat;
  background-position: 0px 180px, 100% 850px;
}

#email,
#password,
#password2 {
  padding-left: 15px;
  margin-left: -15px;
}

@media screen and (max-width: 530px) {
  .container-buttons {
    flex-direction: column;
  }

  .responsive-buttons {
    margin: 0;
    margin-top: 15px;
    width: 100%;
  }

  .google-container,
  .form,
  .input-field {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 460px) {
  label {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  input {
    padding-left: 15px;
    padding-right: 0;
  }
}

@media screen and (max-width: 340px) {
  input::placeholder {
    color: transparent;
  }

  input {
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 0;
  }
}
</style>
