<template>
  <div>
    <div class="head" v-if="convName">
      <div class="logo">
        <img src="@/assets/match.png" alt="match" />
      </div>
      <p class="conv-name" v-if="!isModifying">
        <img src="@/assets/petit.svg" style="height: 20px; margin-right: 10px; margin-bottom: 4px; cursor: pointer;"
          @click="renameConversation" />
        {{ convName.titre }}
      </p>
      <input v-if="isModifying" type="text" class="form-control" v-model="conversationName"
        :placeholder="convName.titre" @keydown.enter="saveTitle" />
      <div class=" entete w-100">
        <ButtonComponent text="Mes alertes" @click="gotoPage('alert')" />
        <p class="chats">Chats</p>
        <button class="plus-icon" v-bind:disabled="isDisabled" @click="createConversation">
          +
        </button>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import ButtonComponent from "@/components/buttons/button/Button.vue";
export default {
  name: "ChatHeader",
  props: {
    createConversation: { type: Function, required: true },
    convName: String,
    isDisabled: Boolean,
  },
  components: {
    ButtonComponent,
  },
  data() {
    return {
      isDropdownVisible: false,
      conversationName: "",
      isModifying: false,
    };
  },
  methods: {
    gotoPage(url) {
      this.$router.push(url);
    },
    renameConversation() {
      this.isModifying = !this.isModifying;
      this.conversationName = this.convName.titre;
    },
    saveTitle() {
      this.isModifying = !this.isModifying;
      this.$emit("renameConversation", this.conversationName);
    },
  },
};
</script>

<style scoped>
input {
  border: 0;
  background: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 16px;
  padding: 10px 20px;
  margin: 0 20px;
  width: 90%;
  height: 100%;
}

.conv-name {
  font-size: 18px !important;
  line-height: 141%;
  padding: 10px;
  width: 100%;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chats {
  color: #000;
  font-family: Outfit;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 141%;
  /* 25.38px */
}

.logo {
  margin: -34px 0 0 -11px;
}

.entete {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
  padding: 20px 0;
}

.plus-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 0;
  background: var(--primary-jaune, #fbde2f);
  width: 41px;
  font-size: 23px;
}

.plus-icon div {
  font-size: 25px !important;
  font-weight: 400 !important;
}

.button {
  display: flex;
  padding: 8px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #000;
  color: white;
}

.filterNum {
  color: #fbde2f;
  margin-left: 10px;
}

.menu {
  width: 44px;
  height: 44px;
}

.menu-container {
  position: relative;
}

button {
  border: 0;
  background-color: white;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 0;
  border-radius: 0.25rem;
  min-width: 160px;
}

.dropdown-item {
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.dropdown-menu[v-show] {
  display: block !important;
}

hr {
  margin: 0 !important;
}

@media (min-width: 1061px) {
  .logo {
    display: block;
  }

  .entete {
    justify-content: flex-end;
  }
}

@media (max-width: 1060px) {
  .logo {
    display: none;
  }

  .entete {
    justify-content: space-around;
  }
}

@media (max-width: 500px) {
  .head {
    flex-direction: column;
  }

  .conv-name {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  .logo {
    display: block;
  }

  .logo img {
    width: 83.25px;
    height: 84px;
  }

  .entete {
    justify-content: space-around;
    padding: 10px 0;
  }

  .menu {
    width: 26px;
    height: 26px;
  }
}
</style>