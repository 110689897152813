<template>
  <div class="down large-width">
    <div class="autocomplete-container entry_field">
      <v-text-field
        type="text"
        id="cityAuto"
        placeholder="Ville"
        v-model="city"
        @input="getGeoValue('text')"
        autocomplete="off"
        @blur="hide_dropdown"
      />

      <!-- Autocomplete suggestions for city names -->
      <ul
        v-if="autocompleteCityResults.length"
        class="autocomplete-list"
        style="z-index: 1"
        :class="{ 'fade-out': fadeDropDrown, 'show-me': !fadeDropDrown }"
      >
        <li
          class="optionVille"
          v-if="showCityDropdown"
          v-for="(result, index) in autocompleteCityResults"
          :key="index"
          @click="selectAutocompleteResult(result, 'text')"
        >
          {{ result.nom }} - {{ result.codesPostaux.join(", ") }}
        </li>
      </ul>
    </div>
  </div>

  <!-- Dropdown for city suggestions based on selected postal code -->
  <div class="autocomplete-container">
    <ul class="autocomplete-list" style="z-index: 2">
      <li
        v-if="showCityDropdown"
        v-for="(city, index) in citySuggestions"
        :key="index"
        @click="selectCityFromDropdown(city)"
      >
        {{ city.nom }} - {{ city.codesPostaux.join(", ") }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  emits: ["cityEntry"],
  props: {
    sendingCity: Array,
    sendingCode: Number,
    initialChips: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      city: "",
      code_postal: "",
      autocompleteCityResults: [],
      autocompletePostalCodeResults: [],
      showCityDropdown: false,
      citySuggestions: [],
      data: [],
      blurTimeout: null,
      fadeDropDrown: false,
    };
  },
  watch: {
    sendingCity(newCity) {
      this.city = newCity;
    },
    sendingCode(newCode) {
      this.code_postal = newCode;
    },
    // showCityDropdown(show) {
    //   if (show) {
    //     // let showPanel = document.getElementById("dropMe");
    //     // showPanel.style.display = "inline";
    //   } else if (!show) {
    //     console.log("hide dropdown");
    //     // let hidden = document.getElementById("dropMe");
    //     // hidden.style.display = "none";
    //   }
    // },
    city(cityInput) {
      if (cityInput.length == 0) {
        this.showCityDropdown = false;
      }
    },
  },
  mounted() {
    // let hidden = document.getElementById("dropMe");
    // hidden.style.display = "none";
    this.getCityList();
  },
  created() {
    this.city = this.sendingCity;
    this.code_postal = this.sendingCode;
    if (this.initialChips) {
      this.city = this.initialChips.toString();
    }
  },
  methods: {
    async getCityList() {
      try {
        const response = await fetch(
          `https://geo.api.gouv.fr/communes?fields=nom,codesPostaux&format=json`
        );
        const data = await response.json();
        this.data = data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    getGeoValue() {
      const search = this.city;
      if (this.city.length > 0) {
        const filteredResults = this.data.filter((result) =>
          result.nom.toLowerCase().startsWith(search.toLowerCase())
        );
        this.autocompleteCityResults = filteredResults;
        this.citySuggestions = filteredResults;
        this.autocompletePostalCodeResults = this.data;
        this.showCityDropdown = true;
        this.fadeDropDrown = false;
      }
    },
    selectAutocompleteResult(result, type) {
      this.city = result.nom;
      this.code_postal = result.codesPostaux[0];
      this.autocompleteCityResults = [];
      this.sendCity();
    },
    selectCityFromDropdown(city) {
      this.city = city.nom;
      this.clearTimeout();
      this.code_postal = city.codesPostaux[0];
      this.showCityDropdown = false;
      this.citySuggestions = [];
    },
    hide_dropdown() {
      this.fadeDropDrown = true;
      this.blurTimeout = setTimeout(() => {
        this.showCityDropdown = false;
      }, 200);
    },
    clearTimeout() {
      clearTimeout(this.blurTimeout);
    },
    sendCity() {
      //sending city value to the parent component
      this.$emit("justCityEntry", {
        ville: this.city,
        postal_code: this.code_postal,
      });
    },
  },
};
</script>

<style scoped>
.autocomplete-container {
  position: relative;
  display: inline-block;
}

.autocomplete-list {
  list-style: none;
  padding: 0;
  position: absolute;
  top: 100%;
  left: -12px;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  max-height: 171px;
  overflow-y: auto;
  width: 105%;
}

li {
  cursor: pointer;
  padding: 7px 30px 7px 15px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

li:first-child {
  margin-top: 15px;
}
/* li:last-child {
  border-bottom: none;
} */

/* .form-base {
  background-color: var(--dl-color-gray-light);
  padding: 30px;
  border-radius: 10px;
  border: 1px solid rgba(196, 196, 196, 1);
  margin-bottom: 100px;
} */

.entry_field {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

input {
  border: none;
}

input:focus {
  border: none;
  outline: none;
}

.show-me {
  opacity: 1;
}
.fade-out {
  transition: opacity 0.3s ease;
  opacity: 0;
}

/* .form_bloc input,
.form_bloc select,
.form_bloc textarea {
  border-radius: 5px;
  border: none;
  padding: 10px 0 10px;
} */

/* #cityAuto {
  padding: 10px 30px 10px 30px;
} */

/* label {
  margin-bottom: -5px;
  padding-left: 20px;
}

.down {
  margin-top: 25px;
}

.small-width {
  max-width: 130px;
}

.large-width {
  min-width: 400px;
}

.no-left-padding {
  padding-left: 15px;
} */

/* #codeAuto {
  text-align: center;
  padding-left: 0px;
  margin-left: -10px;
} */

ul {
  cursor: pointer;
}
</style>
