<template>
  <div class="popup-linkedin-container" v-if="isPopupVisible">
    <span>
      Format Linkedin importé
    </span>
    <div class="popup-linkedin-description d-flex flex-row">
      <img src="@/assets/Info.png" />
      <p>
        Tu as opté pour une connexion via LinkedIn. Ce choix te permet de profiter d’une complétion automatique de ton profil, mais aussi de disposer d’un CV en format pdf généré à partir de tes données LinkedIn. 
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupLinkedin',
  data() {
    return {
      isPopupVisible: false,
    };
  },
}
</script>

<style scoped>

span {
  display: inline-block;  
  color: var(--Feedback-Information, #5173D7);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 141%;
}

.popup-linkedin-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 480px;
  gap: 20px;
  padding: 20px 30px 30px 30px;
  border: 1px solid var(--Feedback-Information, #5173D7);
  border-radius: 20px 20px 20px 0px;
  background: #FFF;
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.close-popup {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  img {
    width: 26px;
    height: 26px; 
    cursor: pointer;
  }
}

.popup-linkedin-description {
  img {
    width: 26px;
    height: 26px;
    margin-right: 10px;
  }
  p {
    font-size: 18px !important;
    font-style: normal;
    font-weight: 300 !important;
    line-height: 141% !important;
  }
}

</style>