<template>
<div class="profile-container">
  <Informations :informationList="{ ...informationList }" @information-form-submit="handleInformationSubmit"
    @delete-account="deleteAccount" />
  <Cv :normalCv="{ ...informationList }" @normal-cv-change="handleNormalCVChange" @linkedin-cv-change="linkedinCvParser"
    @delete-normal-cv="deleteNormalCv" />
  <Experience :experienceList="{ ...experienceList }" @experienceList-form-submit="createExperience"
    @experience-form-delete="deleteExperience" />
  <Formation :formationList="{ ...formationList }" @formation-form-submit="createFormation"
    @formation-form-delete="deleteFormation" />
  <Qualification :qualificationList="{ ...qualificationList }" @qualification-form-submit="createQualification"
    @qualification-form-delete="deleteQualification" />
  <Language :languageList="{ ...languageList }" @language-form-submit="createLanguage"
    @language-form-delete="deleteLanguage" />
</div>
</template>

<script>
import { toaster } from '@/utils/toast/toast';
import { mapGetters, mapActions } from 'vuex';
import axios from '@/services/axios';
import Informations from './informations/Informations.vue';
import Cv from './cv/Cv.vue';
import Experience from './experiences/Experiences.vue';
import Formation from './formations/Formations.vue';
import Qualification from './qualifications/Qualifications.vue';
import Language from './languages/Languages.vue';
import { accountService } from '@/services';

export default {
  name: 'MyAccount',
  components: {
    Informations,
    Cv,
    Experience,
    Formation,
    Qualification,
    Language
  },
  data() {
    return {
      // user data
      informationList: [],
      experienceList: [],
      formationList: [],
      qualificationList: [],
      languageList: [],
      linkedinCv: [],
    }
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  mounted() {
    this.handleUserInformation();
  },
  methods: {
    ...mapActions(['handleUserChange', 'handleDeleteUserPropreties']),
    /* ADDS */
    // experiences section data submit
    async createExperience(data) {
      try {
        const response = await axios().post('/experience/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        // set id to the new object 
        const { data: { id } } = response;
        data.id = id;
        // mutation and get new list
        this.handleUserChange({ type: 'experience', payload: data });
        this.experienceList = this.getUser.experience;
        toaster.showSuccessPopup("Expérience ajoutée avec succès !");
      } catch (error) {
        toaster.showErrorPopup();
        console.error('Error retrieving user information:', error);
      }
    },
    async createFormation(data) {
      try {
        const response = await axios().post('/formations/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        // set id to the new object 
        const { data: { id } } = response;
        data.id = id;
        // mutation and get new list
        this.handleUserChange({ type: 'formation', payload: data });
        this.formationList = this.getUser.formation;
        toaster.showSuccessPopup("Formation ajoutée avec succès !");
      } catch (error) {
        toaster.showErrorPopup();
        console.error('Error retrieving user information:', error);
      }

    },
    // qualifications section data submit ( diplome )
    async createQualification(data) {
      try {
        const response = await axios().post('/education/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        // set id to the new object 
        const { data: { id } } = response;
        data.id = id;
        // mutation and get new list
        this.handleUserChange({ type: 'education', payload: data });
        this.qualificationList = this.getUser.education;
        toaster.showSuccessPopup("Diplome ajouté avec succès !");
      } catch (error) {
        toaster.showErrorPopup();
        console.error('Error retrieving user information:', error);
      }
    },
    // languages section data submit
    async createLanguage(data) {
      try {
        const response = await axios().post('/langue/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const { data: { id } } = response;
        data.id = id;
        // mutation and get new list
        this.handleUserChange({ type: 'langue', payload: data });
        this.languageList = this.getUser.langue;
        toaster.showSuccessPopup();
      } catch (error) {
        toaster.showErrorPopup("Erreur lors de l'ajout de la langue. Veuillez réessayer");
        console.error('Error retrieving user information:', error);
      }
    },

    /* GETS */
    // main user information 
    handleUserInformation() {
      this.informationList = this.getUser;
      this.formationList = this.getUser.formation;
      this.experienceList = this.getUser.experience;
      this.qualificationList = this.getUser.education;
      this.languageList = this.getUser.langue;
    },

    /* UPDATES */
    // informations section data submit
    async handleInformationSubmit(data) {
      console.log(data);
      if (data.logo_img !== null) delete data.logo_img;

      try {
        await axios().put('/user/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        let currentUser = this.getUser;
        currentUser = { ...currentUser, ...data };
        this.handleUserChange({ type: null, payload: currentUser });
        this.informationList = this.getUser;
        toaster.showSuccessPopup();
      } catch (error) {
        toaster.showErrorPopup("Erreur lors de la mise à jour des informations. Veuillez réessayer.");
        console.error('Error retrieving user information:', error);
      }
    },
    // normal cv change
    async handleNormalCVChange(selectedFile) {
      const formData = new FormData();
      formData.append('cv', selectedFile);
      try {
        await axios().put('/user/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        let currentUser = this.getUser;
        currentUser = { ...currentUser, cv: selectedFile };
        this.handleUserChange({ type: null, payload: currentUser });
        this.informationList = this.getUser.cv;
        toaster.showSuccessPopup("CV importé avec succès !");
      } catch (error) {
        toaster.showErrorPopup();
        console.error('Error retrieving user information:', error);
      }
    },
    // linkedin cv change
    async linkedinCvParser(selectedFile) {
      const formData = new FormData();
      formData.append('resume', selectedFile);
      axios().post('/api/resume_parser/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.informationList = response.data;
        this.formationList = response.data.certification;
        this.experienceList = response.data.experience;
        this.qualificationList = response.data.education;
        this.languageList = response.data.languages;

        this.fetchDataForList("/formation/", this.formationList);
        this.fetchDataForList("/experience/", this.experienceList);
        this.fetchDataForList("/education/", this.qualificationList);
        this.fetchDataForList("/langue/", this.languageList);

        window.location.reload();
        // after reload, show popup
        toaster.showSuccessPopup("Informations du CV importé avec succès !");
      }).catch((error) => {
        toaster.showErrorPopup("Veuillez vérifier votre CV et réessayer.");
        console.error('Error retrieving user information:', error);
      });
    },

    async fetchDataForList(apiEndpoint, dataList) {
      if (dataList && dataList.length > 0) {
        const requests = dataList.map(async (item) => {
          try {
            await axios().post(apiEndpoint, item, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
          } catch (error) {
            console.error('Erreur lors de la requête :', error);
          }
        });
        // Attendre que toutes les requêtes soient terminées
        await Promise.all(requests);
      }
    },

    /* DELETES */
    // experiences delete
    async deleteExperience(id) {
      try {
        await axios().delete(`/experience/${id}/`);
        await this.handleDeleteUserPropreties({ type: 'experience', payload: id });
        this.experienceList = this.getUser.experience;
        toaster.showSuccessPopup("Expérience supprimée avec succès !");
      } catch (error) {
        toaster.showErrorPopup();
        console.error('Error deleting user experience:', error);
      }
    },

    // formations delete
    async deleteFormation(id) {
      try {
        await axios().delete(`/formations/${id}/`);
        await this.handleDeleteUserPropreties({ type: 'formation', payload: id });
        this.formationList = this.getUser.formationList;
        toaster.showSuccessPopup("Formation supprimée avec succès !");
      } catch (error) {
        toaster.showErrorPopup();
        console.error('Error deleting user formation:', error);
      }
    },

    // qualifications delete
    async deleteQualification(id) {
      try {
        await axios().delete(`/education/${id}/`);
        await this.handleDeleteUserPropreties({ type: 'education', payload: id });
        this.qualificationList = this.getUser.education;
        toaster.showSuccessPopup("Diplome supprimé avec succès !");
      } catch (error) {
        toaster.showErrorPopup();
        console.error('Error deleting user qualification:', error);
      }
    },
    // languages delete
    async deleteLanguage(id) {
      try {
        await axios().delete(`/langue/${id}/`);
        await this.handleDeleteUserPropreties({ type: 'langue', payload: id });
        this.languageList = this.getUser.langue;
        toaster.showSuccessPopup("Langue supprimée avec succès !");
      } catch (error) {
        toaster.showErrorPopup();
        console.error('Error deleting user language:', error);
      }
    },
    // normal cv delete
    async deleteNormalCv() {
      try {
        await axios().delete('/user/delete-cv/');
        this.informationList.cv = null;
        toaster.showSuccessPopup("CV supprimé avec succès !");
      } catch (error) {
        toaster.showErrorPopup();
        console.error('Error deleting user cv:', error);
      }
    },
    // account delete
    async deleteAccount() {
      try {
        toaster.showInfoPopup("Votre compte est en cours de suppression. Vous allez être redirigé vers la page de connexion.");
        await axios().delete('/user/');
        accountService.logout();
        this.$router.push('/login');
      } catch (error) {
        toaster.showErrorPopup();
        console.error('Error deleting user account:', error);
      }
    },
  },
};

</script>

<style>
img {
  transition: transform 0.3s ease;
}

.profile-container {
  width: 100%;
  display: grid;
  gap: 50px;
  margin-bottom: 110px;
  color: #000;
  font-family: Outfit;

  label {
    font-size: 18px !important;
  }

  input,
  select,
  textarea {
    background-color: #fff;
  }

  button {
    background-color: #000;
    color: #fff;
  }

  select {
    -webkit-appearance: auto;
    appearance: auto;
  }
}

.profile-child-container {
  width: 95%;
  padding: 35px 27px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  border: 1px solid var(--Gray-medium, #C4C4C4);
  background: var(--Gray-light, #F5F5F5);
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.12);
}

.profile-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
}

.profile-save-button {
  padding: 8px 25px 8px 25px;
  border-radius: 50px;
  background: #000;
  color: #fff;
  border: none;
}

.profile-line-topbar {
  border-top: 1px solid var(--Gray-dark, #979797);
  margin-top: 50px;
  padding-top: 35px;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.rotate-180 {
  transform: rotate(180deg);
}

@media screen and (max-width: 991px) {
  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .profile-grid {
    display: flex;
    flex-direction: column;
  }

  .profile-child-container {
    width: 100%;
    padding: 25px;
  }
}
</style>
